/**** Responsive ****/
@media (min-width: 1200px){        
.mobile_hmenu_list{ display: none;}/* Mobile cms menu */
.hmenu_list > li:hover > a > .hdd_arrow{ opacity: 1; visibility: visible;}
}
@media (min-width: 679px){
.cur-ord-rec-mobile{ display: none;}
}   
@media (min-width: 576px){    
.modal-dialog { margin: 1.75rem auto; }
.modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); min-height: -webkit-calc(100% - (1.75rem * 2));}
}
@media(max-width: 1330px) and (min-width: 991px){
.promation_btn .btn {min-width: auto; width: 48%; float: left; margin: 1%; font-size: 16px; padding: 12px 10px;}
}
@media (min-width: 1500px){   
.container-full{ padding-left: 0; padding-right: 0  } 
}
@media (max-width: 1500px) {
    .product-details .container-full > div .inn-product-popup .slick-slider .slick-thumb li img {width: auto;}
}
/* Max width */
@media (max-width: 1350px){
.container, .container-full{padding-left: 15px; padding-right: 15px;}
.ordr-hdr h4{
    font-size: 14.5px;
}
}

@media (max-width: 1280px){
ul.hmenu_list > li > a, .hsign_sec a{font-size: 13px;}
ul.hmenu_list > li > a{padding: 15px 5px 12px;margin: 0 2px}    
.hordertype_sec a { padding: 10px 20px;  margin: 0 15px 0 20px;font-size: 14px;}    
.htico_cart{margin-left: 10px}    
.footer-bottom .container { padding: 0 15px;  flex-flow: column wrap;}    
.footer-bottom-right { position: relative; top: inherit; left: inherit; margin-top: 5px;}    
.product-details .container-full > div .inn-product-popup .slick-slider .slick-thumb li img {max-width: 100px;}
}
@media (max-width: 1240px){
.header_in::before, .header_in::after, .ourstory-inner-cont:before{display: none}   
}
@media (max-width: 1199px){
input{ -webkit-appearance: none; -moz-appearance: none; appearance: none;  border-radius:0;}
.sticky .alert_fixed{ top: 95px;}
.header_in{ padding: 0 }
.header_middle:before, header.small_sticky:before{display: none !important}    
.hmenu_actions{float: right}
.product-view-more {  margin-top: 15px;}    
.tbars header{ height: 153px;}
.breadcrumb{ padding: 25px 0 0;}
.Products .breadcrumb{ padding: 69px 0 0 0;}
.Products .bread_space { margin: 69px 0 0; }
.hmenu_actions > li.hsign_sec{ display: none;}
.mega_menuin [class*='col-']{width: 100%;}
.logo{width: 100%;max-width:200px;}
.hmenu_actions li.horder_btn{display: none;}
.hsearch_bx .re_select .Select-control:before{ right: 14px;}
i.hdd_arrow{ position: absolute; right: 0; top: 0; bottom: 0; height: 45px; width: 45px; line-height: 45px; font-size: 16px; text-align: center; background: #ecdfcc; color: #181818; margin: auto 0;}
.hsearch_trigger:before{ top: 36px; right: 12px;}
.hsearch_bx .re_select .Select-control{ height: 49px;}
.hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
.hsearch_bx .re_select .Select-input{ height: 49px;}
/* Sticky Header */
.bakery_row_fixed{ top: 162px;}
.tbars .bakery_row_fixed{ top: 202px;}
.banner{ margin-top: 25px;}
.banner .container{ padding: 0 15px;}
.banner-top-text{position: static;left: 0;right: 0;margin: 0 auto 50px auto;width: 100%; padding: 0 50px;}    
.banner-text-box{margin-right: 0;}
.banner-info h2, .featured-products-main h2, .serving-passionately-right h3{font-size: 40px; }
.banner-info p{font-size: 14px;    line-height: 20px;}
.banner-info a{
    display: none;
}
.banner-info{
    top:5%
}
.order_popup{
    /*max-width: 375px;*/
}
.ta-box-inner {
    text-align: center;
}
.tkaw-icons span{
    font-size: 12px;
}
/*.white-popup.login-popup, .white-popup.signup-popup, .white-popup.signup-corparateone-popup, .white-popup.signup-corparatetwo-popup, .white-popup.guest-popup{
    background: inherit;
    max-width: 352px;
}*/
.qrcode img {
     padding: 40px;
    width: 100%;
    border: 1px solid #fff;
}
.inside-dine-popup-rhs {
    float: none;
    background: #000;
    padding: 15px 60px 0px 60px;
    position: relative;
    z-index: 9;
    border: 1px solid #04cadf;
    /* margin: 0 35px 0 0; */
    width: 100%;
    transition: 0.4s linear all;
}
.dine-input input{
    width:95%;
    display:flex;
    margin: 0 auto;
}
.pop-whole-lhs-inner p {
    font-size: 12px;
}
.ft-tp-col1 img, .ft-tp-col2 img, .ft-tp-col3 img {
    margin: 0 auto 0px;
    display: block;
}

.featured-products-full{ width: 100%}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 15px;}
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 28px;}
.innerproduct-col{width: 32.29061553985873%;margin-right: 1.5136226034308779%;margin-bottom: 15px;}
.innerproduct-col:nth-of-type(3n+3){margin-right: 0;}
.innerproduct-col:nth-of-type(5n+5){margin-right: auto;}
.chk_hea{font-size: 16px;}
.checkout-total .cdd-details-lhs{width: 100%;margin-bottom: 10px;} 
.cdd-details-lhs .checkout-heading:after{display: none}    
.check_pg_inner{padding: 20px 15px 10px 15px;}
.checkout-total .checkout-control-group-top{padding: 20px 0 20px 0;}
.checkout-total .cdd-details-rhs{width: 100%;}
.checkout-total .checkout-control-group-middle{padding: 10px 0 0px 0;}
.checkout-control-group-top [class*='col-md']{margin-bottom: 10px;}
.checkout-control-group-middle [class*='col-md']{margin-bottom: 10px;}
.checkout-total .checkout-control-group-top a{margin-top: 0;}
.order-detail-inner-icon{max-width: 100%;}
.order-detail-inner-icon-left.order-detail-inner-center::before{background-repeat: repeat;max-width: 100%;height: 6px;}
.order-detail-inner{padding-top: 27px;}
.main_tabsec_inner> .nav-tabs>li> a{text-align: left;}
.main_tabsec_inner, .rewards-tab-section .main_tabsec_inner, .promation-tab-section .main_tabsec_inner{padding: 20px 15px;}
.title_sec h2{font-size: 34px;}
.filter_tabsec .nav-tabs>li>a{font-size: 17px;}
.hme-product-inner .filter_tabsec .nav-tabs{margin-bottom: 20px;}
.banner-left{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.banner-text-box{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;width: 100%;}
.banner-right{margin-top: 0;}
.banner-right-content{text-align: left;}
.htopinfo{text-align: center;width: 100%;}
.delivery-item h2{font-size: 32px;}
.delivery-item span{font-size: 17px;}
.hme-product-inner{padding: 20px 15px;}
.outlet-list li{ padding-left: 0;}
.footer-inner [class*='col-']{width: 33.33%;}
.footer-inner .news-letter{width: 100%;margin-top: 10px;}
.cart_footer_totrow p{font-size: 20px;}
.cart_footer_totrow span{font-size: 20px;}
.delivery_info h4{font-size: 14px;}
.delivery_info p{font-size: 14px;}
.cart_action .btn_grey{font-size: 14px;}
.tnk-you{padding: 70px 0;}
.redeem-col{width: 100%;margin: 0 0 10px 0;padding: 20px 10px;}
.redeem-item-hea{padding-left: 0;}
.redeem-item-hea h4{font-size: 23px;}
.tnk-order h3{font-size: 28px;}
.tnk-order p{ font-size: 14px;}
.tick img{width: 60px;}
.tick h2{font-size: 60px;
    margin-top: 15px;}
.tick span{font-size: 16px;}
.delivery-inner{padding: 40px 0;position: relative;}
.delivery-inner-comman{position: relative;z-index: 2;}
.delivery-inner::after{content: "";background: rgba(255, 255, 255, 0.6);top: 0;left: 0;bottom: 0;right: 0;position: absolute;z-index: 1;}
.bts .form-group {min-width: auto;width: calc(100% - 200px) !important;}
/*promotion pages*/
.promo-earned-content{padding: 10px 10px; }
.promo-earned-content h2, .promo-earned-content span{ font-size: 20px; }
.promo-earned-content h2 span sup {font-size: 20px; }
.abc_code span{ font-size: 17px; }
.abc_code{ padding: 10px 0; }
.redeem_popsec .promo-earned-content h2 span sup {font-size: 18px; }
.redeem_popsec .promo-earned-content h4{ font-size: 24px; }
.redeem_popsec .promo-earned-content p{ margin: 0; }
.redeem_popsec .abc_code {padding: 6px 0 0px; }
.redeem_popsec .promo-earned-content{ padding: 20px 10px 20px; }
.redeem_popsec .promo-earned-content h2{ font-size: 30px;}
/* Account information page */

.pkg_ginfo p{ max-height: 75px; overflow: hidden}    
.header-bottom .logo{display: none}    
.mobile-logo-div{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;text-align: center; position: absolute;left: 0;right: 0; max-width: 210px; margin: auto;top: 5px;bottom: 5px; -webkit-box-align: center; -ms-flex-align: center; align-items: center}       
.mobile-logo-div a{display: block;width: 100%;}    
.mobile-logo-div a img{ width: 100% }       
.productlist-main-div{ width: 100%}    
}
@media (max-width: 550px){  
        .prd-cat{
            padding: 50px 15px 0px 15px!important;
    }
    .white-popup.login-popup, .white-popup.signup-popup, .white-popup.signup-corparateone-popup, .white-popup.signup-corparatetwo-popup, .white-popup.guest-popup{
    background: inherit;
    /*max-width: 352px;*/
}  
}
@media (max-width: 1180px){
.pkselected_package .buffet-label {padding: 15.5px 15px 15.5px 60px;width: 130px;}
.pkselected_package .form-group {/*width: calc(100% - 135px);*/ min-width: auto;}
.pkselected_filtersec .time-label {width: 171px;}
.pkselected_break .form-group {min-width: auto; /*width: calc(100% - 175px);*/}
.row-pgk-list > li, .pkg_gcol { width: 33.333333333333%;}    
    
    .testimonial-section{background-image: none; 
        /*background: #000; */
        min-height: inherit; 
        /*padding: 0px 40px ;*/
    }    
    .testimonial-section > div{padding: 0}
    .promo-three-clm2 span{font-size: 13px;}
    .promo-three-clm2 h4{font-size: 18px}
    .ft-bt-col4{padding-left: 0}
}
@media (max-width: 1100px){
.order_delivery_mobile_tooltip, .selfcollect_tooltip .order_delivery_mobile_tooltip{top: -119px; left:-13px; right:-13px; height: 110px; opacity:1; visibility:visible; pointer-events:auto;}
.order_delivery_mobile_tooltip:before, .selfcollect_tooltip .order_delivery_mobile_tooltip:before{ top: auto; right: auto; left: 50%; bottom: -16px; border-color: #181818 transparent transparent transparent; border-width: 10px 7px 10px 7px; margin: 0 0 0 -7px;}
.order_delivery_mobile_tooltip p{ font-size: 13px; }    
.chekout_cart_bar .cart_extrainfo{padding-left: 0px;}
.ord-amount [class*='col-']{width: 100%;}
.earned_points{float: none;}
.ord-rec-sec{float: none;width: 100%; margin-top: 10px;}
.sprite_lst_icon{display: none}
.web_order_typecls li a h5{font-size: 16px}    
.f-nav > ul > li { padding-right: 20px;}    
.productlist-main-div{ padding: 20px 20px 20px;}    
.productlist-main-div .products-list-ulmain{justify-content: space-between;}
.productlist-main-div .products-list-ulmain > li{width:48.5%;margin: 0 0 30px 0;}    

}

@media (max-width: 1080px){
    .menu_icon.trigger_menunav_act{ display: block; width: 30px; height: 19px; margin: 3px 0px; padding: 0px 0px; cursor: pointer;}    
.menu_icon .icon-bar{ display: block; background-color: #000; height: 2px; -webkit-transition: all 400ms; transition: all 400ms;}
.menu_icon .icon-bar+.icon-bar { margin-top: 6px; }    
 .logo img { width: 100%;    
    /*max-height: 80px;*/
 } 
 .logo {
    max-width: 150px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.logo-main-section {
    height: 68px;
}
.hmenu_sec {
    display: none;
}
.mobile-login {
    left: 50px;
    top: 10px;
    right: inherit;
}
.header-top-cls {
    padding: 20px 0;
}
a.controller-nav img {
    margin-top: 10px;
}
    .myacc_order_details .booking-head {
            padding: 0 0 70px 0;
    }
        .choose-option-hover{
        display: none;
}
    .menu-nav-section h3{
        margin-top: 20px;
    }
    .home-top-four-section{
        padding: 15px 15px 0px 15px;
    }
.mobile_accountdel_body, .mobile-order-nowbtn, .mobile-login{display: block;}
.header_bot{display: block; margin: 15px 0 0 0;}       
.hordertype_sec, .f-address-img{display: none}  
.logo-main-section{-webkit-box-align: center;-ms-flex-align: center;align-items: center;justify-content: space-between;}    
.hsign_sec{display: none}     
.htico_cart { margin-left: 7px;}    

.mobile-login ul{right: 0; left: inherit;}   
.mobile-login {position: absolute; right: 70px;}    
a.controller-nav img { width: 28px;}    
.menu-section-left{display: none}    
.menu-section-mobile-inner, .mobile-menu-section-left{display: block}    
.product_det_cards > ul{justify-content: space-between}    
.product_det_cards > ul li.det_card{width: 49%; margin: 0 0 20px 0;}    
.choose-option-hover{width: 260px}    
.choose-option-hover ul{padding: 5px}
.choose-option-hover ul li a{font-size: 13px;font-family: 'avantgarde_bk_btbook';}
.choose-option-hover ul li { margin: 4px 0;}
.pdr_right.price-fixed{top: 136px}    

.welcome-rhs { padding: 40px 0 40px 40px;}    
.footer-bottom-middle ul li { margin: 0 3px;}    
.footer-bottom-middle ul li a{font-size:14px;}    
.footer-bottom-right h3{font-size: 14px}   
.wh-lhs-top h2 { font-size: 50px;}    
.wh-lhs-top p {font-size: 18px;}    
.white-home { padding: 0px 0 0px 0;margin-bottom: 0px;}    
.featured-products-section .products-list-ulmain li { width: 32%; margin: 0 2% 20px 0;}    
.featured-products-section .products-list-ulmain li:nth-child(3n) { margin-right: 0;}
.featured-products-section .products-list-ulmain li:nth-child(4n) { margin-right: 2%;}    
.footer-top img {
    width: 160px;
}    
    .promo-three-clm2 h3{font-size: 26px;}   
    section.menu-nav-section {position: relative;top: inherit;padding: 0;} 

    .product-details .container-full > div .inn-product-popup .prodet_bansec {flex: 1 1 100%;max-width: 100%;margin-bottom: 25px;}
    .product-details .container-full > div .inn-product-popup .inn_product_row {flex: 1 1 100%;max-width: 100%;}
}

@media (max-width: 1024px){
    .home-banner-main{
        margin-top: 0px;;
    }
    .header-logo,
    .header-top-cls {background-color: #fff;;}
.delivery{margin-top: 30px;}    
.prd_chosen_row{padding: 25px 20px 20px 20px;}
.congrats.redeem-promation-body{padding: 24px 0;}    
.prd-sub{max-width: 100%;}
.prd-sub .slick-prev{z-index: 1; background: rgba(255,255,255,0.75); left: 0;}
.prd-sub .slick-next{z-index: 1; background: rgba(255,255,255,0.75); right: 0;}
.product_chosen_hea h6{font-size: 14px;}
.tnk-you{padding:45px 0 30px 0;}
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 25px 30px 25px 45px;}  
.award_galimg{ height: 250px }  
.head_left h4{font-size: 14px} 
.head_right h4{font-size: 14px}    
.order_amt h3{font-size: 21px}    
    /*.four-columns h3{font-size: 50px}   */ 
    .top-bar p{font-size: 12px}    
    .top-bar span { font-size: 14px; padding: 14px 30px;}
    
}
@media (min-width: 992px) and (max-width: 1080px){
    .social-list{
        justify-content: flex-start;
    }
.four-columns h3 {
    font-size: 14px;
    line-height: 0;
}
.ft-bt-col3{
        width: 18%;
}
.ft-bt-col1 {
    width: 30%;
    padding: 0 0 0 0;
}
.ft-bt-col2 {
    width: 18%;
    padding: 0px 15px 0%;
    margin-bottom: 25px;
}
.ft-bt-col4 {
    width: 14%;
    padding: 0px 1%;
    margin-bottom: 15px;
}
.ft-bt-col5 {
    width: 18%;
    padding: 0px 15px 0%;
    margin-bottom: 15px;
}
.ft-bt-col6 {
    width: 100%;
    margin-left: 10px
}
.four-columns-btn{
        font-size: 13px;
}
.white-home .four-columns:hover .offer_txt a, .white-home .four-columns.active .offer_txt a{
    font-size: 13px;
}

.four-columns{
    padding: 10px;
}
}

@media (min-width: 1081px) and (max-width: 1280px){
.social-list{
        justify-content: flex-start;
}
.four-columns h3 {
    font-size: 14px;
    line-height: 0;
}
.ft-bt-col3{
        width: 18%;
}
.ft-bt-col1 {
    width: 30%;
    padding: 0 0 0 0;
}
.ft-bt-col2 {
    width: 18%;
    padding: 0px 15px 0%;
    margin-bottom: 25px;
}
.ft-bt-col4 {
    width: 14%;
    padding: 0px 1%;
    margin-bottom: 15px;
}
.ft-bt-col5 {
    width: 18%;
    padding: 0px 15px 0%;
    margin-bottom: 15px;
}
.ft-bt-col6 {
    width: 100%;
    margin-left: 10px
}
    .about-left{
        margin-top: 50px;
    }
}

@media (min-width: 768px) and (max-width: 991px){
.four-columns h3 {
    font-size: 13px;
    /*line-height: 0;*/
}
.four-columns-btn{
        font-size: 12px;
}
.white-home .four-columns:hover .offer_txt a, .white-home .four-columns.active .offer_txt a{
    font-size: 12px;
}

.four-columns{
    padding: 10px;
    width: 25%;
    margin: 15px;
}
}
@media (max-width: 1080px){
    .about-left{
        margin-top: 50px;
    }
    .testi-content p{
        text-align: center;
    }
.about-inner{
    padding-top: 90px;
    padding-bottom: 115px;
}
.testimonial-section h2{
        line-height: 0;
    margin-bottom: 60px;
}
}
@media (max-width: 991px){
    .activities-advanced-timeslot-left-right{
        display: block;
    }
    .activities-advanced-timeslot-left-5{
        width: 100%
    }
    .activities-advanced-timeslot-left-7 {
        width: 100%;
    }
    .col-lg-12.booking_hourchanges{
        display: block;
        text-align: center;
        
    }
    .activities-advanced-timeslot-left-6, .activities-advanced-timeslot-right, .activities-advanced-timeslot-left{
        width: 100%
    }

    .timeslot_info-right .activities-date{
        width: 100%
    }
    .timeslot_info-left .activities-time{
        width: 100%
    }
    .timeslot_info_left_right{
        display: block;
    }
    .timeslot_info-right, .timeslot_info-left{
        width: 100%
    }

    .timeslot_info_div .custom_select:after{
    width: 25px;
    top: 3pxpx;
    right: 2px;
    }
    .col-lg-12.booking_hourchanges .custom_radio{
        margin-top: 10px
    }
    .ft-tp-col1, .ft-tp-col3{
        width: 39%
    }
    .ft-tp-col2{
        width: 22%
    }
    .pricing-section table tr td:nth-of-type(1), .pricing-section .smaller-table tr td{
        text-align: center;
    }
    .pricing-section table tr td span{
        font-size: 18px;
    }
    .pricing-section h3{
        margin-top: 30px;
        text-align: center;
        font-size: 22px;
    }
    .pricing-section table{
        margin-bottom: 0px;
    }
.media-page .mediaul>li {
    margin-right: 0;
    width: 100%;
}
.media_row {
    display: block;
}
.media_info {
    padding: 20px 0px 10px;
    width: 100%;
    text-align: center;
}
.media_info h4 {
    font-size: 19px;
}
    .prog-bar {
    margin-bottom: 0px;
}
.contact_col {
    width: 100%;
}
    .pop-whole-lhs-inner h3{
            font-size: 23px;
            font-weight: 600;
    }
    .virtual-card-inner h4{
        font-size: 19px;
    }
    .promo-card-body{
        display: flex;
        justify-content: space-between;
    }
    .virtual-card-head p {
    font-size: 18px;
    }
    .packages-promos-popup .pop-whole-lhs-inner h3 {
        font-size: 35px;
    }
    .our-promotions-section.happening-section{
        padding: 5px 0 30px;
    }
    .inn-product-popup .inn_product_row .prd_chosen_sub_col .addcart_row .qty_bx {
        right: 0px;
    }
    .pt-0-home {
        padding-top:10px!important;
    }
    .menu-nav-section h3{
        margin-bottom: 20px;
    }
    .foot-left {
        margin-top: 0px;
    }
    .testimonial-section{
        /*padding: 0px 40px 20px;*/
    }
    .our-promotions-section{
        padding: 10px 0 10px;
    }
    a.controller-nav img{
        margin-top: 10px;
    }
    .outlet-lst-page {
        margin-top: 5px;
    }
    .ourrest_info, .ourrest_map {
        width: 100%;
    }
    .cdd-details-lhs .tab-menu-container ul li a{
        font-size: 15px;
    }
    .our-promotions-section > p{
        line-height: 20px;
    }
    .mobile-menu .menu a{
        font-size: 16px;
    }
    .foot-left{
        margin-bottom: -1px;
    }
    .upcoming-events-section > p{
        line-height: 20px;
    }
    .home-top-four-section{     line-height: 20px; }
    .about-inner{
        margin-top: 0px;
    }
    .upcoming-events-section h2{
        font-size: 28px 
    }
    .about-section .abt-inner h4{
        font-size: 30px;
    }
    .testimonial-section{
        background: none;
    }
    .about-inner{
        background-color: #0357ae;
        height: 100%;
        align-items: center;
        padding-top: 0px;
        padding-bottom: 24px;
    }
    .about-left {
        margin-top: 0px;
        width: 100%
    }
    .abt-inner {
    padding: 0px 10px 50px;
    }
    .about-section{
    padding-bottom: 0px;
    padding-top: 0px;
    }
    .about-inner-2{
        padding-top:50px
    }
    .footer-right{
        text-align: right;
        /* margin-top: -25px; */
        padding: 0 0 10px 0;
    }
    .ft-bt-col1 p{
        margin: 0;
        width: 100%;
    font-size: 16px;
    word-break: break-all;
    font-family: 'poppins';
    line-height: 22px;
    color: #fff;
    font-weight: normal;
    }
    .ft-bt-col2{
        padding: 0px 0px 0%;
        margin-bottom: 15px;
    }
    .ft-bt-col1{
        width: 100%!important;
        padding:0px ;
        order: 1;
        text-align: center;
    }
    .ft-bt-col6{
        /*order: 6;*/
    }
    .ft-bt-col4{
        width: 100%;
    /*order: 4;*/
    justify-content: left;
    text-align: left;
    }
    .fs-45 p{
        font-size: 45px!important;
        margin-top: 20px;
    }
    .cart_body {
        /*padding-top: 60px!important;*/
    }
    .detail-pg-slider button.slick-arrow, .products_sidebar_inner button.slick-arrow, .mobile-menu-section-left button.slick-arrow
    {
        top: 52%;
    }
    .inn_produc_title{
        font-size: 65px;
    }
    .detail-pg-slider .product-title h3{
        font-size: 18px;
    }
    .outlet-bg h1{
        font-size: 60px;
    }
    .home-banner-main{
        margin-top: 0px;;
    }
    .col-sm-6 {
        width: 100% !important;
    }
    .myacc-info-top p{
        width: 100%;
        text-align: center;
    }
    .myacc-info-top h3{ display: inherit;    text-align: center; }

    .header-top-cls{
        background-color: #fff;
    }
.hbslider_sec h2{ font-size: 20px;} 
.container_720 { max-width: 100%;}

/*--category--*/
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}
/*--update your package--*/
.pkg_listsec .bx_in {padding: 15px;}
.pkglist_itemtable > div {display: block;}
.pkglist_img {width: 100%; padding: 0px 0px 10px;}
.pkglist_info {width: 100%; padding: 15px 0px 0px;}
.pkglist_pricebx {width: 100%; border-left: 1px solid transparent; padding: 0px 0px; text-align: left;}
.pkselect_tabin {padding: 15px 0;}
.package_checkbx .bx_in {padding: 15px 15px;}
.package_formtt {font-size: 17px;}
.package_checkitem {padding: 15px 15px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 14px;padding: 5px 0 0 40px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 14px;}
.package_addonbx .bx_in {    padding: 20px 15px;}
.package_setupbx .bx_in { padding: 20px 15px;}
.package_descbx .bx_in { padding: 20px 15px;}
.title4 {  font-size: 16px;}

.form-group [class*="col-md-"]{ margin-bottom: 8px;}
.form-group [class*="col-md-"]:last-child { margin-bottom: 0; }    
.delivery-item span{ font-size: 14px;}
.delivery-item h2{ font-size: 24px;}
.delivery-item p{ font-size: 16px;}
/* Tab sec */
.nav-tabs{ display: none;}
.tab-content>.tab-pane{ display: block; opacity: 1; margin: 0 0 15px;}
.tab_mobtrigger{ display: block; position: relative; padding-right: 45px; margin: 0; cursor: pointer;}
.tab_mobtrigger i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.tab_mobtrigger i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.tab_mobtrigger.active i:after{ content: "\f068";}
.tab_mobrow{ display: none; margin: 0px 0 0;}
.tab-content > .tab-pane.active > .tab_mobrow{ display: block; padding-bottom: 0; padding-top: 0px;}
/* Main Tab */
.main_tabsec .nav-tabs{ display: none;}
.main_tabsec .tab_mobtrigger{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  }
.filter_tabsec .tab_mobtrigger{ padding: 5px 45px 8px 10px; font-size: 16px; }
.filter_tabsec .tab_mobtrigger:after{ 
content: ""; background: #ecdfcc; height: 3px; position: absolute; left: 0; right: 0; bottom: 0;  pointer-events: none; -webkit-transition: all 400ms; transition: all 400ms;}
.tab-content > .tab-pane.promation-tab-section.active > .tab_mobrow{padding-top: 0;}
.small_responsive_row .filter_tabsec .tab_mobtrigger.inner_tab_border{display: none;}
.small_responsive_row .tab-content > .tab-pane.active > .tab_mobrow{display: block;}
.main_tabsec .small_responsive_row  .nav-tabs{display: block; } 
.mobiCategory .nav-tabs{ display:none;}
.sub_tab_mobtrigger.active {background: #0b0405; color: #fff;}
.sub_tab_mobtrigger {background:#b69a6b; padding: 15px 45px 15px 15px; font-size: 16px;  color: #fff; display: block; position: relative; margin: 0; cursor: pointer;margin: 0px 15px;}
.sub_tab_mobrow{ display: none; margin: 10px 0px 0px;}
.tab-content > .tab-pane.active > .sub_tab_mobrow {display: block; padding-bottom: 0; padding-top: 10px;}
.sub_tab_mobtrigger i {position: absolute;width: 45px;height: 25px;line-height: 25px;right: 0;top: 0;bottom: 0;margin: auto 0;
text-align: center;}
.sub_tab_mobtrigger i:after {content: "\f067";font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.sub_tab_mobtrigger.active i:after {content: "\f068";}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 15px;}    
.main_tabsec .small_responsive_rewards  .nav-tabs{display: block;}
.small_responsive_rewards .filter_tabsec .tab_mobtrigger{display: none;}    
.delivery-inner-comman{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.delivery-item{margin-top: 10px;}    
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 18px;}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 14px;}
.delivery_total_left h3{
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
}
.delivery_total_left span{line-height: normal;}
.redeem-item-hea .points{font-size: 15px;}
.pop-whole-lhs{padding: 50px 15px;position: static;-webkit-transform: translateY(-0%); transform: translateY(-0%); }
.pop-whole-lhs-inner{max-width: 100%;margin: 0 auto;}    
.login-popup .mfp-close, .signup-popup .mfp-close, .signup-corparateone-popup .mfp-close, .signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close{right: 10px; top:5px;background: #000;opacity: 1;width: 30px;height: 30px;line-height: 30px;}
.signup-corparatetwo-popup .inside-popup-rhs, .guest-popup .inside-popup-rhs{padding: 50px 20px 15px 20px;}
.inside-popup-rhs{width: 100%;max-width: 100%;margin: 0 auto;float: none;    padding: 20px 35px 0px 35px;}
.popup_equalrw{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.pop_title{text-align: center;padding-left: 0;margin: 0 auto 10px auto;}
.pop_title i{position: static;text-align: center;margin: auto;}
.pop_title h2{margin-top: 0px;}
.self_pop_item_loc p{padding: 0 10px; }
.product_chosen_item_left{width: 100%;margin: 10px 0;}
.product_chosen_item_left_second {width: 100%;margin: 0;}
.inn_product_row{margin-top: 20px;}
.inn-product-popup-bg, .inn-product-popup-background{background-image: inherit !important;padding-top: 0;text-align: center;}
.inn-product-popup-inner{max-width: 100%;}
.other-add-row li{width: 100%;}
.account_sec{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.accmenu_sec{width: 100%;    height: auto;}
.accsec_right{width: 100%; padding: 20px 15px;}    
.mainacc_menuout{margin: 0 0 10px; }
.mainacc_menuico{ display: block; position: relative; padding: 9px 0 9px 50px; color: #191919; font-size: 17px; cursor: pointer; overflow: hidden; z-index: 1;  }
.mainacc_menuico b{ font-weight: normal;}
.mainacc_menuico:after{content: ""; position: absolute; left: 0; top: -100%; width: 100%; height: 100%; background: #ecdfcd; -webkit-transition: all 0.5s; transition: all 0.5s; z-index: -1;}
.mainacc_menuico.active:after{ top: 0;}
.mainacc_menuico i{ position: absolute; left: 1px; top: 1px; font-size: 18px; width: 40px; height: 40px; line-height: 40px; background: #ecdfcd; text-align: center; -webkit-box-shadow: 0 0 1px  0 #555; box-shadow: 0 0 1px  0 #555;}
.mainacc_menuico i:before{ -webkit-transition: all 0.5s; transition: all 0.5s;  display: block;}
.mainacc_menuico.active i:before{ -webkit-transform: rotate(90deg); transform: rotate(90deg);}
.mainacc_menulist{ display: none;}
.mainacc_menulist > li{ display: block; width: auto; border-left: 0; text-align: left; border-top: 1px solid #ddd1c0;}
.mainacc_menulist > li >a{ text-transform: none; padding: 10px 15px 10px 50px;    background: #ecdfcd; color: #191919;}
.mainacc_menulist > li >a:after{ display: none;}
.mbacc_mslidersec{ display: block; padding: 0; position: relative;}
.mbacc_mslidersec .slick-track .slick-slide > div, .mobile-catering-slider .slick-track .slick-slide > div{ width: 100%; padding:0 1px}
.mbacc_mslide a, .mobile-catering-slider .slick-track .slick-slide a{padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #000;
    font-family: 'poppins';
    font-weight: bold;
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal;
    display: block;
    height: 100%;
    text-align: center; }
    .focused label {
        padding: 0 0px;
        font-size: 10px;}
.mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow{ background: #000; border: 0; width: 30px; height: 30px; position: absolute; left: 2px; top: 0; bottom: 0; margin: auto 0; text-align: center; overflow: hidden; font-size: 0;color: #fff; z-index: 1;padding: 0}
.mbacc_mslidersec .slick-next{ right: 2px; left: auto;}
.mbacc_mslidersec .slick-arrow:before, .mobile-catering-slider .slick-arrow:before{ 
    position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%); background: url(../images/left-arrow.png) no-repeat; content: "";
    margin-left: 7px;
 }
.mbacc_mslidersec .slick-next:before, .mobile-catering-slider .slick-next:before{ 
    transform: rotate(180deg);
    margin-top: -10px;
}
.mbacc_mslidersec .slick-track {
    border-bottom: 4px solid #e7e6e6;
}    
.mbacc_mslide a:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #0271e5;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}    
.mbacc_mslide.active a{color: #000;}   
.mbacc_mslide.active a:before{  left: 0;right: 0;}    
    
.main_tabsec_inner{ padding: 10px 0px;}
.mainacc_menusec .main_tabsec_inner .nav-tabs{ margin-bottom: 0px;}
.main_tabsec_inner > .tab-content > .tab-pane.active > .tab_mobrow{ padding-top: 0; margin-top: 0;}
.mainacc_moblink{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  
color: #fff; display: block; position: relative; margin: 0 0 10px; cursor: pointer; display: block;}
.mainacc_moblink:hover, .mainacc_moblink:focus{ color: #fff;}
.mainacc_moblink i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.mainacc_moblink i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.mainacc_moblink.active i:after{ content: "\f068";}

.filter_tabsec .tab_mobtrigger.inner_tab_border{background: inherit;border: 0;color: #000;text-align: center;text-transform: uppercase;}
.myacc_filter{padding-bottom: 0;}
.main_tabsec_inner .nav-tabs{ display: block; border: 0;}
.main_tabsec_inner .tab_mobtrigger{ display: none;}
.main_tabsec_inner> .nav-tabs>li{ margin: 0; padding: 0 2.5px;}
.main_tabsec_inner> .nav-tabs>li> a{ text-align: center; font-size: 16px; height: auto; padding: 13px 15px; border-radius: 4px;}
.main_tabsec_inner> .nav-tabs>li> a > span > b{ display: none;}
.main_tabsec_inner> .nav-tabs>li > a > i{ display: none;}
.main_tabsec_inner> .nav-tabs>li > em { display: none;}
.acc-inform .form-group [class*="col-md-"]{ margin-bottom: 11px;}
.acc-inform .form-group [class*="col-md-"]:last-child{ margin-bottom: 0;}
.redeem_pro li h2{font-size: 30px;}
.redeem_pro li h3{font-size: 21px;}
.redeem_pro li{margin: 0 15px;}
.promation_btn li{width: 100%;margin-right: 0;margin-bottom: 5px;}
.promo-earned-content h4{font-size: 20px;}
.promo-earned-content h2{font-size: 26px;}
.promo-earned-content p{font-size: 15px;}
.abc_code span{font-size: 18px;}
.bg_top_slider h3{font-size: 17px;}
.banner-inner h5{font-size: 15px;}
.banner-inner span{font-size: 13px;}
.delivery-inner{padding: 20px 0;}
.tnk-delivery{padding: 15px;}
.chk-payment-btn-row .row{display: block;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.chk-payment-btn-row .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.chk-payment-btn-row .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.delevery_popup .btn_sec .row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.delevery_popup .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.delevery_popup .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.back_click{position: static;margin-bottom: 10px;}
/* Thank You */
.tick h2{ font-size: 60px;}
.tnk-detail h2{ font-size: 16px;}
.tnk-order h3{ font-size: 18px;}

/* Contact us */
.contactus_wrapper .row{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;}
.contactus_wrapper .contact_left{ -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2;}
.contactus_wrapper .contact_right{ -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; margin: 0 0 20px;}
.cms-page h3{ font-size: 22px;}
.award_galinfo h3{font-size: 17px}
/*promo*/
.promation_btn{ text-align: center; }
.promo-earned-col {width: 100%; display: block; margin: 0 0 15px; }    
.redeem_popsec .promo-earned-content{ width: 100%; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ width: 35%; }
.innersection_wrap {  padding: 0px 0 0 0;}
.count_num_t{display: none}    
.web_order_typecls li a{padding: 13px 10px}    
.web_order_typecls{margin-bottom: 30px}    
.promo-form h3 span{    font-size: 35px;
    line-height: 30px;}    
.promo-form h3{font-size: 24px}    
.mypromo-main-div .myacc_order_details{
    /* -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between */
    justify-content: space-between;
    margin-top: 10px;
}  

.myacc_order_details{
    margin-top: 10px;
}

.mypromo-main-div .myacc_order_details .promo-earned-col{ width: 49%; margin: 0 0 20px 0 }    
    
.mobile-catering-slider{ display: block !important; padding: 0 40px 0; position: relative}  
.row-pgk-list > li, .pkg_gcol {  width: 50%;}     
.mobile-catering-slider button.slick-arrow{ opacity: 1; width: 40px; height: 52px }     
.mobile-catering-slider button.slick-prev { left: -40px;}
.mobile-catering-slider button.slick-next { right: -40px;left: inherit}    
.mobile-catering-slider .slick-track .slick-slide > div{ padding: 0 }    
.mobile-catering-slider .slick-track .slick-slide a{ padding: 10px }  

.reservation-full{padding: 25px 0px;}
.back_click{display: none}
.back_click_mbl {  position: absolute; display: block; top: 10px; left: 10px;} 
.back_click_mbl a{color: #fff;}    
.chk-payment .form-group.custom-tarms-div {  padding: 0 5px 20px;}    
.membership_status img{ max-height: 72px }    
.membership_status span{top: 18px}        
.setting_menu_list, .promo-earned-info:before{ display: none;}    
.accprofile{ padding: 20px 10px 20px;}    
footer { margin: 20px 0 0 0;}    
.promo-earned-col-image {  width: 100%;}    
.promo-earned-info { width: 100%; flex-flow: column wrap; padding: 20px 10px;}

 .promo-earned-col-image img{max-width: 100%;}    
}

@media (max-width: 980px){ 
    .Viewreceipt .tnk-order{
        background: #fff;
    }
    
    .tnk-you .tnk-delivery .order-items-maindiv .cart_right{
        width: 130px;
    }
    .reward-list-body .delivery_total.delivery_total_number .delivery_total_left h4{

    }
    .inner-bdy{
        padding:0;
    }
    .reward-list-body .myacc_order_details .current_order{
        margin-top: 10px;
    }
    .product-tag-list ul li{
        margin: 0 2px;
    }
    .detail-pg .owl-stage-outer .owl-stage .owl-item{
        left: 65px;
    }
    .white-home{
        margin-bottom: 10px;
    }
    .two-links{
        padding: 15px 30px 15px 10px;
    }
    .cart_right {
        width: 108px;
    }
    .cart_remove{
        right:5px;
    }
    .mt-20{
        margin-top:0px;
    }
    .m-mt-15{
        margin-top: 15px;
    }
    .prd_chosen_sub_row > p {
        margin-top: 0px;
    }
    .ft-tp-col1, .ft-tp-col2, .ft-tp-col3{
        margin: 25px 0px 5px 0px!important;
    }
    .home-banner-main{
        margin-top: 0px;;
    }
    .custom_center_alertcls{
        font-size: 18px!important;
    }
    .hcart_dropdown .cart-header-first .cart_left h4{
        margin-bottom: 0px;
        text-align: center;
        font-size: 60px;
    }
    .head_left h4{
        font-size: 16px;
    }
    .head_right h4{
        font-size: 16px;
        margin-right: 0px;
        font-weight: bold;
    }
    .focus-out label{
        font-size: 12px;
        z-index: 1;
    }
    .outlet-bg{
        background-size: cover;
        background-position: bottom;
    }
    .header-top-cls{
        background-color: #fff;;
    }
    .mobile-menu .menu{
        margin: 0px 0 30px;
    }
.product-menu-listing .product-rhs{ 
    background: #fff;
    width: 100%;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
    z-index: 99;
    max-width: 620px;
    padding: 45px 0 55px 0;}
body.cart-items-open .product-menu-listing .product-rhs{
    -webkit-transform: translateX(0);
    transform: translateX(0);
}    
.product-menu-listing .product-rhs .hcart_dropdown{ overflow-y: auto;
    height: 100%;  }    
.product-menu-listing .product-rhs .hcart_dropdown .fixed_checkout { position: fixed; bottom: 0; left: 0; right: 0;}    
    .product-menu-listing .product-rhs .hcart_dropdown .fixed_checkout a{border-radius: 0}    
    
.product-rhs .product_orders_top{display: flex}    
.product-lhs{width: 100%;padding-top: 10px}

}


@media (max-width: 850px){          
    .home-banner-main{
        margin-top: 0px;;
    }
    .header-top-cls{
        background-color: #fff;;
    }
    .hme-product-inner .title_sec{margin-bottom: 5px;}
.delivery_bg{ background-size: cover; }
.popup_ttsec, .news_postal_popup .popup_ttsec{width: 100%; padding: 10px 15px; height: auto;}
.popup_right{width: 100%; padding: 10px 15px;}
.news_postal_popup .popup_right{width: 100%; height: 300px;}
.awesome_del h2{margin-bottom: 0; font-size: 13px;}
.title_sec h2{font-size: 29px;}
.title_sec small{font-size: 14px;}
.hme-prt-slidecontent{padding: 10px 15px; top: 0; right: 0; bottom: 0; left: 0;}
.hme-prt-slidecontent-inner p{font-size: 13px;}

.tnk-header{max-width: 100%;}
.tnk-you .container{ padding-left: 0; padding-right: 0;}
.tnk-delivery .cart_footer.tnk_cart_footer{margin-top: 10px;}
.order-cat-table table{max-width: none;}
.ord-amount-item{max-width: 97%;}
.htopinfo p{font-size: 10px;}
.htopinfo p span{ font-size: 8px;}
.htopinfo{width: 100%; text-align: center;}
.htmenu_sec{width: 100%;text-align: center;}
.horder_btn .btn{width: 100%;}
.inn-product-popup{margin-top: 20px;}
.detail-pg-inner{padding: 50px 10px 0;} 
/*upadate package*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time { width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {font-size: 16px; margin: 0 0 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.tbars header{ height: 147px;}
}
@media (max-width: 780px){
    .Viewreceipt .cart_left {
    width: calc(100% - 130px);
}
h1{ font-size: 35px}
h2{ font-size: 30px}    
h3{ font-size: 26px;}        
h4{ font-size: 21px;}    
.home-outlets-list {padding: 40px 0 40px 0;}        
.date_list {padding: 20px 15px;}
.time_slot {padding: 20px 15px 20px;}
.add_time {margin: 10px 15px 20px 15px;}
.choose_location {padding: 20px 15px 20px; margin-bottom: 20px;}
.cedele-location {padding: 2px 15px 0px 0px;}
.location-list ul li {font-size: 14px; line-height: 1.3; height: auto; padding: 10px 15px;}
.catering_checout .checkout-total .cdd-details-lhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.catering .ttsplitter {margin: 0 auto 10px;}
.catering_checout .checkout-total .checkout-control-group-top {padding: 20px 0px 20px 0px;}
.checkout-total .checkout-control-group-middle {padding: 20px 0px 0px 0px;}
.catering_checout .checkout-total .cdd-details-rhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.event-time .title4 {font-size: 16px;}
.inner_banner {  height: 250px;}    
.myacc_order_details .current_order{width: 100%}    
.web_order_typecls{ margin: 0 0 20px 0 }    
.order_delivery_item{ padding: 10px 20px 0 20px;}    
.order_delivery_item {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    margin: 0 auto;
    padding: 15px 0 0 0;
    max-width: 740px;
}
.order_delivery_item li {
    width: 55%;
    margin: 0 auto;
    padding: 0px 0px 20px 0px;
}
.order_delivery_item li .header-ordernow-single-img img {
    height: 75px;
}
.order_delivery_item h3 {
    font-size: 20px;
}
.order_popup {
    max-width: 360px;
}
.checkout-total{margin: 0 auto}    
.hmenu-login-section {position: static;top: inherit; transform: none;}
.birthday-info-div{margin-bottom: 20px;     padding: 0px 5px 0px 5px;}    
.hmenu_sec{display: none;}
.menu_icon.trigger_menunav_act{ display: block; width: 30px; height: 19px; margin: 3px 0px; padding: 0px 0px; cursor: pointer;}    
.menu_icon .icon-bar{ display: block; background-color: #000; height: 2px; -webkit-transition: all 400ms; transition: all 400ms;}
.menu_icon .icon-bar+.icon-bar { margin-top: 6px; }    
 .logo img { width: 100%;    
    /*max-height: 80px;*/
 }   
.mobile-login {left: 50px; top: 10px;right: inherit;}    
.mobile-login ul { left: 0; right: inherit;}   
.header-top-cls {padding: 20px 0;}    
.logo { max-width: 150px; position: absolute; left: 0; right: 0; margin: auto;}    
.logo-main-section { height: 68px;}    
.profile-info-div {
    margin: 15px 0;
}
.copyright-section { padding: 10px 10px;}    
.copyright-section p { font-size: 13px;}    
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link{font-size: 15px; display: flex; justify-content: center; align-items: center;}
.common-inner-banner{    
    background-size: cover;
    min-height: 80px;
    /*background-size: contain;*/
    /* background: url(/static/media/bg-overlay.png) no-repeat; */
}    
.accsec_right h1 { font-size: 30px;}
.accsec_right p { font-size: 16px;}    
.home-banner .btn-act-clss { padding: 10px 16px 10px; font-size: 14px;}    
.package_details{flex-wrap: wrap; }
.package_details_left { width: 100%;margin-bottom: 10px}
.package_details_right { width: 100%;}
  
.pdr_right.price-fixed { top: 133px;} 
.featuredpro-section{padding: 40px 0 30px 0}    
.featured-products-section .products-list-ulmain{justify-content: space-between}    
.featured-products-section .products-list-ulmain li {width:49%; margin: 0 0 20px 0;}    
.featured-products-section .products-list-ulmain li:nth-child(4n) { margin-right: 0}    
.featuredpro-section:before, .welcome-lhs:before{display: none}    
.featured-products-section h2{padding-bottom: 0}    

.wh-lhs { margin: 0 auto;}    
.wh-lhs-top h2 { font-size: 38px;}    
.wh-lhs-top p { font-size: 16px;}    
.wh-lhs-bottom ul {padding: 70px 0 0 0;}    
.welcome-lhs img { visibility: visible;}    
.testimonial-section h2 { margin: 0 0 10px; font-size: 24px;    line-height: 55px;}  
.about-section{
    margin-bottom: 0px;
}  
.four-columns h3 {
    font-size: 16px;
    margin: 10px;
}
.our-special-section h2, .our-promotions-section h2{
    font-size: 28px;
}
    .our-special-section h2, .our-promotions-section h2{font-size: 28px; line-height: 1;    margin-bottom: 20px;}    
    .home-top-four-section{
        /* background: #FAE2C7; */
        padding: 15px 0px 0px 15px;
    }
    .four-columns {  width: 30%;  padding: 15px;margin: 10px}
    .four-columns-btn{
            font-size: 15px;
    }
    .white-home .four-columns:hover .offer_txt a, .white-home .four-columns.active .offer_txt a{
         font-size: 15px;
    }
    .our-promotions-section.our-special-section{padding-top: 10px}
    .our-promotions-section.promo-section .slick-track .slick-slide img { width: 100% }
    .promo-three-clm1 {
    width: 50%;    order: 1;
}
    .promo-three-clm2 {
    width: 100%;
    padding: 20px;    order: 3;
}
    .promo-three-clm3 {
    width: 50%;order: 2;
}
.promo-form .applyBtn{
    right: -10px;
    min-width: 120px;
}
.promo-form .applyBtn{
    border-radius: 10px;
}
.footer-main {
    padding: 0px 0 0 0;
    background-size: contain;
    /* background-color: #0271e5; */
}    
.footer-right img{
    /*margin-top: 0px;*/
}
.footer-top img {
    width: 180px;
}
.footer-top img {
    margin-top: -65px;
}
    .ft-ct-bottom{justify-content: space-between}    
.ft-bt-col1 {
    width: 60%;
    padding: 0px 0px 20px;
    order: 1;
}
.ft-bt-col2 {
    order: 1;
    width: 18%;
}
.ft-bt-col3 {
    order: 2;
    width: 33%;
}
.ft-bt-col4 {
    order: 3;
    width: 33%;
    padding-right: 0;
}
.ft-bt-col5 {
    order: 4;
    width: 33%;
    padding-bottom: 20px;
    padding:0px 0px 0%;
}

.ft-bt-col6 {
    order: 5;
    width: 33%;
    padding-bottom: 0px;
}

.white-home {
    padding: 0px 0 0px 0;
}
.home-banner-main{
    margin-top: 0px;;
}
.header-top-cls{
    background-color: #fff;;
}
    .testimonial-section .slick-track .slick-slide > div{padding: 0}    
    .testi-author {  padding: 30px 0 0;}
    
}

@media (max-width: 767px){
    .controls.single-link{
        padding-bottom: 20px; 
    }
.outlet-lst-page .outletul>li {
    margin-right: 0;
    width: 100%;
}
.ourrest_info {
    padding: 40px 30px 70px;
    width: 100%;
}
.self_pop_col .self_pop_col_right {
    width: 100%;
    margin-left: 0;
    max-width: 100%;
}
.outlet-list-rhs ul li {
    margin: 15px 19px 4px 0px;
    font-size: 11px;
}
.self_pop_col {
    width: 100%;
    margin-bottom: 0;
}
.self_outlet {
    margin-top: 15px;
    height: auto;
}
.self_outlet_inner {
    height: 150px !important;
}
.outlet-list-lhs>img {
    max-height: inherit;
}
.outlet-list-rhs ul li:last-child {
    margin: 5px 0 0;
}
.self_popup  {
    padding: 20px 15px;
}
.self_pop_row {
    height: auto;
}
.detail-pg {max-width: 800px;}
}
@media (max-width: 480px){
.checkout-total .cdd-details-lhs h4, .checkout-total .cart-header h4{
    margin-top: 20px;
    font-size: 18px;
}  

.delevery_popup_datetime .btn_sec, .takelist-btn-part {
    max-width: 100%;
    flex-wrap: wrap;
}

.col-sm-cls.cart_right.text-right{
    width: 100%;
}
}

@media (max-width: 767px){   
    .myvouchar-main-div .myacc_order_details .promo-earned-col{
        margin-bottom: 20px;
    }
.Viewreceipt .cart_left{
        width: calc(100% - 130px);
}
.title1{font-size: 25px;}
.title3{ font-size: 18px;}
.htico_search{ position: static;}
.hsearch_sec{ left: 15px; right: 15px; width: auto;top: 80px;}
.banner .container, .delivery .container, .hme-product .container, .outlet .container{ padding-left:0; padding-right: 0; }

.location-mapsec > div{ height: 350px;}
.main_tabsec .nav-tabs{ margin: 0 0 25px; border-width: 1px;}
.main_tabsec .nav-tabs>li>a{ font-size: 14px; padding: 10px 10px;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.video_popup .youtube_player_iframe{ height: 300px;}
.selfpop_mapouter{ height: 280px !important;}
.chekout_cart_bar .cart_info{width: auto;}
.chekout_cart_bar .order-detail-inner-icon{margin: 0 auto;}
.redeem-col{padding: 15px 10px;}
.redeem-item-hea h4{font-size: 20px;}
.pop-whole-lhs{padding: 40px 15px;}
.inside-popup-rhs, .signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs{padding: 15px 20px;}
.signup-corparatetwo-popup .inside-popup-rhs{padding:50px 20px 15px 20px;}
.popup-header{padding: 10px 0 10px 0;}
.guest_chk_out{margin-left: 0;margin-right: 0;}
.guest_chk_out .btn{white-space: inherit;height: auto;}
.pop_title h2{font-size: 25px;}
.order-body {
    padding: 30px 0;
}
.order-body h2{font-size: 32px;}
.order-body p {
    color: #000;
    font-size: 12px;
    line-height: 30px;
    margin: -10px 0 0px 0;
}
section.menu-nav-section {
    padding: 0px 0 0 0;
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    z-index: 9;
    /* background: #fff; */
}
.white-popup {
    max-width: 365px;
    padding: 0;
    border: 1px solid hsl(211deg 98% 45%);
    /* margin: 0px auto; */
    background: #fff;
    z-index: 11;
}
.white-popup.self_popup {
    max-width: 362px;
}
.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 0px;
}
.self_pop_row {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 15px;
}

.pop_title small{font-size: 17px;}
.self_pop_row{ height: auto;}
.self_outlet{margin-top: 15px; height: auto;}   
.self_pop_col{width: 100%;  margin-bottom: 0;}
.self_pop_col.self_pop_col_right{width: 100%;margin-left: 0;max-width: 100%;}
.self_popup_map_col{ height: auto; margin: 0;}
.self_pop_item_map{width: 100%; margin: 0 auto; display: none;}
.self_popup .self-back{ display: none;}
.self_outlet_inner{height: 330px;}
.self_pop_item_loc .or-seperator-row{display: none;}
.pouup_in{padding: 30px 20px 20px;}
.redeem_popsec .pouup_in{ padding: 30px 20px 20px;}
.redeem_popsec .title3{ font-size: 20px;}
.hme-product-inner .ttsplitter{margin-bottom: 0;}
.product_chosen_col{width: 100%;margin: 10px 0;}
.product_chosen_col_inner{margin-bottom: 15px;margin-left: 0;margin-right: 0;}
.accsec_right h1{font-size: 27px;}
.accsec_right p{font-size: 14px;}
.accsec_right .ttsplitter_md{margin-top: 20px;}
.redeem_pro li.pro-subscribe button{font-size: 16px;}
.outlet_inner{padding: 23px 20px 20px 20px;}
.breadcrumb_left{width: 100%;display: inline-block;}
.breadcrumb_right{width: 100%;float: none;display: inline-block;margin: 10px 0;}
.breadcrumb_right .chosen_left{ left: 0; right: auto;}
.breadcrumb_right .re_select{max-width: calc(100% - 73px);}
.bakery_inner h3{font-size: 23px;}
label{font-size: 14px;}
.process_right p{font-size: 15px;}
.product_tag{float: none;}
.product_chosen_col .text-box{margin-left: 0;}

.top-bar span:after {display: none;}
/*----Venue Details page----*/  
.myacc_hea h3 {font-size: 20px;}
.ttsplitter span {font-size: 14px;}
.choose_location h4 {font-size: 14px;}
.choose_location .custom_radio span {font-size: 14px; line-height: 30px;}
.choose_location .custom_radio span:before {width: 26px; height: 26px;}
.cedele-location .form-group {margin: 20px 0 20px;}
.date_list h3 {font-size: 14px;}
.res-datetime-picker ul li dl dd, .catering_days_ul li dl dd {font-size: 17px;}  
.res-datetime-picker ul li dl.time_inner dd {font-size: 20px;}
.res-datetime-picker ul li dl dt {font-size: 12px;line-height: 1;}
.res-datetime-picker ul li dl.cl-next-days span {font-size: 14px;}
.res-datetime-picker ul li dl.cl-next-days {line-height: 1;}
.time_slot .time {padding: 0px 0px 10px 0px;}
.add_time {margin: 10px 15px 10px 15px;}
.time_slot .order-btn {padding: 20px 0px 15px;}
.time_slot h4 {font-size: 14px;}
.time_slot .order-btn .no {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
.time_slot .order-btn .yes {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
.time_slot .add_time .label {float: none; font-size: 14px; display: block; width: auto; text-align: center}
.time_slot .time label {font-size: 14px;}
.location-list ul li {font-size: 13px;}
/*----Checkout page----*/
.order_details .panel-title>a {font-size: 14px;}
.order_details .panel-body {padding:15px 10px;}
.package .package_cnt {padding: 0px 0px 15px;}
.package .package-list {padding: 0px 0px 10px 0px; color: #000000;}
.checkout_time {padding: 0px 0px 10px;}
.checkout_time label {font-size: 14px;} 
.package .title {font-size: 14px;}
.package .package-list .one {font-size: 14px;}
.package .package-list .two {font-size: 14px;}
.package .qty_bx {margin-top: 10px;}    
   
.package .amt {margin-top: 15px;}
.amt span {font-size: 14px;}
.addon {padding: 15px 0px 15px 0px;}
.addon h4 {font-size: 14px;}
.addon_list h5 {margin: 10px 0px 15px 0px;}
.addon_list h5 .title {font-size: 14px;}
.addon_list p {margin: 0px 0px 0px 0px; font-size: 14px;}
.total_amt {padding: 15px 15px 10px 15px;}
.total_amt .total {font-size: 20px;}
.total_amt .subtotal {font-size: 14px;}
.buffet_setup {padding: 10px 0px 15px;}
.buffet_setup h5 .title {font-size: 14px;}
.buffet_setup p {font-size: 14px; margin: 0px 0px 7px 0px;}
.download-quote .btn {font-size: 14px;height: 30px;}
.catering_checout .redeem-item-hea h4 {font-size: 18px;}
.catering_checout .redeem-item-hea span {font-size: 14px;}
.catering_checout .redeem-item-hea .points {font-size: 13px;}
.catering_checout .redeem_apply .form-control {height: 40px;}
.catering_checout .focus-out .btn_minwid {min-width: 100px; padding-left: 15px; padding-right: 15px;}
.catering_checout .focus-out .btn {font-size: 14px; height: 40px;}
/*-----Thank You page------*/
.catering_thak_you .package .title {padding-left: 0px;}
.catering_thak_you .order_details .qtybxs {font-size: 14px;line-height: 1.6;}
.catering_thak_you .package .amt {margin-top: 0px;}
.catering_thak_you .addon_list h5 .title {padding-left: 0px;}
.catering_thak_you .addon_list h5 {margin: 10px 0px 5px 0px;}
/*-----Categories------*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time { width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {font-size: 15px; margin: 0px 0px 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.event-time .form-control { height: 45px;}
.making-place {font-size: 14px;}
.event-time .title4 {font-size: 15px;margin-bottom: 7px;}
.title4 {font-size: 14px;}
.bts.text-right {text-align: left;}
.pkselected_filtersec label {font-size: 14px;}
.pkg_gimg img{width:100%;}
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}  
/*Addon*/
.pkgaddon_checklist li {width: 100%; padding: 0px 3px;}
.pkgaddon_checklist .custom_checkbox span {padding: 15px 15px 15px 70px; font-size: 16px;}
/*SETUP*/
.package_controltt .custom_radio span {font-size: 14px;}
.pkgsetup_list .package_formchecklist {padding-left: 0px;margin: 20px 0px 0px;}
.pkgsetup_list .package_formchecklist .custom_checkbox span {font-size: 14px;}
.package_form_helptext {font-size: 14px;}
.package_formchecklist .custom_checkbox span b {font-size: 14px;line-height: 1.5;}
.pkselected_package{margin-bottom: 10px;}   
.pkglist_pricebx > b {font-size: 16px;}
.pkselected_package .chosen-container-single .chosen-single {height: 42px;line-height: 42px;}
/*promo*/
.promation_btn .btn {font-size: 14px; height: 35px; padding: 7px 10px; min-width: 100px; }
.prodet_bansec{ display: block;}
.back_arrow{ width: 43px; height: 43px; line-height: 43px; }
.back_arrow img{ max-width: 11px;}
.prodet_banleft{width: auto; padding: 15px 15px 10px 15px;}
.prodet_baninfo{width: auto;}
.product_modifiers_listinner .modifiers_main_list{ width: 100%;}
.package_checkbx .package_formchecklist .custom_checkbox span{ font-size: 16px}    
.pkglist_img img{ width: 100% }
.package_checkrow {margin-bottom: 0px;}    
.pkg-detail-top-row{ -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: wrap column; flex-flow: wrap column; -webkit-box-align: center; -ms-flex-align: center; align-items: center;}    
.pkselected_filtersec .form-control{ padding-right: 46px }    
.datelist_bracktime h3, h3.choose-eventtype { font-size: 20px;margin: 0 0 10px 0;}    
.pkselected_catgrytext {  text-align: center; margin: 10px 0;}    
.checkout-total .checkout-control-group-bottom textarea{height: 110px}    
footer{ margin: 10px 0 0 0 }    
#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls{font-size: 16px}    
#CartListMdl .Cart-list-mdlheader h2, #PackageEditMdl .Cart-list-mdlheader h2 {font-size: 26px; margin: 5px 0 10px 0;}    
.locate-lirow{ display: block }    
.mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close, .mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close, .redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close, .venue_popup .close-icon-but{ top: 9px; right: 10px; }    
.guest-checkout-popupdiv{margin: 0 -20px;padding: 25px 25px;}    
.login-popup .inside-popup-rhs{padding-bottom: 0}    
.productlist-main-div .products-list-ulmain{justify-content: space-between;}    
.productlist-main-div .products-list-ulmain > li{width: 48.5%; margin: 0 0 20px 0;}    
.common-inner-banner p{font-size: 45px; margin-top: 5px;;}    
.chk-payment-title h3, .chk-addonpro-title h3 {     font-size: 19px;line-height: 25px;
    padding-top: 8px;}    
.chk-payment { padding: 20px 0px 20px 0px;margin-bottom: 0px}    
.mainacc_toptext { padding:0px 0 5px 0;}    
 .products_sidebar_inner ul li a{font-size: 14px;    line-height: 1.2;}    
.footer-bottom-middle ul, .footer-bottom-right ul { text-align: center;}    
}
@media (max-width: 750px){ 
    .ft-bt-col6{
        order: 6;
    }
}

@media (max-width: 680px){ 
.delivery_bg{ width: 100%;}
.innerproduct-col{width: 100%; margin: 0 0 15px;}
.cart_body{padding: 10px;}
.cart_left{/*width: 100% !important;*/ text-align: left;}    
.tnk-delivery .cart_row{padding-top: 5px;}
.cart-header-first .cart_right{margin: 20px 0 0 0; text-align: left; }    
.menu_icon{margin-right: 0;}    
.delivery-inner{ padding: 0;}
.delivery-item{ width: 100%; max-width: 100%; padding: 20px 15px 16px;  margin-top: 0; border: 0;border-bottom: 1px solid #f0e8dd;}
.delivery-item:last-of-type{border-bottom: 0;}
.hme-prt-row .slick-slider, .detail-pg-slider .slick-slider{padding: 0 0px;}
.hme-product-inner .slick-prev, .hme-product-inner .slick-next{z-index: 1; background: #f5f5f5;}
.detail-pg-slider-comman .slick-prev, .detail-pg-slider-comman .slick-next{ z-index: 1;background: #f5f5f5; }
.inn-product-popup-inner{max-width: 100%;}
.accpoints_bxin{padding: 20px 13px 55px;}
.accearn_pointtop span{font-size: 35px;}
.accsec_right .ttsplitter span{z-index: 1;}
.ord-amount .row{width: 100%;}
.ord-rec-sec li{width: 100%; max-width: 100%;}
.ord-amount [class*='col-']{padding-right: 0;}
.ord-amount{padding: 10px 0 0 10px;}
.order-cat-table{padding: 0 10px;}   
.order-cat-hea{max-width: 100%; padding: 8px 10px; margin-top: 10px;}
.ord-amount h2{font-size: 22px;}
.order-cat h2{font-size: 18px;}
.ord-amount-item{max-width: 100%;}
.cur-ord-rec{float: none;  padding: 0 10px;   width: 100%;  max-width: 100%; }
.cur-order-del{ max-width: none; padding: 0 10px; }
.cur-ord-rec li{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.click_view{text-align: center; margin: auto; padding: 0 10px; left: 0; right: 0;}
.cur-order-del li{margin-bottom: 10px; width: 100%; padding-left: 0; font-size: 19px;}
.cur-order-del li.cur-order-del-time{ width: 45%; text-align: right; margin-bottom: 0;}
.cur-order-del li.cur-order-del-date{ width: 55%; margin-bottom: 0;}
.cur-order-del li a.btn{ display: block;}
.cur-ord-rec-desktop, .click_view_desktop{ display: none;}
.cur-ord-rec-mobile{ position: relative; padding: 0 0 10px; border-bottom: 1px solid #efefee; margin: 10px 0;}
.myacc_dropmenu{position: absolute; width: 100%;left: 0;top: 100%; padding: 15px;}
.myacc_dropmenu::after{top: -16px; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 33px; -webkit-transform: rotate(448deg); transform: rotate(448deg);}
.check-pg-slider .slick-slider{padding: 0;}
.check_pg_inner .slick-prev, .check_pg_inner .slick-next{background: #f5f5f5;}
.banner-text-box-left{width: 100%; margin-bottom: 10px;}
.banner-inner h5{ height: auto;}
.banner-inner span{ height: auto;}
.banner-text-box-right{width: 100%;}
.banner-text-box-right .btn_minwid{margin-bottom: 0;}
.banner-text-box{padding: 20px 18px 20px 20px;}
.banner-right-content .btn{min-width: 100%;}    
.checkout-total .checkout-control-group-bottom textarea{height: 100px;}
.web_order_typecls li a{  padding: 15px 5px;}    
.promo-earned-info{     -webkit-box-orient: vertical;     -webkit-box-direction: normal;     -ms-flex-flow: column;     flex-flow: column; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;text-align: center}    .promation_btn .button {margin: 5px 5px 0 0;padding: 10px 22px 8px;font-size: 14px;}    
.product-details .row .cart_left{width: 100%; padding: 0}    
.product-details .row .cart_right{width: 100%; padding: 0; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; -webkit-box-align: center; -ms-flex-align: center; align-items: center }    
.product-details .row .cart_right .cart_price{ margin: 0 0 0 10px }    
.function-rooms ul li{ width: 49% }    
.function-rooms ul li:nth-child(3n){ margin-right:2% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }    
a.view-rooms img{ min-height: 190px }    
.birthday-info-top h3 img{ width: 26px}
.birthday-info-top h3{font-size: 24px}    
.checkout-body-section { padding: 10px;}    
.checkout-right-body-section { padding:10px 10px 10px 10px;}    
.top-bar p {text-align: right;}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row {flex-direction: column;justify-content: center;}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row > p {flex: 1 1 auto;}
.prd_chosen_sub_col {flex: 1 1 auto;}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_right {flex: 1 1 auto;}
}

@media (max-width: 580px){ 

/*    .cartaction_bottom {
        padding-bottom: 120px;
        position: relative;
        z-index: 99;
    }*/
    .mobile-choose-option-hover ul li ul {
    padding: 5px 0;
}
.myacc-main-div .myacc_order_details .promo-earned-col{
    width: 100%;
}
.mobile-choose-option-hover ul li ul li a {
    font-size: 15px;
}
    .checkout-main-div .cart_left {
    width: 200px !important;
}
.chekout_cart_bar .cart_left .cart_info{
    width: calc(100% - 0px);
}
.Products .breadcrumb{ padding: 64px 0 0 0;}
.Products .bread_space { margin: 64px 0 0; }
.htico_cart{ padding-right: 3px;}   
.mobile_account_item > a{ font-size: 15px;padding: 14px 40px 10px 40px;}
.mobile_account_item > a > i{ width: 20px; height: 20px; }  
.mobile_mainacc_menutrigger, .mobile_account_menu_click i.arrow_myaccount{ background-size: 16px auto; width: 45px; height: 49px;}
.mobile_account_menu_click{ font-size: 17px; padding: 12px 45px 12px 15px;}
.mobile_account_delivery_item a, .mobile_account_item_login > a, .mobile_mainacc_menulist li a{  font-size: 17px;}
.mobile_account_item a.myacunt_act{font-size: 17px;padding: 12px 45px 12px 15px;}
.loc-cont-part{ display: none;}
.bakery_row_fixed{ top: 150px;}  
.tbars .bakery_row_fixed{ top: 184px;}
.footer-inner [class*='col-']{width: 100%;margin-bottom: 10px;}
.footer-inner h4{margin-bottom: 10px;}
.chk-payment-col-radio li{margin: 0 0 10px 0;width: 100%;max-width: 95%;}
.chk-payment-col-radio li:last-child{margin-bottom: 0;}
.self-back{top: 5px;font-size: 16px;}
.pop-whole-lhs{padding: 40px 15px 20px;}
.pop-whole-lhs h3, .signup-popup .pop-whole-lhs h3{font-size: 30px;}
.pop-whole-lhs p{font-size: 16px;}
.order_delivery_item li{max-width: 100%;     margin: 0 auto;
    padding: 0px 0px 20px 0px;}
.order_delivery_item li:last-child{margin-bottom: 0;}
.order-body{padding: 5px 0;}
.video_popup .order-body{padding: 50px 30px 25px 30px;}
.self_outlet h2{font-size: 21px;}
.self_outlet_inner li a{font-size: 14px;}
.self_pop_item_map{width: 100%;}
.self_pop_item_map img{width: 100%;}
.self_pop_item_loc .loc_btn{height: 52px;font-size: 14px;min-width: 100%;}
.self_pop_item_loc p{font-size: 15px;}
.chosen_adn_left span, .product_chosen_col_right .custom_checkbox span{font-size: 14px;}
.inn_product_hea p{margin-bottom: 10px;font-size: 13px;}
.prd_chosen_row{padding: 20px 10px 15px 10px;}
.prd_chosen_sub_item_left{width: 100%;margin: 5px 0;}
.prd_chosen_sub_item_right{width: 100%;}
.prd_chosen_sub_item_right button{max-width: 100%;}
.footer-inner .news-letter button{padding: 15px 12px;font-size: 14px;}
.footer-inner h4{font-size: 17px;}
.footer-inner ul li a{font-size: 14px;}
.earned_points li h2{font-size: 20px;}
.tag span{font-size: 12px;}
.htmenu_sec > li{padding: 0 15px; }
.megaimg_item{width: 100%;  max-width: 364px;}
.pkselect_tabsec > .nav-tabs>li>a {font-size: 15px; padding: 10px 10px;}
.bts .form-group {width: 100% !important;}
.bts label {margin: 5px 0px 10px; display: block;}
.pkselected_filtersec label { display: block;  width: 100% !important; margin:5px 0px 0px;padding: 0; text-align: center}
.pkselected_package .form-group { width: 100%;} 
.pkselected_filtersec .time-label {display: block;  width: 100% !important;text-align: left; margin:5px 0px 5px;}
.pkselected_break .form-group {width: 100%;} 
.chk-payment-btn .btn {padding: 10px 22px; height: 37px;font-size: 14px;}
.chk-payment-btn .btn_download {font-size: 14px; padding: 10px 20px 10px;height: 35px;} 
.inner_banner {    height: 200px;}
.mbacc_mslide a{ padding: 15px 10px;    font-size: 16px}
.web_order_typecls li a h5 { font-size: 14px;}
.chk_hea > span{display: block; float: none; clear: both; font-size: 11px;padding-right: 12px; margin: -3px 0 0 0}  
.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a{font-size: 17px}
    span.myaccount_separate{height: 14px}
/*21-5-19*/
.reservation-summary ul li span{font-size: 13px;}
.reservation-summary{padding: 15px;}
.reservation-summary-full{background: none; padding-top: 0px; padding-bottom: 0px;} 
.reservation-full{padding: 20px 0px;}   
.reservation-full h2{margin-bottom: 15px; font-size: 30px;}
.reservation-summary-full h3{font-size: 28px; margin-bottom: 15px;}
.go-back a, .continue a{font-size: 17px;}
.reservation-summary-button{max-width: 425px; margin-left: auto; margin-right: auto;}
/*.reservation-counter, .choose-outlet, .choose-datetime-full, .special-instructions{background-color: transparent; padding-top: 0px;}*/
.reservation-counter-full{display: block;}
.reservation-counter-left, .reservation-counter-right{float: none; width: 100%;}
.reservation-counter-single:first-child{margin-top: 30px; margin-bottom: 30px;}
.goto-chkpage-cls a, .viewmenu-btn-row a{ width: 100%; margin: 0}
.goto-chkpage-cls a:first-child, .viewmenu-btn-row a:first-child{ margin-bottom: 5px }    
.popup_ttsec, .news_postal_popup .popup_ttsec{ min-height: inherit}    
.two-button-row{-ms-flex-wrap: wrap;flex-wrap: wrap}    
.delevery_popup .go_second{margin-top: 5px}  
.products_sidebar_inner .no-slide-res{display: none}    
.products_sidebar_inner .slick-slider{ display: block;padding: 0 30px; }
    
.reservation-summary-button .go-back, .reservation-summary-button .continue{width: 100%;} 
.reservation-summary-button .continue{margin-bottom: 10px; margin-top: 5px;}
.reservation-summary-button{display: -webkit-box;display: -ms-flexbox;display: flex; -ms-flex-wrap: wrap-reverse; flex-wrap: wrap-reverse;}
.common-top-div.productpage-main-div {  padding-top: 155px!important;}    
.left-input-div, .right-input-div {max-width: 48.5%;}    
.subtle{display: none}    
.redeem-item-hea .points { font-size: 15px; position: relative; width: 100%; max-width: inherit; top: inherit; margin: 7px 0;}    
.redeem-item-hea-inner { width: 100%;}
.checkout-heading { padding: 12px 10px 12px;font-size: 18px;}
.cart_row.tnkorder-first > div h5 { font-size: 14px;} 
.cart_row.tnkorder-first > div p { font-size: 13px;}    
.congrats-inner { padding: 30px 15px 20px;}    
.mobile-order-nowbtn a { padding: 13px 15px; font-size: 15px}      
.choose-option-hover{display: none}    
.pdr_right.price-fixed { top: 118px; left: 0;}    
.footer-bottom { padding-top: 10px; padding-bottom: 15px;}    
.footer-top a img{ width: 180px } 
.birthday-info-move { padding: 0px 5px 25px;}    
.birthday-info-middle{padding: 0}    
.chk-payment-btn-row{max-width: 95%; width: 100%;margin: 20px auto 0 auto;}  
.chk-payment-btn-row .button{font-size: 18px}    
.four-columns-btn {  padding: 15px 15px;font-size: 15px;}    
.four-columns img { max-width: 70px;}    
    .ft-ct-bottom h4{font-size: 14px}    
    .ft-list li a{font-size: 14px;}
    .ft-list li {   margin: 0 0 1px;}
    .ft-bt-col1 p{font-size: 14px}

.product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn_left {max-width: 60%;}
}

@media (min-width: 320px) and (max-width: 400px){ 
.promo-earned-col-image img{
    max-height: 240px;
    object-fit: contain;
}
}

@media (min-width: 400px) and (max-width: 550px){ 
.promo-earned-col-image img{
    max-height: 270px;
    object-fit: contain;
}
}

@media (max-width: 550px){    

    .myvouchar-main-div .promo-earned-col-image img{
        object-fit: cover;
    }
    .cms-page .container{
        margin-bottom: 10px;
        padding: 30px 30px 27px;
    }    
    .cms-page{
    padding: 20px;
}
.bg_top_slider h3{ font-size: 12px;}
.bg_top_slide p { font-size: 11px; }
.promo-earned-col-center{ display: block; }
.promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.promo-earned-content{ display: block; width: 100%; }
.promo-earned-content p {font-size: 13px; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.redeem_popsec .promo-earned-content{ width: 100%; display: block; }
.redeem_popsec .promo-earned-content h4 {font-size: 20px; }
.redeem_popsec .promo-earned-content h2, .redeem_popsec .promo-earned-content h2 span{font-size: 25px; max-height: inherit; overflow: visible;}
.promo-earned-col-center .innerproduct-item-image {display: block; width: 100%; height: 250px; }
.award_galcol{width: 100%; padding: 0}    
.award_galcol:last-of-type{ margin-bottom: 0 }    
.award_galinfo{margin: 0;    top: 70px;}
.banner-info h2, .featured-products-main h2, .serving-passionately-right h3{font-size: 28px;    line-height: 35px;}
.hmenu_list{ width: 200%; max-width: inherit}
.mypromo-main-div .myacc_order_details .promo-earned-col { width: 100%;  margin: 0 0 15px 0;}    
.filter_tabsec .nav-tabs>li>a:after{max-width: 50%}    
.hook{margin-bottom: 30px} 
.choose-outlet, .special-instructions, .choose-datetime-full{padding-left: 10px; padding-right: 10px;}
.featured-products-main > ul > li{width: 100%; margin: 0 0 20px 0}    
.tnk-chk-order .button { padding: 15px 10px 15px; }     
.productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card{width: 100%; margin: 0 0 20px 0;}    
.tnk-order { padding: 20px 15px;}    
}
@media (max-width: 480px){
    .wrapper {
        padding: 5px 20px 10px 10px;
    }
    .Viewreceipt .cart_left{
        width: calc(100% - 150px);
}
    .takeaway-btn-part {
    max-width: 100%;
    flex-wrap: wrap;
}
.takeaway-btn-part .button:first-child {
    margin-bottom: 5px;
}
.takeaway-btn-part .button {
    width: 100%;
}
.takeaway-btn-part .btn:first-child {
    margin-bottom: 10px;
    margin-top: 10px;
}
.takeaway-btn-part .btn {
    width: 100%;
}
.detail-pg .innerproduct-item {flex-direction: column;padding: 15px ​10px !important;max-width: 320px;margin: 0 auto;}
.detail-pg .detail-pg-box {
    display: flex;
    justify-content: center;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .qty_bx span {width: 35px;height: 41px;}
}
@media (max-width: 480px){ 
.product-details .container-full > div .inn-product-popup {padding: 45px 10px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul li a {font-size: 15px;}
.menu_icon{ width: 30px;}
.innerproduct-row{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center; text-align: center;}
.innerproduct-item-text{margin-bottom: 15px;}
.spilter{text-align: center; margin: 5px auto; vertical-align: middle;}
.two-links a{ margin: 0; vertical-align: middle;}
.popup-body .sign_reg a{white-space: inherit; height: auto; font-size: 14px;}
.news_subscribe p{font-size: 14px;}
.pop_title h2{font-size: 45px;}
.news_postal_popup .pop_title p{font-size: 14px; line-height: normal;}
.title1{font-size: 20px;}
.inn_product_hea_left h3{font-size: 18px;}
.text-box textarea{font-size: 14px;}
.popup_social_icon{width: 100%; padding-left: 0;}
.popup_social_icon li a{padding: 0 8px;}
.popup_social span{color: #fff;}
.order-cat-table h4{font-size: 14px;}
.order-cat-table table td{font-size: 14px;}
.main_tabsec_inner> .nav-tabs>li> a{ font-size: 12px;}
.delivery_time [class*='col-']{width: 100%;}
.chosen_adn_left{width: 280px;}
.delivery_total.delivery_total_number{padding-bottom: 0;}
.redeem_apply .btn{ min-width: auto; padding-left: 10px; padding-right: 10px;}
.redeem_apply label{ font-size: 12px; top: 17px;}
.redeem_apply.focused label{ top: -7px;}
.redeem_popsec .btn{ height: 46px; }  
.order_delivery_item li .header-ordernow-single-img img { height: 75px;}    
.order_delivery_item h3{font-size: 20px}    
.footer-bottom-left{ width: 100%; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center;  margin: 10px 0 10px 0;}    
.footer-bottom-right{width: 100%;    display: -webkit-box;    display: -ms-flexbox;    display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}    
.footer-bottom-right ul{padding: 0}    
.footer-bottom-right h3{margin: 0 5px 0 0}    
.web_order_typecls li a h5 { font-size: 13px;}    
.delivery_total_text::before{display: none}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 14px;}    
.catering_days_ul li dl dt{font-size: 10px}    
.catering_days_ul li dl.cl-next-days span{font-size: 11px}    
.event-type-maindiv.two-button-row a {  font-size: 16px; padding: 15px 10px;}
.ownvenue-inner-div{max-width: inherit; margin: 20px auto 0}   
.order-select-brktime ul .time-cls{-ms-flex-wrap: wrap;flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center}    
.order-select-brktime ul .time-cls label{width: 100%; text-align: center; margin: 0 0 5px 0}    
.more-time-slots{display: -webkit-box;display: -ms-flexbox;display: flex}    
.more-time-slots a{ -webkit-box-flex: 1; -ms-flex: 1; flex: 1; padding: 10px 10px 10px; font-size: 16px}    
.checkout-total .form-group.controls-three > div{ width: 100%; margin: 0 0 10px 0 }    
.checkout-total .form-group.controls-three > div:last-of-type{ margin-bottom: 0 }    
.catering_checout .checkout-total .checkout-control-group-top { padding: 10px 0px 20px 0px;}    
.checkout-total .checkout-control-group-middle .form-group .input_area{ width: 100%;margin-bottom: 10px}    
.chk-payment-btn-row.checkout-continue-cls .row-replace, #PackageEditMdl .chk-payment-btn-row .row-replace{-ms-flex-wrap: wrap;flex-wrap: wrap} 
.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls, #PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls{ width: 100%} 
.chk-payment-btn-row.checkout-continue-cls .row-replace .go_second{ margin-top: 5px }    
.chk-payment-btn-row.checkout-continue-cls .row-replace .button{ font-size: 18px; padding: 14px 10px;}
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls:first-of-type{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2; margin-top: 5px}   
.catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
.phone-show-dskp{ display: none;}
.phone-show-mbl{display: inline-block}    
.locate-lirow .phone-show-mbl a{ color: #000 }    
.locate-lirow.active .phone-show-mbl a{ color: #fff }  
.birthday-info-middle p{ padding-top: 20px;} 
.checkout-billing-address-bottom {
    padding: 10px 0 20px 0;
}
.product-menu-listing { padding: 10px 0 10px 0;}  
.mainacc_toptext p { font-size: 15px;}    
.common-inner-banner span { font-size: 14px; 
    /*line-height: 1.4;    */
    /*margin-top: 95px;*/
    padding: 10px;}    
.package_details_right_inner{flex-wrap: wrap}    
.pdr_left{width: 100%}    
.pdr_right{width: 100%; margin-top: 15px}
.pdr_right h4 { margin-bottom: 10px;font-size: 20px;}    
.mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{font-size: 15px} 
.mobile-login { left: 40px;top: 11px;}    
.hero-top-desc h4 {font-size: 17px;}    
.hero-top-desc p{font-size: 13px}    
.hero-top-desc { padding: 15px 7px 10px;}    
.hero-top-img {height: 30px;}    
.hero-top-img img{width: 60px}    
.wh-lhs-bottom ul li{width: 49%;} 
.hero-top-desc .button { padding: 10px 15px; font-size: 13px;}    
.white-home { padding: 0px 0 0px 0;}    
.wh-lhs-top h2 { font-size: 30px; margin-bottom: 5px;}  
.wh-lhs-bottom ul { padding: 50px 0 0 0;}
.ft-tp-col1, .ft-tp-col2, .ft-tp-col3 { width: 100%; margin: 7px 0;}    
    .ft-tp-col1 b, .ft-tp-col2 b, .ft-tp-col3 b{font-size: 15px}    

.product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn_left {max-width: 50%;}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row .pro_det_item {
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.mfp-wrap {top: 0 !important;}
} 

@media (max-width: 420px){
.pricing-section table tr td span{
    font-size: 14px;
}    
    .pricing-section table tr td{
        font-size: 16px;
    }
    .Viewreceipt .cart_left {
    width: calc(100% - 105px);
}
    .tnk-you .tnk-delivery .order-items-maindiv .cart_right {
        width: 137px!important;
    }
        .activities-popup-head h3 img {
    max-height: 27px;
    margin-right: 10px;
    }
    .activities-popup-head h3 {
    text-align: center;
    font-size: 25px;
}
.hcart_tt{ padding-left: 20px;}
.hcart_tt h3{ font-size: 16px;}
.hclear_cart{ font-size: 12px;}
.location-list ul li {font-size: 12px;}
.cedele-location P {font-size: 12px;}
.catering_checout .checkout-total .cdd-details-rhs { padding: 30px 10px 25px 10px;}
.res-datetime-picker .tag {left: -7px;width: 125%;}
.number-break .back_bx {left: 10px; width: 42px; height: 42px; font-size: 12px; padding: 26px 6px 6px;}
.number-break .back_bx:after {top: 6px;font-size: 16px;}
.number-break .break-time {margin-top: 50px;}
.pkselect_tabsec > .nav-tabs>li>a {font-size: 13px; padding: 7px 7px;}
.title4 {font-size: 14px;}
.pkglist_infott h3 {font-size: 16px;}
.pkglist_infott p{font-size: 13px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 13px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 13px;}
.pkglist_info p{font-size: 13px;}
.web_order_typecls li a h5 { font-size: 9px;}    
.main_tabsec .nav-tabs>li>a { font-size: 12px; padding: 5px 6px;}        
.function-rooms ul li{ width: 100%; margin:0 0 10px 0 }    
.function-rooms ul li:nth-child(3n){ margin-right:0% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }        
.function-rooms ul li:last-child{ margin-bottom: 0 }    
.function-rooms ul li a{height: auto}    
a.view-rooms img{min-height: inherit; max-height: inherit; height: auto}    
.catering_days_ul li dl.cl-next-days span {font-size: 10px;}    
.catering_days_ul li dl dt { font-size: 9px;}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 12px;}    
#PackageEditMdl .pkselected_filtersec .row-replace{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-flow: column;flex-flow: column} .catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
.hcart_round { top: 13px; }
 .product-lhs-inner .products_list { padding: 10px 12px 20px 12px;} 
.featured-products-section .products-list-ulmain li {width: 100%; margin: 0 0 20px 0;}    
.top-bar span {position: relative;width: 100%;display: block;text-align: center;}
.top-bar p {text-align: center;width: 100%;}
.product-details .container-full > div .inn-product-popup {padding: 45px 10px;}
.product-details .container-full > div .inn-product-popup .inn_product_row {padding: 0;}
.product-details .container-full > div .inn-product-popup .prodet_bansec {padding: 0;}
}

@media (max-width: 380px){

    .main_tabsec .nav-tabs>li>a { font-size: 11px;}
.tbars header{ height: 154px;}
.mobile_mainacc_menutrigger{ background-size: 12px auto; width: 32px;}
.mobile_account_item > a{padding: 14px 25px 10px 40px;  font-size: 14px;}
.mobile_account_item > a > i{ left: 5px; width: 22px; height: 22px;}    
.mobile_mainacc_menulist li a{ padding: 12px 5px 12px 35px; font-size: 14px;}
.mobile_mainacc_menulist .setting_menu_list_icon{ left: 7px;}
.mobile_account_item_login > a{ padding: 14px 0 10px 35px;}
.mobile_account_menu_click{     padding: 12px 30px 12px 10px; font-size: 14px}
.mobile_account_delivery_item a{font-size: 14px; padding: 10px 10px;}    
.mobile_account_menu_click i.arrow_myaccount{ background-size: 12px auto; width: 32px;}
.delivery_total_left{/*float: none;*/ margin-bottom: 10px;}
.ttsplitter span{z-index: 1;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 14px;}
.mobile-logo-div{ max-width: 160px }
.addon_poptop{padding: 0}    
.addon_popbot{ margin: 0 }    
.addon_popbot .row-replace{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center }    
.addon_linfo, .addon_lright, .pkselected_package {width: 100%;}    
#CartListMdl .total_amt { padding: 15px 15px;}    
.number-break { padding: 15px 5px 15px 5px;}    
.number-break .making-catering { padding: 60px 0 0;}   
.number-break .event-time{padding: 5px 0}    
.container { padding-left: 10px; padding-right: 10px;}    
a.go-bck-icon{top:-4px}    
h3.choose-eventtype { padding-left: 20px; }  
.hcart_round { top: 13px; }  
.logo { max-width: 110px;}    
}
@media (max-width: 360px){ 
.pricing-section table tr td span{
    font-size: 12px;
}
    .Viewreceipt .cart_left {
    width: calc(100% - 105px);
}
/* .detail-pg .owl-stage-outer .owl-stage .owl-item {
    left: 140px;
} */
    .prd-cat{
            padding: 50px 15px 0px 15px!important;
    }
.testi-author-name h4{
    font-size: 16px;
}
.virtual-card-inner h4{
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 500;
    color: #101010;
    text-align: left;
    margin-bottom:5px;
    margin-left: 0px;
}

.tnk-you .tnk-delivery .order-items-maindiv .cart_right {
    width: 105px!important;
}
.myacc-info-top h3 img{
    margin: 0px 0 0 15px;
    position: relative;
    width: 50px;
    top: -5px;
}
.reservation-summary ul li span{font-size: 11px;}
.reservation-summary{padding: 10px;}
.reservation-full h2{margin-bottom: 10px; font-size: 30px;}
.reservation-summary-full h3{font-size: 26px; margin-bottom: 12px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn_left {max-width: 100%;text-align: center;}
.product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn_right {float: inherit !important;text-align: center;max-width: 100%;}
.product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn {justify-content: center;}
.product-details {padding: 15px 0;}
.cart_left {
    width: 60% !important;
}

.cart_right {
    width: 33%;
}
.qty_bx{
    width:120px
}
.qty_bx input{
    font-size: 18px;
}
.cart_price p {font-size: 17px; margin-right: 6px;}
.qty_bx {height: 30px;}
.qty_bx span {width: 30px;}
/*.social-list li {margin: 10px;}*/
.common-inner-banner {height: auto;}
.redeem_apply, .promo_apply {text-align: center;}
.promo_apply .focus-out, .redeem_apply .focus-out {
    padding-right: 0px !important;
    padding-bottom: 10px;
    width: 100%;
    display: block;
}
.redeem_apply .button, .promo_apply .button {
    position: relative;
}
.chk-payment {padding: 20px 0 0;margin-bottom: 0px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul li a {font-size: 14px}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_right button {margin: 5px 0px;}
}

.close-icon-but {background: #000;}

@media (max-width: 1192px) and (min-width: 1081px){
    .product-info-div .products-ordernow-action .qty_bx {
        float: none;
        margin-bottom: 4px;
    }
}
@media(min-width:360px) and (max-width:410px){
    .delivery_total_left span{
        font-size: 13px;
    }
    .inn_produc_title{
        font-size: 65px;
    }
}
@media (max-width: 895px) and (min-width: 781px){
    .product-info-div .products-ordernow-action .qty_bx {
        float: none;
        margin-bottom: 4px;
    }
}

@media (max-width: 595px) and (min-width: 421px){
    .product-info-div .products-ordernow-action .qty_bx {
        float: none;
        margin-bottom: 4px;
    }
}

@media (max-width: 758px){
    .modal .modal-dialog.modal-lg{
        width: 540px;
    }
}

@media (max-width: 560px){
    .modal .modal-dialog.modal-lg{
        width: 440px;
        margin: 1.75rem auto;
    }
}

@media (max-width: 440px){
    .modal .modal-dialog.modal-lg{
        width: 311px;
        margin: 1.75rem auto;
    }
    .redeem_apply .button,
    .promo_apply .button {min-width: 150px;}
    .promo_apply .focus-out,
    .redeem_apply .focus-out {padding-right: 130px;}
    .chk-payment-col-radio li {
        max-width: 100%;
        justify-content: center;
        flex-direction: column;
    }
    .chk-payment .chk-payment-col-radio li label {
        position: relative;
        margin: 0 0 25px;
    }
    .custom_center_alertcls.alert-danger {margin: 0 10px !important;}
    .birthday-inpt-act {flex-direction: column;}
    .left-input-div, .right-input-div {max-width: 100%;}
}

@media (max-width: 768px){
    .scan-tbl p{
    font-size: 16px;
}
        .prd-cat{
            padding: 150px 15px 0px 15px!important;
    }
    .ourrest_info h4 {
        font-size: 19px;
    }
    .outletul>li:last-child{
        margin-bottom: 0;
    }
    .outletul>li{
        width: 100%;
    margin: 0 0 30px;
    }
    .ourrest_row{
        display: block;
    }
    .ourrest_info{
        padding: 20px;
    }
    .hcart_dropdown{
        width: 100%;
/*        height: 100vh;
        top: 0;
        overflow-y: scroll;*/
    }
    body.cart-items-open {overflow: hidden !important;}
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .top-bar p{
        margin: 0;
        color: #282828;
    background: #f4f4f4;
    font-size: 11px;
    /*text-align: center;*/
    float: right;
    padding: 10px;
    /* border-left: 10px solid #e2e2e2; */
    }
    /*.top-bar span{
        display: none;
    }*/
    .top-bar span{
        font-size: 11px;
    padding: 13px 10px;
    }
    .top-bar span:after{
        content: '';
    width: 0;
    height: 0;
    border-top: 0px solid #e2e2e2;
    border-right: 0px solid transparent;
    position: absolute;
    left: 0px;
    top: 0;
    }
}

@media (min-width: 300px) and (max-width: 400px){
    .head_right::before{
        width: 37%;
    }
    .myacc-info-top h3{
        line-height: 55px;
        font-size:30px;
    }
/*    .common-inner-banner span{
        margin-bottom: 0px;
        margin-top: 75px;
    }*/
    .four-columns{
        width: 65%;
    }
    .four-columns h3 {
        font-size: 17px;
        line-height: 30px;
    }
    .four-columns h3{
        margin: 0 0 0px;
    }
    .four-columns img{
        margin: 0 auto 0px;
    }
    .four-columns-btn {
        padding: 10px 10px;
        font-size: 12px;
    }
    .head_right::before{
        right: -17px;
    }
    .head_right h4{
        font-size: 12px;
    margin-right: -12px;
    }
    .head_left h4 {
        font-size: 13px;
    }
    .order_btns .button{
        padding: 10px 6px 10px;
        font-size: 14px;
    }
    .two-links a{
        font-size: 12px;
    }
}
@media (min-width:480px){
    @supports (-webkit-overflow-scrolling: touch) {
        .hcart_scrollarea { max-height: 100%; padding-bottom: 220px;}
        header .cartaction_bottom { padding: 10px 0px 120px 0px; position: fixed; bottom: 0px; left: 0; right: 0; background: #FFF;}
        }
}

@media (min-width: 361px) and (max-width: 409px) { 
    .detail-pg .owl-stage-outer .owl-stage .owl-item {
        left: 119px;
    }
    .tnk-you .tnk-delivery .order-items-maindiv .cart_right {
        width: 105px!important;
    }
}
@media (max-width: 480px){
        .prd-cat{
            padding: 75px 15px 0px 15px!important;
    }
    .four-columns{
            width: 65%;
    }
.alert_fixed p {
font-size: 15px;
}}
@media (max-width: 360px){
.alert_fixed p {
font-size: 14px;
text-align: center;
}}
@media (min-width: 310px) and (max-width: 360px){
    .product-details .container-full > div .inn-product-popup .inn_product_row .qty_bx span{
        width: 35px;
    height: 30px;
    }}
@media (min-width: 361px) and (max-width: 475px){
.product-details .container-full > div .inn-product-popup .inn_product_row .qty_bx span{
        width: 35px;
        height: 40px;
}
}
/****************** 10-09-21 ****************************/

@media (max-width: 500px){
    /*.hcart_scrollarea .cart_row.product-details .cart_left {width: 100%!important;}*/

    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .cart_left .cart_info h4{line-height: 1;}
    .checkout-total .cdd-details-rhs .cart_price { margin: 0 0 8px 0;}

    .checkout-total .cdd-details-rhs .booking_cart_price{
        margin-top: 10px !important;
    }
}

@media (max-width: 480px){

    .hcart_dropdown .cart_img {width: 50px; max-width: 50px; margin: 0 5px 5px 0;}
    .hcart_scrollarea .cart_row.product-details .cart_left .cart_info { max-width: calc(100% - 70px); }
    .hcart_scrollarea .cart_row.product-details .cart_left{ flex-wrap: wrap; justify-content: flex-start;}

    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .cart_left .cart_img { width: 50px; margin: 0 5px 5px 0;}
    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .cart_left .cart_info { width: calc(100% - 0px);}
    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .cart_left {
        flex-wrap: wrap; justify-content: flex-start; width: calc(100% - 115px);
    }

    .booking .cart_info h4{
        letter-spacing: normal;
        font-size: 16px;
    }

    .booking-cart-category-txt span{
        font-size: 14px;
    }

    .booking .cart_price p{
        margin-right: 0;
    }

    .htico_search_mbl  {
        display: block;
    }
    .htico_search {
        display: none;
    }

    .hsearch_sec_mbl{
        top: 80px;
        width: auto;
    }

    .hsearch_sec.open .input-sec input, .hsearch_sec_mbl.open .input-sec input{
        padding-right: 25px;
    }
}

@media (max-width: 411px){
    .text-center.checkout-heading.checkout-detail-detail .tab-menu-container ul li a{
        font-size: 14px;
    }
}

@media (max-width: 400px){
    .checkout-total .chekout_cart_bar .checkout-right-body-section .product-details .cart_right .cart_price { margin: 5px 0 7px 0; }
}

@media (max-width: 390px){
    .text-center.checkout-heading.checkout-detail-detail .tab-menu-container ul li a{
        font-size: 12px;
    }
}

@media (max-width: 385px){
    .hcart_scrollarea .qty_bx { width: 100px; height: 35px; }
    .hcart_scrollarea .qty_bx input { font-size: 14px; }
    .hcart_scrollarea .qty_bx span{ width: 35px; }

    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .qty_bx { width: 100px; height: 35px; }
    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .qty_bx input { font-size: 14px;}
    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .qty_bx span{ width: 35px; }
    .checkout-total .chekout_cart_bar .checkout-right-body-section .product-details .cart_remove { right: 12px;}

    .checkout-total .cdd-details-rhs .booking .product-details .cart_remove{ right: 0 !important; top: 0; }
}

@media (max-width: 360px){
    .hcart_scrollarea .cart_row.product-details .cart_right .cart_price { margin: 7px 0 7px 0;}
    .checkout-total .chekout_cart_bar .checkout-right-body-section .product-details .cart_remove { right: 2px; }

    .text-center.checkout-heading.checkout-detail-detail .tab-menu-container ul li a{
        font-size: 11px;
    }
    .checkout-main-div .cart_left{
        width: 200px !important;
    }
    .col-sm-cls.cart_right.text-right {
    width: 80%;
}
}



@media (max-width: 780px){
.common-inner-banner .dbest-myacc, .common-inner-banner img {
    top: 0;
}
.common-inner-banner p {
    margin-top: 15px;
    top: 8px;
}
.ourrest_info {
    padding: 40px 30px 70px;
    width: 100%;
}
.common-inner-banner {
    background-size: cover;
    padding: 70px 0 20px;
}
.common-inner-banner .dbest-myacc-span {
    top: 0;
}
}

@media (max-width: 580px){
.common-inner-banner p {
    font-size: 36px;
    top: 10%;
}
.common-inner-banner .dbest-myacc {
    top: 10%;
}
}


@media (max-width: 480px){
.common-inner-banner {
    padding: 80px 0 10px;
}
.common-inner-banner .dbest-myacc-span {
    top: -10px;
}
}

@media (max-width: 480px){
@supports (-webkit-overflow-scrolling: touch) {
    .hcart_scrollarea { max-height: 100%; padding-bottom: 200px;}
    .hcart_dropdown .cartaction_bottom{   padding: 10px 0px 120px 0px;   background: #fff;}
}    
}

@media (max-width: 580px){
    .mobile-choose-option-hover {
        display: block;
        margin-top: 10px;
    }

    .choose-option-hoverr {
        display: none;
    }
}

@media (max-width: 1199px){
    .hcategory_sec {
        background: #ebebeb;
        position: relative;
        /*border-bottom: 2px solid #181818;*/
    }
    .hcategory_sec.header {
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .hcategory_selected_text {
        padding: 12px 180px 12px 15px;
        color: #000;
        font-size: 18px;
        text-transform: uppercase;
        display: block;
        position: relative;
        font-family: "poppinsregular"!important; 
        font-weight: 700;
    }
    .hcategory_trigger, .hcategory_trigger_cat {
        /*position: relative;*/
        /*right: -10px;*/
        top: 0px;
        -webkit-transition: all .4s;
        transition: all .4s;
        line-height: 0;
        font-size: 18px;
        /*padding-right: 0px;*/
        z-index: 11;
        position: absolute;
    }
    .hcategory_trigger, .hcategory_trigger_cat:after {
        position: absolute;
        background: url(../images/back-black.png) no-repeat;
        background-size: contain;
        width: 19px;
        height: 31px;
        transform: rotate(-90deg);
        content: "";
        right: -10;
        top: 0px;
    }
    .hcategory_menu {
        background: #fff;
        border: 3px solid #0271e5;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        max-height: 400px;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .4s;
        transition: all .4s;
        overflow: auto;
    }

.hcategory_menu_cat {
    background: #fff;
    position: absolute;
    border: 3px solid #0271e5;
    left: 0;
    top: 20px;
    right: 0;
    max-height: 400px;
    opacity: 0;
    visibility: hidden;
    transition: all 400ms;
    overflow: auto;
}

.hcategory_menu_cat.open {
    opacity: 1;
    visibility: visible;
    z-index: 9;
}

    .hcategory_menulist {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .hcategory_menulist>li>a {
        background: #fff;
        color: #000;
        font-size: 18px;
        padding: 12px 45px 12px 15px;
        display: block;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    .hcategory_submenu {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .hcategory_submenu>li>a {
        color: #000;
        font-size: 16px;
        /*padding: 12px 15px 12px 35px;*/
        display: block;
        font-family: 'Poppins';
        /*font-weight: 700;*/
    }

    .product-menu-full {
        /*top: 223px;*/
        top: 62px;
    }

    .hcategory_sec.header {
        margin-top: 10px;
        margin-bottom: 25px;
    }

    .cdd-details-lhs.fl .checkout-control-group-top{
        padding-top: 10px;
    }  
    .hcategory_trigger, .hcategory_trigger_cat:after{ 
        position: absolute; background: url(../images/back-black.png) no-repeat; background-size: contain; width: 22px;
        height: 20px; content: "";  
        right: -10px;
        top: -3px;
    } 
    .hcategory_trigger, .hcategory_trigger_cat.active:after{ 
        background: url(../images/close-block.png) no-repeat; background-size: contain; width: 13px;
        height: 13px; top:3px; 
        /*right:5px;*/
     }
     .hcategory_menu{background: #fff; position: absolute; left: 0; top: 20px; right: 0; max-height: 400px; opacity: 0;
    visibility: hidden; -webkit-transition: all 400ms; transition: all 400ms; overflow: auto;}
    .hcategory_menu .mCSB_scrollTools{ opacity: 1;}
    .hcategory_menu .mCSB_scrollTools .mCSB_draggerRail{ background-color: #6e5546;}
    .hcategory_menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #191919;}
    .hcategory_menu.open{ opacity: 1; visibility: visible;z-index: 9;}
    .hcategory_menulist{ list-style: none; padding: 0; margin: 0;}
    .hcategory_menulist > li > a{background: #f8f8f8; color: #000; font-size: 16px; padding: 12px 45px 12px 15px; display: block; text-transform: uppercase; font-family: 'poppins'; 
        /*font-weight: 700;*/
    }
    .hcategory_menulist > li > a:hover{ color: #49190d;}
    .hcategory_submenu{ list-style: none; padding: 0; margin: 0; }
    .hcategory_submenu >li >a{ color: #000; font-size: 14px; padding: 7px 7px 7px 7px; display: block; font-family: 'Poppins';     border-bottom: 0.1px solid #e2e2e2;
    /*font-weight: 600;*/
}
    .hcategory_submenu >li >a:hover{ color: #0271e5;}
    .hsearch_trigger:before{ top: 36px; right: 12px;}
    .hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
    .hsearch_bx .re_select .Select-input{ height: 49px;}
/* Sticky Header */
}  
@media (min-width: 1081px){
    .hcategory_sec {
        display: none;
    }
}
@media (max-width: 1280px){
.choose-option-hover{
    width: 185px;
}
}
@media (max-width: 991px){   
.signup-popup-main .pop-whole-lhs-inner h3{
    margin-bottom: 0px;
    line-height: 2.1
}
.promo-package-main-div{
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.promo-package-main-div .col-lg-8{
    /*width: 70%*/
}
.promo-package-main-div .col-lg-4{
    /*width: 30%*/
}
.promo-package-main-div .packages-promos-body{
        padding: 5px 0 5px;
        display: block;
}
a.four-columns-btn.four-columns-btns.f-left.issue_vc1 {
    margin-bottom: 10px;
}
.packages-promos-body a {
    margin-bottom: 10px;
}
    .social-list{
        justify-content: flex-start;
    }
.booking-tbl-input input{
    width: 95%;
    font-size: 14px;
    font-weight: 600;
}
.myacc_order_details .booking-head{
    padding: 0 0 70px 0;
}
.pricing-section .row{
    margin-bottom: 0px;
}
.pricing-section h2{
    margin-bottom: 10px;
    margin-top: 20px;
}
}
@media (min-width: 750px) and (max-width: 1200px){
.foot-left{
    margin-top: 40px
}
}

@media (min-width: 821px) and (max-width: 991px){
.ft-bt-col2,.ft-bt-col3, .ft-bt-col4, .ft-bt-col5, .ft-bt-col6 {
    width: 20%;
    order: 2;
}
.social-list{
    justify-content: flex-start;
}
}


@media (min-width: 1201px) and (max-width: 1280px){
.about-inner{
    padding-bottom: 160px;
    padding-top: 90px;
}
.testimonial-section h2 {
    line-height: 0;
    margin-bottom: 60px;
}
.testimonial-section > div {
    padding: 0;
}
}


@media (min-width: 1081px) and (max-width: 1200px){
.about-inner{
    padding-bottom: 210px;
    padding-top: 90px;
}
.testimonial-section h2 {
    line-height: 0;
    margin-bottom: 60px;
}
.testimonial-section > div {
    padding: 0;
}
}


@media (min-width: 991px) and (max-width: 1080px){
.about-inner{
    padding-bottom: 240px;
    padding-top: 90px;
}
.testimonial-section h2{
    line-height: 0;
    margin-bottom: 60px;
}
}

@media (max-width: 991px){
.home-top-four-section{
    justify-content: center;
}
.four-columns {
    width: 27%;
}
.controls.single-link {
    padding-bottom: 20px;
}
}

@media (max-width: 820px){
.ft-bt-col2 {
    order: 1;
    width: 18%;
}
.ft-bt-col3 {
    order: 2;
    width: 33%;
}
.ft-bt-col4 {
    order: 3;
    width: 33%;
    padding-right: 0;
}
.ft-bt-col5 {
    order: 4;
    width: 50%;
    padding-bottom: 20px;
    padding:0px 0px 0%;
}

.ft-bt-col6 {
    order: 5;
    width: 33%;
    padding-bottom: 0px;
}
}
@media (max-width: 850px){
.home-top-four-section{
    justify-content: center;
}
.four-columns {
    width: 28%;
}
}

@media (max-width: 780px){
.home-top-four-section{
    justify-content: center;
}
.four-columns {
    width: 40%;
}
.signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs, .signup-corparatetwo-popup .inside-popup-rhs, .forgot-password-popup .inside-popup-rhs{
            padding: 15px 15px 0;
    }
}

@media (max-width: 580px){
.home-top-four-section{
    justify-content: center;
}
.four-columns {
    width: 45%;
}
.promo-package-main-div{
    width: 100%;
    margin-bottom: 10px;
    display: block;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #ccc;
}
.promo-package-main-div .col-lg-8{
    width: 100%
}
.promo-package-main-div .col-lg-4{
    width: 100%
}
.promo-package-main-head .package-promo-para strong{
    margin-top: 30px;
    text-align: center;
    font-size: 19px;
}
.promo-package-main-head .package-promo-para p{
    /*text-align: center;*/
}
}

@media (max-width: 550px){

    .ft-ct-bottom{
        padding: 30px 0 10px;
    }
    .ft-tp-col1, .ft-tp-col2, .ft-tp-col3{
        margin-top: 15px!important
    }
.footer-right img {
    text-align: center;
    margin-top: 20px;
    padding: 0px;
}
.footer-right{
    text-align: center;
}
.ft-bt-col2 {
    order: 1;
    width: 50%;
}
.ft-bt-col3 {
    order: 2;
    width: 50%;
    padding: 0px;
}
.ft-bt-col4 {
    order: 3;
    width: 50%;
    padding: 0;
}
.ft-bt-col5 {
    order: 4;
    width: 50%;
    padding:0px;
}

.ft-bt-col6 {
    order: 5;
    width: 50%;
}
.promo-package-main-div .packages-promos-body{
    display: block;
}
.packages-promos-body a{
    margin-bottom: 10px
}
a.four-columns-btn.four-columns-btns.f-left.issue_vc1{
    margin-bottom: 10px
}
}

@media (max-width: 520px){
.home-top-four-section{
    justify-content: center;
}
.four-columns {
    width: 50%;
}
.productpage-main-div .menu-nav-section h3{
    font-size: 34px
}

.thankyou-main-div .cart_img{
    margin: 0
}
.thankyou-main-div .cart_img img{
    min-width: 90px;
    max-width: 120px;
    margin: 0
}
.thankyou-main-div .cart_left{
    display: block;
    width: 100%
}
.thankyou-main-div .cart_right.text-right{
    width: 65%
}
}

@media (max-width: 480px){
.home-top-four-section{
    justify-content: center;
}
.four-columns {
    width: 70%;
}
.thankyou-main-div .cart_right.text-right{
    width: 35%
}
}

@media (max-width: 420px){
.home-top-four-section{
    justify-content: center;
}
.four-columns {
    width: 80%;

}
.four-columns h3{
    margin: 10px;
    line-height: 40px
}
.four-columns-btn{
    padding: 15px 15px;
}

.booking .hclear_cart{
    font-size: 15px;
}

}

@media (max-width: 360px){
.home-top-four-section{
    justify-content: center;
}
.four-columns {
    width: 90%;
}
}


/*side cart items*/

@media (max-width: 680px){
.header-top-cls .hcart_dropdown .cart_left, .chekout_cart_bar .cart_left .thankyou-main-div .cart_left{
        width: 70%!important;
            flex-flow: column;
        }
        .hcart_scrollarea .cart_right, .checkout-body .cdd-details-rhs .cart_right .thankyou-main-div .cart_right{
    width: 30%!important;
    text-align: right;
}
.hcart_dropdown .pickup-cart-div .cart-header-first .cart_left{
    width: 100%!important;
    text-align: center;
}
.hcart_dropdown .cart-header-first .cart_left h4{
    font-size: 32px
}
    }
@media (max-width: 480px){
.header-top-cls .hcart_dropdown .cart_left, .chekout_cart_bar .cart_left, .thankyou-main-div .cart_left{
    display: flex;
    flex-flow: column;
    width: 60%!important;
}

.checkout-total .booking .cart_right.text-right{
    width: 150px !important;
}

.checkout-total .booking .cart_left{
    width: calc(100% - 150px) !important;
}

.product-details .row-replace .cart_info, .chekout_cart_bar .cart_info .thankyou-main-div .cart_info{
        width: 100%;
}
.hcart_dropdown .cart_img, .check_out_fourcommon .checkout-right-body-section .cart_body .product-details-parent .cart_row .cart_img .thankyou-main-div .cart_img{
        width: 85px;
        max-width: 85px;
}
.hcart_scrollarea .cart_right, .checkout-body .cdd-details-rhs .cart_right .thankyou-main-div .cart_right{
    width: 40%!important;
    text-align: right;
}
#cart-close-span{
    right: 15px;
    top: 11px;
}
.pickup-thankfull h4{
    font-size: 30px;
    margin-bottom: 10px;
}
.hcart_dropdown .pickup-cart-div .cart-header-first .cart_left{
    width: 100%!important;
    text-align: center;
}
.hcart_dropdown .cart-header-first .cart_left h4{
    font-size: 32px
}
}
@media (max-width: 580px){

.pop-whole-lhs-inner p, .popup-header p {
    font-size: 15px;
}
.signup-popup .pop-whole-lhs-inner h3, .guest-popup .pop-whole-lhs-inner h3, .frgt-head h3, .signup-popup .frgt-head h3 {
    font-size: 30px;    
    font-weight: bold;
}
.login-popup .two-links, .guest-popup .two-links, .signup-popup .single-link, .forgot-password-popup .single-link {
    padding: 10px 0 15px;
}
.login-popup .two-links .spilter, .guest-popup .two-links .spilter {
    margin: 0 4px 0;
}
}
@media (max-width: 480px){
.two-links a{
    font-size: 13px
}
}

@media (max-width: 430px){
.two-links a{
    font-size: 12px
}
}

@media (max-width: 420px){
    .login-popup .two-links{
        padding: 10px 0 10px;
    }

    .guest-popup .two-links{
        padding: 0 0 10px;
    }
.signup-popup .pop-whole-lhs-inner h3, .guest-popup .pop-whole-lhs-inner h3, .frgt-head h3, .signup-popup .frgt-head h3 {
    font-size: 24px;    
}
.pop-whole-lhs-inner p, .popup-header p {
    font-size: 13px;
}
.login-popup .two-links a, .guest-popup .two-links a {
    padding: 0;
}
}

@media (max-width: 380px){
.login-popup .two-links a {
    font-size: 11px;
}
.inside-popup-rhs, .signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs{
    padding: 15px 10px;
}
}

@media (max-width: 340px){
.login-popup .two-links a {
    font-size: 10px;
    text-align: center;
}
}

@media (max-width: 320px){
.signup-popup .pop-whole-lhs-inner h3, .guest-popup .pop-whole-lhs-inner h3, .frgt-head h3, .signup-popup .frgt-head h3 {
    font-size: 22px;    
}
.pop-whole-lhs-inner p, .popup-header p {
    font-size: 12px;
}
}

@media (min-width: 820px) and (max-width: 1280px){
@supports (-webkit-overflow-scrolling: touch) {
    .hcart_scrollarea { max-height: 100%; padding-bottom: 200px;}
    .hcart_dropdown .cartaction_bottom{   padding: 10px 0px 120px 0px;   background: #fff;}
}
.hcart_dropdown .hcart_scrollarea{
     max-height: 100%; padding-bottom: 250px;height: 100%;
}
.hcart_dropdown .cartaction_bottom{   padding: 10px 0px 100px 0px;   background: #fff;}
}

@media (max-width: 991px){
@supports (-webkit-overflow-scrolling: touch) {
    .hcart_scrollarea { max-height: 100%; padding-bottom: 200px;}
    .hcart_dropdown .cartaction_bottom{   padding: 10px 0px 180px 0px;   background: #fff;}
}    
}

@media (max-width: 480px){
/* CSS specific to iOS devices only */ 
    @supports (-webkit-overflow-scrolling: touch) {
        .hcart_scrollarea { max-height: 100%; padding-bottom: 200px;}
        .hcart_dropdown .cartaction_bottom{   padding: 10px 0px 120px 0px;   background: #fff;}
    }  
}


