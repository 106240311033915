@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'CenturyGothic-Bold';
    src: url('../fonts/CenturyGothic-Bold.woff2') format('woff2'),
        url('../fonts/CenturyGothic-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: 'CenturyGothic';
    src: url('../fonts/CenturyGothic.woff2') format('woff2'),
        url('../fonts/CenturyGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Budhayanti Script';
    src: url('../fonts/Budhayanti Script.woff2') format('woff2'),
        url('../fonts/Budhayanti Script.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'BrandonGrotesque';
    src: url('../fonts/BrandonGrotesque.eot');
    src: url('../fonts/BrandonGrotesque-Bold.eot');
    src: url('../fonts/BrandonGrotesque.woff') format('woff');
    src: url('../fonts/BrandonGrotesque-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-Medium';
    src: url('../fonts/BrandonGrotesque-Medium.eot');
    src: url('../fonts/BrandonGrotesque-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BrandonGrotesque-Regular';
    src: url('../fonts/BrandonGrotesque-Regular.eot');
    src: url('../fonts/BrandonGrotesque-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {border: 0;font-family: inherit;font-size: 100%;font-style: inherit;font-weight: inherit;margin: 0;outline: 0;padding: 0;vertical-align: baseline;}

html {
    -webkit-text-size-adjust: none;
}

:focus {
    outline: 0;
}

ol,
ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

body {
    color: #000;
    word-wrap: break-word;
    font-size: 15px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-family: 'poppins';
    background: #fff;
    font-weight: 400;
}

/*body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    background: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
    background-color: #98684a;
    outline: 0px solid slategrey;
}
*/
cite,
em,
i {
    font-style: italic;
}

input,
textarea {
    -webkit-border-radius: 0px;
}
textarea{
    font-family: 'Open Sans';
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
    padding: 12px 10px;
    border: 1px solid #e4e4e4;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius:5px;
    height: 48px;
    font-size: 15px;
    color: #555555;
    font-family: 'poppins';
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #0271e5;
    display: inline-block;
    border-radius: 8px;
    margin: 10px 0px 0px 10px;
    padding: 14px 24px 14px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    /*background: linear-gradient(135deg,  #f36b31 0%,#f2672e 9%,#f26730 14%,#f0642f 20%,#ed612c 22%,#ed5f2d 28%,#ed5f31 29%,#ea5c2e 31%,#e8532b 46%,#e44e29 48%,#e44e29 51%,#e64d2d 52%,#e64d2d 52%,#e44b2b 53%,#e44b2b 57%,#e2482c 57%,#e0462a 59%,#e0442b 66%,#dd4128 68%,#de422c 69%,#dc402a 71%,#de3f2c 75%,#d83926 85%,#da3728 86%,#d63226 100%);*/
    border: 2px solid #0271e5;
    background: #fff;
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;

    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    font-weight: 500;
   font-family: 'poppins';
    -webkit-box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
    box-shadow: 0px 4px 9px 0px rgba(2, 2, 2, 0.08);
}

input[type="reset"]:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
.con_first .delivery_submit_cls button:hover,
.btn_sec .delivery_submit_cls .delivery_submit_div button:hover, 
.button:hover,
.btn:hover {
    color: #fff;
    background: #0271e5;
}

input[type="button"]:hover  {
    color: #fff;
    background: #0271e5;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
    color: #0271e5;
    text-decoration: none;
    cursor: pointer;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #000
}

figure {
    margin: 0;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    font-weight: normal;
    line-height: 1.1;
    color: #1a1a1a;
    font-family: 'BrandonGrotesque-Regular';
}

h1 {
    font-size: 105px;
}

h2 {
    font-size: 100px;
}

h3 {
    font-size: 70px;
}

h4 {
    font-size: 65px;
}

h5 {
    font-size: 40px;
}

h6 {
    font-size: 16px;
}

.show {
    display: block;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.fl {
    float: left
}

.fr {
    float: right
}

.rel {
    position: relative
}

.textcenter {
    text-align: center
}

.textleft {
    text-align: left
}

.textright {
    text-align: right
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.clear,
.clearfix {
    clear: both;
}

.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-family: 'poppins-Bold';
}

.required_star {
    color: #f00;
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}

.slick-slider,
.slick-slider * {
    outline: none;
}

p {
    margin: 0 0 15px;
}

.title_sec {
    margin: 0 0 45px;
    color: #040507;
}

.title_sec h2 {
    font-size: 44px;
    line-height: 1;
    margin: 0 0 5px;
}

.title_sec small {
    font-size: 16px;
    display: block;
    font-weight: normal;
    margin: 0 0 13px;
}

.title1 {
    font-size: 32px;
    color: #040507;
    line-height: 1;
    margin: 0 0 24px;
}

.title3 {
    font-size: 20px;
    color: #040507;
    line-height: 1;
}

.title4 {
    font-size: 18px;
    color: #010101;
}

.icon-cap {
    background: url(../images/icon-cap.png) no-repeat;
    width: 34px;
    height: 31px;
    display: block;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}

.dropdown-menu {}

.dropdown-menu>li>a {
    padding: 7px 20px;
    border-top: 1px solid #e9ebf5;
}

.dropdown-menu>li:first-child>a {
    border-top: 0;
}

.form_grouptt {
    font-size: 18px;
    margin: 0 0 20px 2px;
    color: #000;
    font-weight:500;
    font-family: 'poppins';
    text-transform: capitalize;
}

.form-group {
    margin: 0 0 8px;
}

label {
    font-size: 14px;
    color: #959595;
}

label span {
    color: #f00;
    margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
    content: '*';
    color: red;
}

.form-control {
    height: 48px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    -webkit-box-shadow: none;
    -webkit-appearance: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 0;
    color: #615f5f;
    width: 100%;
    display: block
}

textarea.form-control {
    height: 160px;
}

textarea.form-control.sm {
    height: 90px;
}

.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #686a78;
}

.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #0271e5;
}

.focus-out {
    position: relative;
}

.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #595656;
}


.focused label {
    padding: 0 6px;
    font-size: 13px;
    background: #fff;
    top: -7px;
    height: auto;
    font-family: 'BrandonGrotesque-Regular';
    z-index: 1;
}

.clock_icon,
.ileft_ico {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
}

.clock_icon img,
.ileft_ico img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
}

.profile-info-div {
    margin: 20px 0;
}

.css-1hwfws3{
    font-size: 14px;
    color: #555555;
}

.other-add {
    margin: 30px 0 0 0
}

.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after {
    display: table;
    clear: both;
    content: ""
}

.acc-inform .form-group .row .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
}

.acc-inform .form-group .row .col-md-6:last-of-type {
    margin-right: 0;
}

.re_select input[type="text"] {
    margin: 0;
    height: 34px;
}

.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0;
    z-index:0;
    height: 48px;
}

.re_select .Select-control {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    height: 47px;
    outline: 0;
}

.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
    padding: 0 22px;
    line-height: 45px;
    text-align: left;
    color: #72624f;
}

.re_select .Select-input {
    padding: 0 20px;
    height: 45px;
}

.re_select .Select-input > input {
    line-height: 27px;
    padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
    border-color: #d5d7e0;
}

.re_select .Select-arrow {
    background: url(../images/arrow-down.png) no-repeat center;
    border: 0;
    width: 18px;
    height: 10px;
}

.re_select .is-open .Select-arrow {
    background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
    z-index: 4;
    text-align: left;
}

.re_select .Select-menu-outer {
    background: #ecdfcc;
}

.re_select .Select-arrow-zone {
    padding: 0;
    width: 42px;
}

.re_select .Select--multi .Select-value {
    background-color: #ecdfcc;
    border-color: #dcd8d8;
    color: #181818;
    position: relative;
    padding-right: 19px;
    border-radius: 10px;
    overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
    border-color: #dcd8d8;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.re_select .Select--multi .Select-value-label {
    padding: 2px 5px 2px 9px;
}

.custom_select {
    position: relative;
}

.custom_select:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 46px
}

.react-datepicker {
    font-family: 'Calibri' !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    font-weight: bold
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #769e90 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #769e90 !important;
}

.react-datepicker__header {
    background-color: #f1f1f1 !important;
}

.react-datepicker__navigation {
    margin: 0;
    box-shadow: none;
    border: .8rem solid transparent!important;
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}



.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{ border-radius: 50% !important;     background-color: #769e90 !important}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #769e90 !important;
}

.custom_checkbox {
    position: relative;
    display: inline-block;
    line-height: 25px;
}

.custom_checkbox input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 25%;
    position: absolute;
    cursor: pointer;
}

.custom_checkbox input[type=checkbox]:checked + span:before {
    content: "";
    text-rendering: auto;
    text-align: center;
    border-color: transparent;
    background: url(../images/blue-tick.png) no-repeat;
    border: 0;
}

.custom_checkbox .disableactive + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: #ff8400;
    text-align: center;
    text-align: center;
    border-color: #ff8400;
}

.custom_checkbox span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 1px;
    pointer-events: none;
    line-height: 20px;
    border-radius: 50%;
        border: 1px solid #c3c3c3;
}

.custom_checkbox span {
    position: relative;
    padding: 0 0 0 25px;
    pointer-events: none;
    color: #3d3d3d;
    display: block;
}

.custom_radio {
    position: relative;
    display: inline-block;
}

.custom_radio input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.custom_radio input[type=radio]:checked + span:before {
    background: url(../images/blue-tick.png) no-repeat;
    z-index: 1;
    border: 0px;
}

.custom_radio span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #d0cfcb;
    left: 0;
    top: 1px;
    pointer-events: none;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.custom_radio span {
    position: relative;
    padding: 0 0 0 30px;
    pointer-events: none;
    color: #353535;
    display: block;
}

.ckbox_custmze {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ckbox_custmze input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid #ecdfcc;
}

.ckbox_custmze:hover input ~ .checkmark {
    background-color: #fff;
}

.ckbox_custmze input:checked ~ .checkmark {
    background-color: #72624f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.ckbox_custmze input:checked ~ .checkmark:after {
    display: block;
}

.ckbox_custmze .checkmark:after {
    left: 10px;
    top: 5px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.error {
    color: #f00;
    font-weight: normal;
}

span.error {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    line-height: 1.1;
}

span.error:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.alert_success {
    /* margin-top: 15px!important; */
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0;
}

.alert_fixed a {
    font-size: 24px;
    right: 20px;
    top: 5px;
    position: absolute
}

.display_inblock {
    display: inline-block;
}

.display_block {
    display: block;
}

.relative {
    position: relative;
}

.vtop {
    vertical-align: top;
}

.vmiddle {
    vertical-align: middle;
}

.vbottom {
    vertical-align: bottom;
}

.vmid_sec:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.vmid_in {
    display: inline-block;
    vertical-align: middle;
    width: 98%;
}

.modal-dialog {
    margin: .5rem;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}

.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
    border:1px solid #999;
}

.poup_wrap {
    width: 660px;
}

.popup_in {
    padding: 28px 60px 54px;
}

.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted {
    background: #ecdfcc !important;
}

button.mfp-close:hover,
button.mfp-arrow:hover {
    background: none;
}

.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
    background: none;
}

.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 45px;
    height: 22px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl + .tgl-btn:after {
    left: 1px;
}

.tgl + .tgl-btn:before {
    display: none;
}

.tgl:checked + .tgl-btn:after {
    left: 50%;
}

ul.tg-list {
    float: left;
    padding-top: 7px;
    list-style: none;
    padding-left: 0;
}

.tgl-light + .tgl-btn {
    background: #f9f9f9;
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #b4b4b4;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 22px;
    height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
    background: #4dc93c;
}

.desktop_hmenu_list .submenu-arow {
    display: none
}

.mobile_hmenu_list .submenu-arow {
    display: block;
    width: 40px;
    background: #e6e6e6;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
}

.submenu-arow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 50%;
    line-height: 1;
    margin-top: -11px;
    font-size: 22px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    display: block !important;
}

.open-submenu .submenu-arow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block !important;
}

.hsearch_sec, .hsearch_sec_mbl {
    width: 380px;
    position: absolute;
    right:0px;
    top: 50px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    visibility: hidden;
}

.hsearch_sec.open, .hsearch_sec_mbl.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.hsearch_bx {
    position: relative;
}

.hsearch_bx .form-control {
    height: 48px;
    border: 0;
}

.hsearch_bx .btn {
    background: #ecdfcc url(../images/search.png) no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px;
    border-radius: 0;
    margin: 0;
    height: auto;
    padding: 0;
}

.hsearch_bx .btn:hover {
    background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    overflow: visible;
    height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
    opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
    line-height: 52px;
    padding: 0 26px;
    color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
    height: 54px;
    padding: 0 26px;
    float: left;
}

.hsearch_bx .re_select .Select-input > input {
    line-height: 34px;
}

.hsearch_trigger_img img {
    width: 26px
}

.hsearch_trigger_img {
    margin: 5px 0 0 10px;
}

.hmenu_sec ul {
    padding: 0;
    list-style: none;
    text-align: initial;
}

.hmenu_list {
    margin: 0;
}

.hmenu_list > li {
    display: inline-block;
    position: relative
}

.hmenu_list > li > a > span {
    position: relative;
}

.hmenu_actions > li {
    list-style: none;
    position: relative;
}

.hordertype_sec a {
    display: block;
    padding: 19px 26px;
    color: #fff;
    text-transform: uppercase;
   font-size: 16px;
    position: relative;
    letter-spacing: 0.5px;
    margin: 0 15px 0 25px;
    line-height: 1;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f36b31+0,f2672e+9,f26730+14,f0642f+20,ed612c+22,ed5f2d+28,ed5f31+29,ea5c2e+31,e8532b+46,e44e29+48,e44e29+51,e64d2d+52,e64d2d+52,e44b2b+53,e44b2b+57,e2482c+57,e0462a+59,e0442b+66,dd4128+68,de422c+69,dc402a+71,de3f2c+75,d83926+85,da3728+86,d63226+100 */
background: #920062; /* Old browsers */
/*background: -moz-linear-gradient(-45deg,  #f36b31 0%, #f2672e 9%, #f26730 14%, #f0642f 20%, #ed612c 22%, #ed5f2d 28%, #ed5f31 29%, #ea5c2e 31%, #e8532b 46%, #e44e29 48%, #e44e29 51%, #e64d2d 52%, #e64d2d 52%, #e44b2b 53%, #e44b2b 57%, #e2482c 57%, #e0462a 59%, #e0442b 66%, #dd4128 68%, #de422c 69%, #dc402a 71%, #de3f2c 75%, #d83926 85%, #da3728 86%, #d63226 100%);*/ /* FF3.6-15 */
/*background: -webkit-linear-gradient(-45deg,  #f36b31 0%,#f2672e 9%,#f26730 14%,#f0642f 20%,#ed612c 22%,#ed5f2d 28%,#ed5f31 29%,#ea5c2e 31%,#e8532b 46%,#e44e29 48%,#e44e29 51%,#e64d2d 52%,#e64d2d 52%,#e44b2b 53%,#e44b2b 57%,#e2482c 57%,#e0462a 59%,#e0442b 66%,#dd4128 68%,#de422c 69%,#dc402a 71%,#de3f2c 75%,#d83926 85%,#da3728 86%,#d63226 100%);*/ /* Chrome10-25,Safari5.1-6 */
/*background: linear-gradient(135deg,  #f36b31 0%,#f2672e 9%,#f26730 14%,#f0642f 20%,#ed612c 22%,#ed5f2d 28%,#ed5f31 29%,#ea5c2e 31%,#e8532b 46%,#e44e29 48%,#e44e29 51%,#e64d2d 52%,#e64d2d 52%,#e44b2b 53%,#e44b2b 57%,#e2482c 57%,#e0462a 59%,#e0442b 66%,#dd4128 68%,#de422c 69%,#dc402a 71%,#de3f2c 75%,#d83926 85%,#da3728 86%,#d63226 100%);*/ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f36b31', endColorstr='#d63226',GradientType=1 );*/ /* IE6-9 fallback on horizontal gradient */
font-family: 'poppins';
    font-weight: bold;
    border-radius: 8px;
}

.hordertype_sec a:hover {
    color: #000;
    background: #52FA00;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
    margin: 0;
    padding: 0px;
    background: #fff;
    z-index: 99;
    width: 220px;
    position: absolute;
    left: 0px;
    top: 33px;
    display: none;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover>ul {
    display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {
    font-family: 'poppins-Bold';
    font-size: 16px;
    color: #1b0000;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078)
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
    color: #ab2225;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}

.mobile_hmenu_list ul.submenu_list li a {
    display: block;
    padding: 10px 15px;
    font-family: 'CenturyGothic-Bold';
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
    color: #769e90;
}

.hsign_sec > a {
    display: inline-block;
    position: relative;
}

.hsign_sec > a:hover {
    color: #000;
}

.hsearch_sec .search_result, .hsearch_sec_mbl .search_result_mbl, .product_search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none;
}
.search-product-main .spm-lhs .search_result{
    width: 100%;
}
.product_search_result{position: absolute; top: 100%; left: 0;z-index: 5;width: 100%;}

.search_result li, .search_result_mbl li, .product_search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a,
.no-pro-found, .search_result_mbl li a, .product_search_result li a {
    color: #333;
    display: block;
    padding: 9px 15px
}

.search_result li a:hover, .search_result_mbl li a:hover, .product_search_result li a:hover {
    background: #49947c;
    color: #fff;
}

.hsearch_sec.open .input-sec, .hsearch_sec_mbl.open .input-sec {
    position: relative
}

.hsearch_sec.open .input-sec input, .hsearch_sec_mbl.open .input-sec input  {
    padding-right: 10px;
    margin-bottom: 0;
    border-color: rgba(0, 0, 0, 0.3);;
}
.htico_search{position: relative;     margin-left: 10px;
    padding-left: 5px;}
.search_text_clear {
    position: absolute;
    right: 10px;
    top: 11px;
    display: none
}

.htico_cart {
    position: relative;
    margin-left: 10px;
    padding-left: 5px;
}

.htico_cart > a {
    position: relative;
    display: block;
}

.hcartdd_trigger.active:before {
    opacity: 1;
    visibility: visible;
}


body.cart-items-open{
    overflow: hidden;
}
body.cart-items-open .logo-main-section:before {
    position: fixed;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.68);
    z-index: 9;
}
.product_orders_top{position: relative; display: flex; justify-content: space-between; background: #f5f4f4;padding: 10px 15px;border-radius: 10px;line-height: 1}
.product_orders_top h4{font-size: 16px;
    color: #060203;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Poppins';
    margin: 0;}
.product_orders_top a{   font-family: 'poppins';
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #060203;
    color: #060203;}

.cart_info {
    display: inline-block;
    vertical-align: top;
    color: #3d3d3d;
}

.cart_info h4 {
    font-size: 18px;
        /* word-break: break-all;*/
        letter-spacing: 2px;
    margin: 0 0 6px;
    line-height: 25px;
    color: #060203;
    font-family: 'Open Sans';
    font-weight: bold;
}

.cart_left .help-block {
    font-style: italic;
    color: #a7a4a4;
    margin: 5px 0 0;
    font-size: 14px;
}

.cart_extrainfo {
    color: #3d3d3d;
}

.cart_extrainfo p {
    font-family: 'BrandonGrotesque-Regular';
}

.cart_extrainfo p b {
    color: #959595;
    font-style: italic;
    font-family: 'BrandonGrotesque-Regular';
    font-weight:normal;
    font-size: 15px;
}

.cart_extrainfo p, .notes-block {
    margin: 0;
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 500;
    padding-left: 10px
}

.cart_price {
    position: relative;
    margin: 2px 0 10px 0;
    padding-right: 22px;
    line-height: 1;
}

.cart_price p {
    font-weight: bold;
    margin: 0;
    color: #060203;
    font-size: 20px;
    font-family: 'poppins';
}

.cart_remove {
    background: #b0b0b0;
    position: absolute;
    top: 17px;
    right: 0px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_remove:hover {
    background-color: #000;
}
.cart_remove img{
    width: 8px;
}

.cdd-details .cdd-details-rhs  .qty_bx {
    width: 125px;
    right: 15px;
    height: 40px;
    position: relative;
    padding: 0 36px;
    display: inline-block;
    margin: 10px 0px 0px 15px;
}


.qty_bx {
    width: 125px;
    right: 15px;
    height: 40px;
    position: relative;
    padding: 0 36px;
    display: inline-block;
    margin: 10px 0px 0px 15px;
}
.qty-box-mar{
    margin: 0px!important;
}
.qty_bx span {
    position: absolute;
    width: 38px;
    height: 100%;
    display: block;
    top: 0%;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    /* background: #0271e5; */
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    border: 1px solid #eaeaea; 
    /*border: 2px solid #ff8b27;*/

    /* border-radius: 25px; */
}

.qty_bx span.qty_minus {
    left: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.qty_bx span.qty_plus {
    right: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.qty_bx input {
    height: 100%;
    /* border: none; */
    border: 1px solid #eaeaea;
    color: #0d0b06;
    font-size: 22px;
    text-align: center;
    margin: 0;
    padding: 2px;
    /* border-radius: 5px; */
    border-radius: unset;
    font-weight: bold;
}


.qty_bx span.qty_minus:before{
    position: absolute;
    content: "";
    background: #000;
    width: 14px;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}

.qty_bx span.qty_plus:before, .qty_bx span.qty_plus:after {
    position: absolute;
    content: "";
    background: #000;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}
.qty_bx span.qty_plus:before{
    width: 14px;
    height: 2px;
}
.qty_bx span.qty_plus:after{
    width: 2px;
    height: 14px;    
}

.cart_footer span {
    font-size: 16px;
        font-family: 'CenturyGothic-Bold';
}
.cart_footer .cart_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.cart_footer .cart_row p{
    margin: 0;
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: bold;
    color: #060203;
}

.discount_info {
    margin-left: 15px;
}

.discount_info p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #3d3d3d;
}

.promo_delico_parent {
    position: relative;
}

.promo_delico {
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    color: #3d3d3d;
}

.promo_delico:hover {
    background-color: #b69a6b;
    color: #fff;
}

.cart_footer_totrow p {
    font-size: 25px;
}

.cart_footer_totrow p.help-block {
    font-size: 12px;
    font-size: 14px;
    font-weight: 300;
}

.cart_footer_totrow span {
    font-size: 25px;
}

.indication_progress {
    height: 12px;
    overflow: hidden;
    border-radius: 10px;
    background: #769e90;
    position: relative
}

.progress_bar {
    content: "";
    height: 12px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    background:#f7d3bd;
    border-radius: 10px;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.indication .help-block {
    color: #cdaf9d;
    font-size: 14px;
    font-weight: 400;
    margin: 7px 0 0;
}

.delivery_info {
    background: #f5f5f5;
    padding: 24px 35px;
}

.delivery_address {
    margin: 0 0 20px;
}

.delivery_info h4 {
    color: #838181;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}

.delivery_info p {
    color: #3d3d3d;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}

.delivery_link {
    color: #3d3d3d;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 10px 0 0 0;
    display: inline-block;
    vertical-align: top;
}

.delivery_link:hover {
    border-color: #b69a6b;
}

.delivery_time {
    margin: 0 0 16px;
}

.delivery_handle {
    border-top: 1px solid #d3d1d1;
    padding: 20px 0 0 0
}

.cart_action .btn_grey {
    margin: 0;
    border-color: #e6e3e3;
}

.cartaction_price {
    font-size: 24px;
    width: 30%;
    cursor: default;
}

.cartaction_price sup {
    font-size: 50%;
    left: -4px;
    top: -7px;
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

header .cart_action{  
        /* padding: 20px 20px 0 20px */
        padding: 0;
        z-index: 99;
        background: #fff;
}

.cart_action{
    padding: 20px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:99!important;
}

.header_top {
    background: #181818;
    position: relative;
}

.htopinfo {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 10px 0;
}

.htopinfo p {
    margin: 0;
}

.pop-alert-icon {
    padding: 0 0 15px;
}

.header_bot {
    position: relative;
}

.sticky .alert_fixed {
    top: 95px;
}

.scrollToTop {
    background: rgba(0, 0, 0, 0.7);
    width: 40px;
    height: 40px;
    line-height: 36px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: 24px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
    background: rgba(0, 0, 0, 1);
    color: #fff;
}

.viewmore_poplink {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.innerproduct > h3 {
    font-size: 21px;
    font-family: 'poppins';
    color:#000;
    font-weight: bold;
    text-transform: capitalize;
}

.innerproduct-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}



.addcart_row {
    display: none;
    padding: 0 5px;
    margin: 0 auto;
}
.addcart-row-child{     display: -webkit-box;
    display: -ms-flexbox;
    display: flex;  justify-content: center;    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;}

.modal-lg {
    width: 740px;
}
.inn-product-popup {
    background: #fff;
    width: 100%;
}

.inn-product-popup .container {
    padding: 0;
}
.prodet_banleft {
    width: 100%;
}

.prodet_baninfo {
    width: 100%;
    padding:30px 30px 0 30px;
    position: relative;
    text-align: center;
}

.prodet_baninfo .product-tags-list {
    position: relative;
    margin: 0 0 20px 0
}

.prodet_baninfo .product-tags-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap;
    justify-content: center;
}
.prodet_baninfo .product-tags-list ul li{margin: 0 3px; background: #060203; text-transform: uppercase; padding: 5px 15px;border-radius: 15px;line-height: 1;color: #fff;font-weight: bold; font-size: 12px;}

.inn-product-popup-bg {
    position: relative;
}

.inn-product-popup-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 345px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}

.inn-product-popup-inner {
    position: relative;
    padding-top: 0px;
    line-height: 1;
}

.inner_product_slide {
    outline: 0;
}

.inn-product-popup-inner,
.inn-product-popup-inner  {
    display: none !important
}

.inn-product-popup-inner .custom-dots {
    padding: 0;
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
}

.inn-product-popup-inner .custom-dots li {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
}

.inn-product-popup-inner .custom-dots li button {
    padding: 0;
    margin: 0
}

.inn-product-popup-inner .custom-dots li button:before {
    content: "";
    background: #fff;
    width: 12px;
    height: 12px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid #c3c3c3;
}

.inn-product-popup-inner .custom-dots li.slick-active button:before {
    background: #769e90;
}

.inn-product-popup-inner .custom-dots li button {
    text-indent: -9999px;
    background: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
}

.inner_product_slide {
    position: relative;
}

.pthumb_slide {
    margin: 5px;
    border: 1px solid #a2a6ac;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    width: auto !important;
}

.pthumb_slide:hover,
.slick-current .pthumb_slide {
    -webkit-box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    border-color: #e77600;
}

.popup_social {
    position: absolute;
    bottom: 5px;
    z-index: 1;
    right: 0;
    right: 5px;
}

.popup_social span {
    color: #373535;
    font-size: 12px;
    font-weight: 700;
}

.popup_social_icon {
    list-style: none;
    margin-bottom: 0;
    background: #fff;
    padding: 4px 0px 4px 4px;
}

.popup_social_icon li {
    float: left;
}

.popup_social_icon li a {
    color: #2a2a2a;
    padding: 0 10px;
    display: inline-block;
}

.popup_social_icon li a:hover {
    color: #ccc;
}

.popup_social_inner {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup_social_left {
    padding-right: 10px;
}

.copied {
    position: absolute;
    right: 10px;
    top: -21px;
    background: #000;
    padding: 3px 7px;
    color: #fff;
    font-weight: normal;
    font-size: 11px;
    border-radius: 2px;
    pointer-events: none;
}

.copied:before {
    content: "";
    border: 7px solid #000;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -11px;
    margin-left: -7px;
}

.inn_product_row {
    margin: 0 auto 0 auto;
}

.inn_product_hea p {
    color: #5b5b5b;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 20px;
}

.inn_product_hea_left h3 {
    font-size: 25px;
    font-family: 'Poppins';
    line-height: 45px;
    margin: 0 0 10px 0;
    color: #050505;
    text-transform: capitalize;
}

.prd_chosen_row {
    padding: 30px 30px 30px 30px;
}

.product_chosen_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cdd-details-lhs .product_chosen_inner {
    display: contents;
    /* display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; */
}

.product_chosen_col {
    width: 100%;
}

.product_chosen_col.product_chosen_col_right {
    margin-right: 0;
}

.product_chosen_col_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
        margin-bottom: 30px;
}

.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {
    margin-bottom: 0;
}

.form-group-modifier {
    margin-top: 23px;
}

.product_chosen_item_left.product_chosen_item_left_full {
    width: 100%;
    margin-bottom: 10px
}

.product_chosen_item_left:nth-of-type(2n+2) {
    margin-right: 0;
}

.product_chosen_item_left_second {
    float: left;
    width: 47%;
    margin-right: 3.9285714285714285%;
}

.product_chosen_item_left_second:nth-of-type(2n+2) {
    margin-right: 0;
}

.select_full {
    width: 100%;
}

.product_chosen_hea h6 {
    font-size: 17px;
    color: #000000;
    font-family: 'poppins';
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
}

.product_chosen_hea {
    position: relative;
    padding-bottom: 10px;
}

.product_chosen_item_left .chosen-container-single .chosen-single {
    width: 100%;
    height: 47px;
    line-height: 40px;
    border: 1px solid #e6e5e5;
    font-weight: 700;
}

.product_chosen_item_left .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    background-position: -246px -62px;
    width: 28px;
    height: 28px;
}

.product_chosen_item_left .chosen-container-active.chosen-with-drop .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    width: 28px;
    height: 28px;
    background-position: -287px -62px;
}

.product_chosen_item_left .chosen-container-single .chosen-single div {
    top: 10px;
    right: 0px;
}

.product_chosen_item_left .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.pmodifier_ddlist ul {
    padding: 0;
    margin: 7px 0;
    list-style: none;
}

.pmodifier_ddlist li {
    background: #ecdfcc;
    padding: 3px 35px 3px 17px;
    border: 1px solid #dcd8d8;
    border-radius: 20px;
    position: relative;
    font-weight: 700;
    color: #181818;
    margin: 0 0 4px;
}

.pmodifier_close {
    background: url(../images/close-modifier.png) no-repeat center;
    width: 48px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 20px 20px 0;
    margin: auto 0;
}

.pmodifier_close:hover {
    background-image: url(../images/cross1.png);
    background-color: #1c1c1c;
}

.product_chosen_addons {
    width: 100%;
}

.chosen_adn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.chosen_adn_right {
    line-height: 0;
}

.chosen_adn_left span {
    font-size: 16px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
}

.radio.chosen_adn_right label {
    padding-left: 0;
}

.radio .qty_bx label {
    font-weight: 700;
}

.product_chosen_col_right .custom_checkbox {
    width: 100%;
}

.product_chosen_col_right .custom_checkbox span {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    display: block;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    padding-right: 30px;
    padding-left: 0;
}

.product_chosen_col_right .custom_checkbox span:before {
    right: 0;
    left: auto;
    top: -5px;
}

.product_chosen_col_right .chosen_adn {
    margin-bottom: 16px;
}

.text-box {
    width: 100%;
}

.text-box em {
    text-transform: uppercase;
    font-size: 13px;
    color: #181818;
    text-align: right;
    float: right;
}

.text-box textarea {
    width: 100%;
    height: 120px;
    padding: 15px 15px;
}

textarea {
    resize: none
}

.chosen_upload {
    float: right;
    text-align: right;
    width: 100%;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: 0;
}

.inputfile + label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
}

.inputfile + label:hover {
    background: #333;
}

.inputfile + label:hover span {
    color: #fff;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.chosen_upload_inner label span {
    color: #72624f;
}

.inputfile-4 + label {
    color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
    color: #722040;
}

.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
    background-color: #722040;
}

.inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: 722040;
}

.prd_chosen_sub_row {
    text-align: center;
        padding: 0 0;
}

.prd_chosen_sub_row > p {
    margin-top: 25px;
    font-size: 35px;
    color: #000;
    font-family: 'CenturyGothic-Bold';
   margin-bottom: 5px;
}

.prd_chosen_sub_row p > sup {
    font-size: 20px;
}

.prd_chosen_sub_col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex: 1 1 198px;
    min-width: 100px;
    max-width: 198px;
    margin: 0 auto;
}
.popup_addcart_cls{max-width: 100%}

.prd_chosen_sub_col .alert {
    margin: 0;
    width: 100%;
}

.prd_chosen_sub_item_left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.prd_chosen_sub_item_right {
    /*width: 67.64705882352942%;*/
    width: 100%;
}

.prd_chosen_sub_item_right button {
    border: 0;
    padding: 15px 15px;
    width: 100%;
    margin-top:10px;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    outline: 0;
    font-family: 'CenturyGothic-Bold';
    margin-bottom: 0;
    border-radius: 0;
        
}

.prd_chosen_sub_item_right button:hover {
    background: #52FA00;
    color: #000;
}

/*.disbl_href_action button:hover {
    background: #a81774;
}*/


.prd_chosen_sub_item_right.prd_chosen_item_full {
    width: 100%;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button {
    max-width: 100%;
    margin: 0;
    letter-spacing: 0.5px;
    font-size: 18px;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button:hover {
    background: #52FA00;
    color: #000;
}

.prd_chosen_sub_item_left .qty_bx {
    width: 124px !important;
}

.cart_update_div button{
    margin: 0 auto;
    top: 15px;
}


.skip {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    background: #dbd9d9;
    max-width: 89px;
    width: 100%;
    display: block;
    text-align: center;
    padding: 7px 15px;
    color: #181818;
}

.skip:hover {
    color: #fff;
    background: #333;
}

.prd-sub-bg {
    background: #fff;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 10px 0 10px 10px;
    -webkit-border-radius: 10px 0 10px 10px;
    box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    position: relative;
}

.prd-sub {
    max-width: 862px;
    position: relative;
    margin: 0 auto;
}

.prd-sub h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
}

.prd-sub .slick-slide {
    padding: 0 5px;
}

.prd-sub .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.slick-next button:hover { background-color: unset !important;
    color: unset !important;}

.prd-sub .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-next:hover {
    background: #f5f5f5;
}

.prd-sub .slick-next:hover::after {
    background-position: -154px -123px;
}

.prd-sub {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub ::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub :hover {
    background: #f5f5f5;
}

.prd-sub :hover::after {
    background-position: -213px -123px;
}

.prd-sub-slide-content {
    text-align: center;
    margin-top: 10px;
}

.prd-sub-slide-content h5 {
    font-size: 14px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 8px;
}

.prd-sub-slide-content h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.prd-sub-slide-qty .qty_bx .qty_minus {
    background: #f4b56b;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_plus {
    background: #e48f2c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_minus:hover,
.prd-sub-slide-qty .qty_bx .qty_plus:hover {
    background: #333;
}

.prd-sub-slide-qty .qty_bx input {
    background: #fff;
    width: 62px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd-sub-slide-qty {
    background: #ecdfcc;
    padding: 4px 0 0px 0;
    border: 1px solid #f9f9f9;
}

.prd-sub-slide-qty .qty_bx {
    width: 146px;
    height: 40px;
}

.prd-sub-slide-qty .qty_bx span {
    height: 40px;
}

.prd-sub-slide-submit {
    background: #181818;
}

.prd-sub-slide-submit a {
    font-size: 18px;
    padding: 13px 0;
    display: block;
    text-transform: uppercase;
    color: #fff;
    border-top: 1px solid #f9f9f9;
}

.prd-sub-slide-submit a:hover {
    background: #e48f2c;
}

.slick-slide {
    outline: 0;
}

.prd-sub-slide-content .btn {
    width: 100%;
}

.checkout_hea {
    position: relative;
}

.checkout_hea::after,
.check_pg::after {
    display: block;
    content: "";
    clear: both;
}

.checkout_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.checkout_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.checkout_hea_inner {
    margin-top: 8px;
}

.cdd-details-lhs .tab-menu-container{
    position: relative;
    display: block;
    width: 100%;
    margin: 0px 0 15px;
}

.cdd-details-lhs .tab-menu-container ul{
    width: 100%;
    padding: 0;
}

.cdd-details-lhs .tab-menu-container ul li{
    position: relative;
    width: 50%;
    float: left;
    text-align: center;
    list-style: none;
}

.cdd-details-lhs .tab-menu-container ul li a:hover{
    /* border-bottom: 5px solid #f27202; */
    /* transition: all .2s linear; */
}

.cdd-details-lhs .tab-menu-container ul li a.active{
    /* background: #0271e5; */
    /* height:50px; */
}

.cdd-details-lhs .tab-menu-container ul li a{
    font-family: 'Open Sans';
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    display: block;
    width: 100%;
    /* border-bottom: 5px solid #F5F4F4; */
}

.check_pg {
    position: relative;
}

.check_pg_inner {
    background: #fff;
    padding: 75px 0px 29px 0px;
    margin-bottom: 14px;
}

.check-pg-slider .slick-slider {
    padding: 0 75px;
}

.check_pg_inner .innerproduct-col {
    width: auto;
    margin-right: 0;
}

.check_pg_inner .slick-slide img {
    text-align: center;
    margin: auto;
}

.check_pg_inner .slick-slide {
    padding: 0 7.5px;
}

.check_pg_inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.check_pg_inner .slick-next:hover {
    background: #f5f5f5;
}

.check_pg_inner .slick-next:hover::after {
    background-position: -154px -123px;
}

.check_pg_inner {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner ::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.check_pg_inner :hover {
    background: #f5f5f5;
}

.check_pg_inner :hover::after {
    background-position: -213px -123px;
}

.checkoutpage_form_outer .form-group {
    margin-bottom: 15px;
}

.check_out_fourcommon {
    width: 100%;
    margin: 0 auto;
}

.checkoutpage_form_outer {
    width: 100%;
    margin: 0 auto;
}

.cdd-details{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin-bottom: 20px; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;}
.cdd-details .ttsplitter{margin-bottom: 0;}
.checkout-total {width: 100%;margin: 25px auto 0px auto}
.checkout-total::after {display: block;content: "";clear: both}
.checkout-total .cdd-details-lhs h4,
.checkout-total .cart-header h4 {padding: 14px 20px;
    position: relative;
    background: #87311d;
    border-bottom: 0px;
    text-align: left;
    margin-left: 10px;
    font-size: 20px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0}
.checkout-total .cart-header {padding: 0;background: inherit;border: 0;margin: 0}
.checkout-total .cdd-details-rhs .cart-header h4 {margin: 0;line-height: normal}
.checkout-total .cart-header:after {display: none}
.checkout-total .cdd-details-lhs {width:49%;position: relative;padding: 0;background: #fff; -webkit-box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18); box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18);}
.checkout-total .cdd-details-rhs {width:49%;padding:0;background: #fff;position: relative;-webkit-box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18);box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18);}
.checkout-right-body-section{padding:20px 0px 20px 20px; }    
.checkout-body-section{ padding: 20px}
.checkout-body{position:relative; z-index: 10; margin-bottom:0px}

.chk_hea {
    font-size: 16px;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 15px 0;
    background-color: #fafafa;
    line-height: 1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: bold;
  font-family: 'poppins';
    text-transform: uppercase;
    color: #0c0c0c;
    position: relative;
}

.chk_hea > span {
    color: #393939;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
    padding-right: 30px;
}

.chk-foot{
    background-color: #fff;
    padding: 0px 60px 15px 60px;
    box-shadow: 0px 1px 21px 0px rgb(0 0 0 / 18%)
}

.checkout-total .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.checkout-total .checkout-control-group-top a {
    font-size: 14px;
    color: #393939;
    font-family: 'Open Sans';
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0 0px;
    display: inline-block;
    vertical-align: top;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.checkout-total .checkout-control-group-top a:hover {
    color: #769e90
}

.checkout-total .checkout-control-group-top {
    padding: 0px 0 25px 0;
    border-bottom: 1px solid #eeeded;
}

.checkout-control-group-topband {
    border-bottom: 1px solid #939292;
    padding: 0 0 35px 0
}

.checkout-control-group-topband span {
    float: right;
    font-weight: normal;
    font-size: 15px
}

.checkout-total .checkout-control-group-middle {
    margin: 0px 0 25px 0;
    padding: 25px 0 20px 0;
    width: 100%;
    border-bottom: 1px solid #eeeded;
}

.checkout-total .checkout-control-group-bottom {
    padding: 25px 0 0 0;
}
/*.checkout-billing-address {
    padding: 25px 0 20px 0;
    width: 100%;
    border-bottom: 1px solid #eeeded;
}*/
.checkout-billing-address-bottom {
    padding: 25px 0 20px 0;
    width: 100%;
    border-bottom: 1px solid #eeeded;
}
.check-billing{ padding: 20px 0 0 0; }
.checkout-billing-address .chk_hea{padding-bottom: 0; background-color: #fafafa;
    padding: 20px;
    font-family: 'Open Sans';
    font-weight: bold;
    color: #3d3d3d; margin-bottom: 0}
.checkout-total .checkout-control-group-middle .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}
.mt-20{
    margin-top:20px;
}
.checkout-total .checkout-control-group-middle .form-group .react_datepicker {
    width: 48%
}

.checkout-total .checkout-control-group-middle .form-group .input_area {
    width: 49%
}

.checkout-total .checkout-control-group-bottom textarea {
    padding: 10px 15px;
    height: 100px;
    font-size: 15px;
    width: 100%;
    outline: 0;
}

.checkout-total textarea::-webkit-input-placeholder {
    color: #595656;
}

.checkout-total textarea:-moz-placeholder {
    color: #595656;
}

.checkout-total textarea::-moz-placeholder {
    color: #595656;
}

.checkout-total .cart_body {
    padding: 0 10px;
    box-shadow: none;
}

.checkout-total .form-group.controls-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.checkout-total .form-group.controls-three > div {
    width: 49%;
}

.checkout-total .form-group.controls-three > div input {
    margin-bottom: 0
}

.order-detail-inner {
    padding: 82px 0 0 0
}

.order-detail-inner-icon {
    position: relative;
    max-width: 604px;
}

.order-detail-inner-icon-left {
    position: absolute;
    left: 0;
    top: 2px;
}

.order-detail-scotter {
    position: relative;
    z-index: 2;
}

.order-detail-scotter::after {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -283px -126px;
    width: 41px;
    height: 24px;
    left: 0;
    top: -2px;
    position: absolute;
}

.order-detail-scotter::before {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -347px -126px;
    width: 22px;
    height: 22px;
    right: 0;
    top: -2px;
    position: absolute;
}

.order-detail-inner-icon-left.order-detail-right {
    position: absolute;
    right: 0;
    top: 3px;
    left: inherit;
}

.order-detail-inner-icon-left.order-detail-inner-center {
    position: static;
    left: inherit;
    top: inherit;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.order-detail-inner-icon-left.order-detail-inner-center::after {
    display: block;
    clear: both;
    content: "";
}

.order-detail-inner-icon-left.order-detail-inner-center::before {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    max-width: 497px;
    left: 0;
    right: 0;
    height: 10px;
    margin: auto;
    top: 50%;
}

.order-detail-inner-icon-left.order-detail-inner-center img {
    vertical-align: middle
}

.delivery_total::after {
    display: block;
    content: "";
    clear: both;
}

.delivery_total {
    margin: 10px 0 0px 0;
}

.delivery_total_left {
    float: left;
    text-align: left;
    width: 50%;
}

.delivery_total_left h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
    color: #1c1b1b;
    line-height: 22px;
    font-size: 17px
}

.delivery_total_left span {
    display: block;
    color: #000;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px
}

.delivery_total_left.delivery_total_right {
    float: right;
    text-align: right
}

.delivery_total.delivery_total_number {
    margin-bottom: 5px;
    padding: 10px 0 0 0;
}

.delivery_total.delivery_total_number .delivery_total_left h2 {
    font-size: 18px;
    color: #060203;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'BrandonGrotesque-Regular';
}

.delivery_total.delivery_total_number .delivery_total_left h4 {
    color: #060203;
    font-size: 22px;
    font-family: 'BrandonGrotesque-Regular';
    margin-bottom: 0;
}

.pickup-order-div:before {
    display: none
}

.pickup-order-div .delivery_total_left {
    width: 100%;
    text-align: center
}

.pickup-order-div .delivery_total_left img {
    width: 25px;
}

.cart-product-desc-inner {
    padding-left: 20px
}

.cart-product-desc-inner span {
    display: block;
    color: #3d3d3d;
    line-height: 19px;
    font-size: 14px
}

.chekout_cart_bar .cart_footer {
    padding: 28px 15px 10px 15px;
    margin: 0;
    /* background-color: #f5f4f4;; */
}

/* scan table */

.qrcode img{
    /*padding: 25px;
    border: 1px solid #000;*/
}

.qrcode-center h3{
font-family: 'Brandon-Grot-W01-Light';
font-size: 22px;
margin-top: 25px;
font-weight: bold;
}
.qrcode-center p{
font-family: 'Brandon-Grot-W01-Light';
font-size: 20px;
/*margin-top: 25px;*/
font-weight: bold;
}

.qrcode a{
width: 100%;
min-width: 60%;
}

.qrcode{
padding: 20px 0px 0px 0px;
border: 1px solid #000;
}

.umbrella-scantable p{
    font-family: 'poppins';
    font-size: 20px;
    top: 11%;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    margin: 22px auto;
}

.umbrella-scantable-inner section{
    text-align:center;
    justify-content: center;
    margin: 0 auto;
}

.umbrella-div{
    width: 100%;
    text-align: center;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
}

.umbrella-scantable a{
    padding: 0px 10px 10px 10px;
    font-family: 'Brandon-Grot-W01-Light';
    font-size: 50px;
    font-weight: bold;
    color: #000;
    float: left;
}

.umbrella-white-popup{
    position: relative;
    /*padding: 25px;*/
    width: auto;
    max-width: 450px !important;
    margin: 0 auto!important;
}

.checkout-billing-address .dine-input {
    display:flex;
}

.mt-10{
    margin-top:10px!important;
}

.mb-10{
    margin-bottom:10px!important;
}

/**/
.gift-radio{
    top: 38px;
    position: relative;
    z-index: 10;
    padding-left: 15px;
}
.pl-45{
    padding-left: 45px!important;
}
.chekout_cart_bar .cart_left {
    padding-left: 0;
}

.chk_hea .custom_checkbox {
  position: absolute;
    top: 15px;
    right: 0;
}
.chk_hea .custom_checkbox span{ vertical-align: top;}

.chk-payment-btn-row {
    margin: 30px auto 0 auto;
    max-width: 470px
}

.chk-payment-btn-row .button {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 21px;
    padding: 14px 10px;
    font-family: 'CenturyGothic-Bold';
    background: #0271e5;
    color:#fff
}

.chk-payment-btn-row.add-tocart-cls,
.chk-payment-btn-row.checkout-continue-cls .row-replace,
#PackageEditMdl .chk-payment-btn-row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls,
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls {
    width: 49%
}

.dwn_quotion {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.dwn_quotion a {
    background: #e6e3e3;
    font-size: 16px;
    font-weight: 700;
    display: block;
    padding: 10px 0;
    color: #0d0d0d;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dwn_quotion a:hover {
    background: #333;
    color: #fff;
}

.redeem {
    position: relative;
    margin-bottom: 20px;
}

.redeem::after {
    display: block;
    clear: both;
    content: "";
}

.redeem-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.redeem-col {
    width: 49%;
    background: #f4f4f4;
    box-shadow: 0px 1px 21px 0px rgb(0 0 0 / 18%);
    padding: 25px 50px 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    border: 1px solid #48a7d2;
}
.redeem-col-right {
    width: 49%;
    background: #f4f4f4;
    box-shadow: 0px 1px 21px 0px rgb(0 0 0 / 18%);
    padding: 25px 50px 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    border: 2px solid #f4e329!important;
}
.redeem-col-full{width: 100%}
.redeem-col-full .redeem-item{   max-width: 660px; margin: 0 auto;}
/*.redeem-col.redeem-col-right {  border-color: #f7c55a;}*/

.redeem-item-hea {
    padding-left: 0px;
    position: relative;
}

.redeem-item-hea h4 {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Poppins';
    color: #1c1c1c;
    text-transform: uppercase;
    margin-bottom: 0;
}
.redeem-item-hea span {
    font-weight: bold;
    font-family: 'Poppins';
    color: #1c1c1c;
    font-size: 14px;
}

.redeem-item-hea .points {
    position: absolute;
    right: 0;
    background: #1c1c1c;
    padding: 8px 2px;
    max-width: 260px;
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    top: 8px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    font-size: 13px;
}

.redeem-item-hea .points b {
    color: #f4e329;
    font-weight:bold;
}
.redeem-col:first-of-type .redeem-item-hea .points b{  color: #54abd4; }

.redeem_apply {
    position: relative;
    margin-top: 5px;
}

.redeem_apply .button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    margin: 0;
    background: #1c1c1c;
    color:#fff;
    padding: 17px 22px;
    border-radius: initial;
    min-width: 180px;
    line-height: 14px;
}
.redeem_apply .button:hover{
    background: #0271e5;
}

.redeem_apply .btn:hover {
    background: #0271e5;
    color: #fff;
}
.redeem-item-hea-inner {
    width: 41.37770897832817%;
}
.redeem_apply .focus-out {
    padding-right: 140px;
}

.promo_apply {
    position: relative;
    margin-top: 5px;
}

.promo_apply .button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    margin: 0;
    color:#fff;
    background: #1c1c1c;
    padding: 17px 22px;
    border-radius: initial;
    min-width: 180px;
    line-height: 14px;
}
.promo_apply .button:hover{
    background: #0271e5;
}

.promo_apply .btn:hover {
    background: #0271e5;
    color: #fff;
}
.promo-item-hea-inner {
    width: 41.37770897832817%;
}
.promo_apply .focus-out {
    padding-right: 140px;
}
.chk-payment {
    padding: 0px 30px 30px 30px;
    text-align: center;
    /*border: 2px solid #ff8b27;*/
    border-radius: 10px;
}

.chk-payment-col-radio {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.chk-payment-col-radio li {
    max-width: 257px;
    margin-right: 10px;
    padding: 15px 20px 15px 20px;
    border: 1px solid #d0cfcb;
    border-radius: 7px;
    width: 100%;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chk-payment .chk-payment-col-radio li .radio_con{  font-size: 16px; text-transform: uppercase;    font-family: 'CenturyGothic-Bold';}
.chk-payment .chk-payment-col-radio li label { padding-left: 35px !important;}
.birthday-info-move {
    padding: 15px 5px 25px;
    text-align: center;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    margin-top: -19px;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #d0cfcb;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: url(../images/blue-tick.png) no-repeat;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:after {
    width: 28px;
    height: 28px;
    background-position: -455px -122px;
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:before {
    width: 28px;
    height: 28px;
}

.chk-payment-col-radio.promotion-ul-topcls li {
    width: auto;
    margin-right: 0
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    top: 0px;
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    top: 0px;
}

.chosen_radio_inner {
    width: 100%;
}

.expand {
    background: #eaeaea;
    padding: 10px 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
}

.expand h3 {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    width: 100%;
}

.expand::after {
    content: "\f107";
    right: 10px;
    display: block;
    top: 3px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand.active::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.expand_sub h1 {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 10px;
}

.expand_sub h1 span {
    width: 80%;
}

.panel-heading.expand_hea {
    cursor: pointer;
}

.panel p{
    padding: 20px 0px 0px 30px;
}

.expand_sub h4.panel-title {
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}

.expand_sub_body {
    width: 100%;
}

.expand_sub h4::after {
    content: "\f107";
    right: 0;
    display: block;
    top: -7px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand_hea.active h4.panel-title::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.chk-payment-btn-row .btn {
    width: 100%;
    border: 0;
}

.chk-payment-btn-row .row {
    margin-right: 0;
    margin-left: 0;
}

.chk-payment-btn-row [class*="col-"] {
    padding: 0;
}

.tnk-you {
    position: relative;
    padding: 110px 0;
}

.tnk-you::after {
    display: block;
    clear: both;
    content: "";
}

.tnk-header {
    max-width: 683px;
    margin: 0 auto;
    text-align: center;
}

.tnk-header .tick {
    padding: 0 15px;
}

.tick h2 {
    font-family: 'poppins';
    font-size: 50px;
    line-height: 1;
    top: 5%;
    color: #000;
    margin: 18px 0 0 0;
    -webkit-text-stroke: 1px #fff;
    text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff;
}

.tick span {
    font-size: 18px;
    color: #0b0405;
}

.tnk-detail {
    margin-top: 20px;
}

.tnk-detail h2 {
    padding: 13px 10px;
    color: #fff;
    text-transform: uppercase;
    background: #ff9f28;
    margin-bottom: 0;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 18px;
}
.Viewreceipt .tnk-detail h2{    
    color: #fff;
    background: #060203;
    font-size: 17px;
}

.tnk-order {
    background: #fff;
    padding: 30px 15px 0px 15px;
}

.tnk-order h3 {
    margin-bottom: 10px;
    font-size: 36px;
    text-transform: uppercase;
    font-family: 'CenturyGothic-Bold';
    color: #0c0c0c;
}

.tnk-order p {
    font-size: 18px;
    color: #060801;
    margin-bottom: 0;
    font-family: 'CenturyGothic-Bold';
}
.Viewreceipt .tnk-order{ background: #f5f5f5;  }
.Viewreceipt .tnk-order h3 {
    color: #292929;
}


.Viewreceipt .tnk-order p {
    color: #292929;
    font-family: 'poppins';
}

.tnk-delivery .order-detail-inner {
    padding-top: 0px;
}

.tnk-delivery {
    padding: 15px 15px;
    background: #fff;
}

.cart_row.tnkorder-first {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cart_row.tnkorder-first > div {
    width: 49%;
}
.cart_row.tnkorder-first > div h5{
    margin-bottom: 5px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'CenturyGothic-Bold';
    color: #0d0b06;
}
.cart_row.tnkorder-first > div p{
    margin: 0;
    font-size: 15px;
}

.tnk-delivery .order-detail-inner-icon-left.order-detail-inner-center::before {
    display: none;
}

.tnk-delivery .order-detail-inner-icon {
    margin: 0 auto;
}

.tnk-delivery .delivery_total {
        margin: 6px 0 20px 0 !important;
}
.tnk-chk-order {
    margin: 15px auto;
    max-width: 580px;
}

.tnk-delivery .cart_left .help-block {
    text-align: left;
}

.tnk-delivery .cart_info h4 {
    text-align: left;
}

.tnk-delivery .cart_extrainfo {
    text-align: left;
}

.tnk-delivery .cart_footer.tnk_cart_footer {
    padding: 20px 15px 0px 15px;
    margin: 0;
    background: #f5f4f4;
}

.tnk-delivery .tnk_cart_footer .cart_row {
    text-align: left;
    padding: 0px 0px 5px 0px;
}
.tnk-delivery .tnk_cart_footer .cart_row.grant-total-cls{     
    margin-bottom: 0;    padding: 10px 0px 10px 0px;    
    /*border-bottom: 3px solid #f4f4f5;*/}
.tnk-chk-order .btn {
    width: 100%;
    border: 0;
}

.delivery_datetime_div {
    padding: 10px 0 10px 0 !important;
    margin: 10px 0 0px 0 !important;
}

.remark_notesec {
    margin: 20px 0 0;
    padding: 0 7px;
}

.remark_notesec h4 {
    margin: 0 0 3px;
    font-weight: 700;
    color: #3d3d3d;
    font-size: 16px;
}

.remark_notesec p {
    margin: 0;
}

.mfp-bg {
    z-index: 10042;
}

.mfp-wrap {
    z-index: 10043;
    top: 0px!important
}

.white-popup {
    max-width: 1026px;
    padding: 0;
    margin: 30px auto;
    background: #fff;
    border-radius: 20px;
}
.white-popup.delevery_popup {    border: 1px solid hsl(211deg 98% 45%);}
.newsletter-white-popup {
    margin: 25px auto;
    position: relative;
    padding: 0px;
    max-width: 590px;
    margin: 30px auto;
}
.order_popup{ max-width: 950px; }
.pouup_in {
    padding: 60px 50px 40px;
}
.ml-0{
    margin-left: 0px!important;
}
.alert_popup,
.warining_popup,
.warning_popup,
.pay-conf-popup {
    max-width: 450px;
}

.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px 0
}

button.mfp-close,
button.mfp-arrow {
    margin: 0;
    padding: 0
}

.alert_popup .mfp-close {
    display: none;
}

.alert_header {
    background: #0271e5;
    color: #000;
    font-size: 20px;
    padding: 12px 14px;
    position: relative;
    text-align: center;
        font-family: 'CenturyGothic-Bold';
    text-transform: uppercase
}

.alert_body {
    text-align: center;
    padding: 20px;
}

.alert_body h2{
    color:#000
}
.alert_body p{
    color:#000
}

.alt_btns a {
    margin: 0 5px;
}

.alt_btns a.button-left {
    background: #fff;
    color: #000;
}

.alt_btns a.button-left a:hover {
    background: #0271e5!important;
    color:#fff!important;
}
.alt_btns a:hover {
    background: #0271e5;
    color:#fff;
}

.popup_equalrw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border: 1px solid hsl(211deg 98% 45%);
}

.popup_ttsec {
    width: 42%;
    min-height: 310px;
    padding: 20px 20px;
    border-right: 2px solid #ff9f28;
    min-height: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop_title {
    position: relative;
    color: #fff;
}
.mt-20{
    margin-top:20px;
}
.pop_title h2 {
    font-size: 80px;
    margin: 0;
    line-height: 1;
    color: #fff;
    text-transform: inherit;
}

#delevery-postcode-popup .pop_title h2{
    text-transform: uppercase;
}

.pop_title small {
    font-size: 16px;
    font-weight: normal;
}

.popup_right {
    width: 58%;
    padding: 20px 60px 20px 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #fff;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.innervmid_in {
    width: 100%
}

.delevery_popup .popup_ttsec {
    background: #ff9f28;
}

.delevery_popup .popup_ttsec {
    padding-left: 38px;
    padding-right: 28px;
}

.delevery_popup .popup_right .form-group .form-control {
    border: 1px solid #dadada;
}


.two-button-row {
        display: flex;
    max-width: 476px;
    justify-content: space-between;
    margin: 0 auto;
}

.two-button-row > div .button {
    display: block;
    margin: 0;
    width: 100%;
}

.two-button-row > div {
    width: 49%;
    
}
.go_second a {
    background: #fff;
    color:#0271e5;
}

.two-button-row .delivery_submit:hover,
.go_second a:hover {
    background: #0271e5;
    color:#fff;
}

.error_postal_popup .popup_right .button {
    margin: 0
}

img.pop-scooter-img {
    max-width: 90px;
    margin-bottom: 15px
}

.receipt_popup,
.receipt_cateringpopup {
    max-width: 620px;
}

.receipt_popup .pouup_in,
.receipt_cateringpopup .pouup_in {
    padding: 0;
}

.receipt_popup .tnk-you,
.receipt_cateringpopup .tnk-you {
    padding: 0;
}

.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
    margin-top: 0
}

.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
    margin: 30px 0
}

.error_postal_popup .popup_ttsec {
    background: #ff9f28;
}

.error_postal_popup h4 {
    color: #fff;
    font-size: 16px;
}

.poptt_icontop {
    padding-left: 0;
}

.poptt_icontop i {
    position: static;
    margin: 0 auto 17px;
}

.news_postal_popup .popup_ttsec {
    height: 309px;
}

.news_postal_popup .popup_ttsec {
    background: #b79a6c;
    width: 51.46198830409356%;
}

.news_postal_popup .popup_right {
    background: url(../images/news_popupright.png) no-repeat;
    background-size: cover;
    width: 48.53801169590643%;
}

.news_postal_popup .pop_title p {
    font-size: 16px;
    margin: 6px 0 0 0;
}

.news_subscribe {
    position: relative;
    margin-top: 14px;
}

.news_subscribe .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    border: 1px solid #f9f9f9;
}

.news_subscribe .btn:hover {
    background: #54362c;
    color: #fff;
}

.news_subscribe .form-control {
    height: 51px;
    border: 1px solid #fff;
}

.news_subscribe p {
    font-size: 16px;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 0;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
    background: rgba(24, 24, 24, 0.5);
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 32px;
    line-height: 30px;
    opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.order-body {
    text-align: center;
    padding: 30px 12px 30px 30px;
    /*border: 1px solid hsl(211deg 98% 45%);*/
}

.order-body h2 {
    font-size: 45px;
    line-height: 65px;
    margin-bottom: 0;
    font-family: 'Poppins';
    color: #000;
    text-transform: capitalize;
}

.order-body p {
    color: #000;
    font-size: 15px;
    line-height:  0px;
}

.order_delivery_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    /*flex-wrap: wrap;*/
    list-style: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    padding:30px 0 0 0;
    max-width: 950px;
}

.order_delivery_item li {
    width: 50%;
    margin: 0px 20px 20px 0px;
}

.order_delivery_item li a {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100%);
    padding: 15px 15px 10px;
    background: #fff;
    position: relative;
    border: 1px solid hsl(211deg 98% 45%);
    border-radius: 10px;
    transition: 0.3s linear all;
    box-shadow:0px 0px 24px 0px rgba(0, 0, 0, 0.12);
}
.order_delivery_item li a.active, .order_delivery_item li a:hover{
    /* border-color: #04cadf;
    box-shadow: none; */
    background: #0271e5;
    color:#fff
}



.order_delivery_item h3 {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    margin: 25px 0px 0px 0px;
    font-family: 'Poppins';
    /*text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff;
    font-family: 'jamesfajardo';
    -webkit-text-stroke: 1px #fff;
    font-weight: bold;
    text-transform: capitalize;*/
}

.order_icon {
    display: block;
    text-align: center;
    margin: auto;
}
.header-ordernow-single-img {
    padding-top: 50px;
}
.header-ordernow-single-img img {
    max-height: 75px;
    position: absolute;
    top: 7px;
    /*background: #000;*/
    left: 0;
    right: 0;
    margin: auto;
}

.takeaway-button {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    padding:10px 0 0 0;
    max-width: 280px;
}

.takeaway-button li {
    width: 48%;
}

.takeaway-button li a {
    display: block;
    padding: 15px 10px 20px;
    background: #fff;
    position: relative;
    border: 2px solid #fff;
    border-radius: 10px;
    transition: 0.3s linear all;
    box-shadow:0px 0px 24px 0px rgba(0, 0, 0, 0.12);
}


.order_delivery_item .tooltip {
    font-size: 16px;
}

.order_delivery_item .tooltip-inner {
    max-width: 260px;
}

.ordelivery_tooltip {
    position: relative;
}

.order_delivery_mobile_tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: -270px;
    top: 23px;
    background: #181818;
    padding: 10px;
    border-radius: 3px;
    width: 260px;
    margin: 0 auto 5px;
    z-index: 1;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
    opacity: 1;
    visibility: visible;
}

.order_delivery_mobile_tooltip:before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -7px;
    border: 10px solid #181818;
    border-color: transparent transparent transparent #181818;
    border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
    left: auto;
    right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
    border-color: transparent #181818 transparent transparent;
    left: -16px;
    right: auto;
}

.order_delivery_item li.disable .ordericon_link {
    display: none;
}

.order_delivery_item li.disable:hover {
    background: #f9f9f9;
    border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
    display: none;
}

.orderpop_address {
    position: relative;
    height: 100%;
    padding-top: 35px;
}

.orderpop_address p {
    font-size: 12px;
    margin: 0;
}

.orderpop_continue_shop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.orderpop_continue_shop .btn {
    font-size: 11px;
    height: 30px;
    padding: 7px 15px;
    margin: 0;
}

.orderpop_change_address {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
}

.orderpop_change_address:hover {
    color: #000;
}


.mfp-close-btn-in .order_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close,
.mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close,
.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close,
.changepw_popup .mfp-close,
.redeem_popsec .mfp-close,
.pay-conf-popup .mfp-close,
.warning_popup .mfp-close {
    background: #000;
    color: #fff;
    font-size: 0;
    width: 26px;
    height: 26px;
    opacity: 1;
    top: 5px;
    right: 5px;
    padding: 0;
    margin: 0;
    border: 0px;
    border-radius: 5px;
}

.mfp-close-btn-in .order_popup .mfp-close::after,
.mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after,
.mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after,
.mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after,
.changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after,
.pay-conf-popup .mfp-close:after,
.warning_popup .mfp-close:after, .venue_popup .close-icon-but:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover,
.mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover,
.mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover,
.redeem_popsec .mfp-close:hover,
.pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover,
.venue_popup .close-icon-but:hover{
    background: #282828;
}

.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(1, 1, 1, 0.9);
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: rgba(0, 0, 0, 0.9);
}

.video_popup .order-body {
    padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
    width: 100%;
    background: #000000;
    padding: 30px 20px;
}

.awesome_popup .btn_sec {
    max-width: 500px;
    margin: 10px auto;
}

.awesome_popup .popup_ttsec .button {
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    color: #000
}

.awesome_popup .popup_ttsec .button:hover {
    background: #769e90;
    color: #fff
}

.awesome_del {
    margin-top: 13px;
    text-align: center;
}

.awesome_del h5 {
    font-size: 18px;
    font-family: 'poppins';
    margin-bottom: 10px;
    color: #fff;
    text-transform: capitalize;
}

.awesome_del h2 {
    color: #fff;
    font-size: 14px;
    font-family: 'poppins';
    line-height: 20px;
}

.awesome_right h4 {
    font-size: 16px;
}

.awesome_popup {
    max-width: 640px;
}

.datetime_selt_lbl{ font-weight: 700; padding-bottom: 10px; color: #000; font-size: 16px; text-align: center;}
.delevery_popup_datetime .btn_sec .button{ display: block; margin: 0; width: 100%; }

.white-popup.self_popup {
    max-width: 560px;
}
.login-hd h3{
    font-size:55px !important;
}
.self-back {
    position: absolute;
    top: 13px;
    font-size: 18px;
    color: #fff;
    z-index: 1;
    left: 40px;
    text-transform: uppercase;
}

.self-back:hover {
    color: #000;
}

.self_popup .order-body {
    padding: 0 0 0px 0;
}

.self_popup_hea_row {
    background: #000;
    border-bottom: 2px solid #1e1e20;
    padding: 35px 15px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.self_popup_hea_col {
    text-align: left;
}

.order-body .self_popup_hea_row h2 {
    color: #fff;
}

.order-body .self_popup_hea_row p {
    margin-bottom: 0;
    font-size: 16px;
    color: #fff;
}

.self_popup_hea_col_left {
    padding-right: 15px;
}
.self_popup_hea_col_left img{width: 50px }

.self_pop_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 5px 25px 25px 25px;
}

.self_pop_col.self_pop_col_right {
    width: 100%;
    margin: 0 auto;
}

.self_pop_col {
    width: 50%;
}

.self_pop_item_loc {
    position: relative;
}

.self_pop_item_map {
    box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    border: 1px solid #bba075;
    height: 100%;
}

.selfpop_mapouter {
    height: 100% !important;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.self_pop_item_loc p {
    color: #595656;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 170px;
    margin: 0 auto;
    min-width: 288px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 23px;
    border-radius: 10px;
    height: 62px;
}

.loc_icon {
    width: 33px;
    height: 33px;
    background: url(../images/location_btn.png) no-repeat;
    margin-right: 10px;
}

.self_pop_col_right h4 {
    font-size: 16px;
    font-weight: 700;
    color: #595656;
    text-align: left;
    margin-left: 30px;
}

.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}

.self_outlet h2 {
    color: #000;
    font-size: 17px;
    text-align: left;
    margin: 0px 0 15px 5px;
   font-family: 'poppins';
    font-weight: 700
}

.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height: 190px;
}

.self_outlet_inner li {
    margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
    margin-bottom: 0;
}
.self_outlet_inner li a {
    background: #0271e5;
    padding: 10px 15px;
    display: block;
    font-size: 15px;
    color: #fff;
    font-family:'poppins';
    position: relative;
    overflow: hidden;
    border-radius: 3px;
}
.self_outlet_inner li a:hover,
.self_outlet_inner li.active a {
        background: #0271e5;
        color: #fff;
        /* border:1px solid #0271e5; */
}

.self_outlet .btn.cnt_btn {
    width: 100%;
    background: #72624f;
    margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
    background: #333;
}

.self_pop_locbx .form-group .form-control {
    width: 100%
}

.self_outlet .button {
    margin: 0;
    width: 100%;
    display: block;
    font-family: 'CenturyGothic-Bold';
}

.self_pop_locbx {
    margin: 0 0 15px 0
}

.white-popup.login-popup,
.white-popup.signup-popup,
.white-popup.signup-corparateone-popup,
.white-popup.signup-corparatetwo-popup,
.white-popup.guest-popup {
    background: inherit;
    max-width: 820px;
}

.pop-whole-lhs-inner {
    text-align: center;
}

.pop-whole-lhs-inner p {
    color:#000;
    margin-bottom:10px;
    font-family: 'poppins';
}

.packages-promos-popup{
        max-width: 1050px!important;
}

.packages-promos-popup .pop-whole-lhs-inner {
        background: #f5f5f5;
        padding: 10px;
}

.packages-promos-popup .inside-popup-rhs{
    padding: 0px!important;
}

.packages-promos-popup .pop-whole-lhs-inner h3 {
    margin-top: -15px!important;
    color: #333f4f!important;
}

.virtual-card-head p {
    color:#333f4f;
    font-size: 20px;
    margin-bottom:0px;
    font-family: 'poppins';
}

.newsletter-lhs-inner p {
    color:#000;
    font-size: 17px;
    text-align: center;
    margin-bottom:5px;
    font-family: 'poppins';
}
.newsletter-bg {
    width: 52%;
    text-align: center;
    margin: 0 auto;
}
.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column; 
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 0px 0px 0px 25px ;
  }
  .nl-img img{
      border-radius:10px 10px 0px 0px;
  }
  #formContent {
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    width: 100%;
    max-width: 500px;
    position: relative;
  }
  .main-nl-col{
    padding: 5px 70px 25px 80px;
  }
  
.pop-whole .kep-login-facebook.metro{
    display: block;
    margin: 0 auto;
    background: #3d5a98;
    text-transform: inherit;
    border-radius: 5px;
    border:none;
    color:#fff;
    width: 100%;
    padding: 8px 15px;
    font-size: 15px;
    font-family: "avantgarde_bk_btdemi";
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    
    }

.pop-whole .kep-login-facebook.metro:hover {
    background: #0271e5;
    }
.signup-popup-main .pop-whole-lhs-inner h3{
    margin-bottom: 0px;
    line-height: 1.5
}
.pop-whole-lhs-inner h3 {
    text-align: center;
    font-family: 'poppins';
    font-size: 45px;
    text-transform: capitalize;
    font-weight: 500;
    line-height:1.1;
    margin-top: 10px;;
    margin-bottom: 15px;
    color: #000;
    -webkit-text-stroke: 1px #fff;
    text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff;
}
.newsletter-lhs-inner h3 {
    text-align: center;
    font-family: 'BrandonGrotesque-Regular';
    font-size: 70px;
    font-weight: 500;
    line-height: 50px;
    margin-top: 20px;
    margin-bottom: 0px;
    color: #000;
}
.or-seperator {
    margin: 20px 0 15px;
    font-family: "avantgarde_bk_btdemi";
    text-transform: uppercase;
    color: #868686;
    font-size: 18px;
    position: relative;
    z-index: 1;
}
.or-seperator:before {
    height: 2px;
    background: #e1e1e1;
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 55%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
}

.or-seperator span {
    background: #fae2c7;
    padding: 0 10px;
}

.pop-whole {
    position: relative
}

.login-popup .mfp-close,
.signup-popup .mfp-close, .forgot-password-popup .mfp-close,
.signup-corparateone-popup .mfp-close,
.signup-corparatetwo-popup .mfp-close,
.guest-popup .mfp-close {
    width: 24px;
    height: 24px;
    right: 10px;
    top: 10px;
    font-size: 0;
    margin: 0;
    opacity: 1;
    background: #000;
    border-radius: 3px;
}

.login-popup .mfp-close:hover,
.signup-popup .mfp-close:hover, .forgot-password-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover,
.signup-corparatetwo-popup .mfp-close:hover,
.guest-popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.login-popup .mfp-close:after,
.signup-popup .mfp-close:after, .forgot-password-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after,
.signup-corparatetwo-popup .mfp-close:after,
.guest-popup .mfp-close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}


.popup-footer .btn {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
}

.popup-footer .btn:hover {
    background: #333;
    color: #fff;
}

.popup-footer .btn i {
    padding-right: 15px;
}

.popup-footer span {
    color: #000;
    font-size: 16px;
    margin: 0 0 3px;
}

.popup-footer .guest_btn {
    background: #70afa7;
    color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
    background: #333333;
    color: #fff;
}

.pop-whole:after {
    display: table;
    clear: both;
    content: ""
}



.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
    height: 20px;
    background-color: #000
}

.loginsep-popup .mfp-close {
    right: 20px;
    top: -6px
}



.white-popup.processing {
    max-width: 768px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}

.redeem_bansec {
    position: relative;
    overflow: hidden;
}

.redeem_bansec .innerproduct-item-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
}

.redeem_popsec .pouup_in {
    padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
    font-size: 24px;
}

@-webkit-keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
        transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.location-part-outer {
    padding: 0px !important;
    position: relative;
    overflow: hidden;
}

.location-part iframe {
    width: 100%;
    height: 680px;
}

.location-sidemenu {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 320px;
    height: 100%;
    z-index: 8;
    width: 100%;
}

.location-sidemenu .red-bar {
    background: #db4337;
    padding: 7px 40px;
}

.location-sidemenu .three-lines {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    width: 18px;
    height: 12px;
    background: url(../images/spirite_whole.png) no-repeat scroll -122px -191px transparent;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 18px;
}

.location-sidemenu .three-lines:hover {
    background-position: -156px -191px;
}

.location-sidemenu .serch-box form {
    position: relative;
}

.location-sidemenu .serch-box input[type="text"] {
    margin: 0;
    height: 35px;
    border: none;
    padding: 7px 35px 7px 15px;
    background: transparent;
    color: #fff;
    outline: 0;
    font-weight: 700;
}

.location-sidemenu .serch-box input[type="text"]::-webkit-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]::-moz-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-ms-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-moz-placeholder {
    color: #fff;
}

.loc-cont-part {
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff;
}

.location-sidemenu .serch-box input[type="submit"] {
    position: absolute;
    font-size: 0;
    right: 0;
    top: 0;
    margin: 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    min-width: inherit;
    border: none;
    border-radius: 0;
    background: url(../images/search-icon.png) no-repeat scroll center center transparent;
}

.location-sidemenu .three-dots {
    width: 19px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat scroll -193px -191px transparent;
    display: inline-block;
    position: absolute;
    right: 13px;
    top: 16px;
    cursor: pointer;
}

.outlet-list {
    max-height: 551px;
    overflow-y: auto;
}

.outlet-list > ul {
    padding: 20px 15px;
}

.outlet-list > ul > li {
    margin-bottom: 20px;
}

.outlet-list li {
    list-style: none;
    padding-left: 38px;
}

.outlet-list li span {
    display: block;
    font-weight: 700;
}

.outlet-list li .submenu {
    padding: 0;
}

.outlet-list li .submenu li {
    margin-top: 10px;
    list-style: none;
    padding: 0;
    line-height: normal;
    padding-left: 3px;
}

.outlet-list li .submenu li a {
    font-weight: 700;
    display: block;
    color: #272626;
    position: relative;
    padding-left: 14px;
    font-size: 11px;
}

.outlet-list li .submenu li a:hover {
    color: #ecdfcc
}

.outlet-list .custom_checkbox {
    position: absolute;
    left: 0;
    font-size: 0;
}

.outlet-list .custom_checkbox span:before {
    width: 16px;
    height: 17px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #e6e6e6;
}

.outlet-list .custom_checkbox input[type=checkbox]:checked + span:before {
    background-position: -216px -189px;
}

.mapinfo_trigger {
    background: red;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
}

.mapinfo_canvas {
    background: #fff;
    width: 100%;
    max-width: 320px;
    position: absolute;
    left: -320px;
    top: -49px;
    bottom: 0;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 9;
}

.mapinfo_canvas.open {
    left: 0;
}

.mapcanvas_ttsec {
    background: #db4337;
    padding: 15.5px 20px 15.5px 49px;
    position: relative;
}

.mapcanvas_close {
    background: url(../images/left-arrow.png) no-repeat center;
    position: absolute;
    width: 49px;
    height: 49px;
    line-height: 49px;
    font-size: 24px;
    text-align: center;
    left: 0;
    top: 0;
}

.outlet-list li .submenu li .mapcanvas_close {
    position: absolute;
    padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
    display: none;
}

.mapcanvas_ttsec h3 {
    font-size: 14px;
    margin: 0;
    color: #fff;
    font-weight: 700;
}

.mapcanvas_bx {
    padding: 12px 18px;
    color: #000;
}

.mapcanvas_bx h5 {
    color: #888;
    margin: 0;
    font-size: 13px;
}

.mainacc_menusec {
    margin: 0 0 40px;
    position:relative;
    z-index:10;
}

.mainacc_menulist {
    padding: 0;
    list-style: none;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom:4px solid #e3e3e3;
}

.mainacc_menulist > li {
    width: 25%;
    position: relative;
}
.mainacc_menulist > li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #ff8b27;
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s linear;
}
.mainacc_menulist > li >a {
    background: #fff;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 15px 10px;
    display: block;
    position: relative;
    font-family: 'poppins';
    font-weight: bold;
    letter-spacing: 0.1px;
    text-align: center
}
.account-tab-section{
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: bold;
}
.mainacc_menulist > li.active:before,
.mainacc_menulist > li:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.mbacc_mslidersec {
    display: none;
}

.mainacc_moblink {
    display: none;
}

.myacc_hea {
    position: relative;
    margin: 0 0 14px;
}

.myacc_hea::after,
.myacc_pg::after {
    display: block;
    content: "";
    clear: both;
}

.myacc_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.myacc_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.myacc_hea_inner {
    margin-top: 8px;
}

.box_in {
    background: #fafafa;
}

.account_sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.accmenu_sec {
    background: #000;
    width: 265px;
    display: inline-block;
    position: relative;
    border-radius: 6px;
    color: #fff;
    height:294px;
    /*overflow:hidden;*/
}

.accprofile {
    padding: 26px 10px 0px;
}

.accprofile_img {
    width: 140px;
    height: 140px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    line-height: 134px;
    border-radius: 50%;
    margin: 0 auto 0;
    overflow: hidden;
}

.accprofile_img img {
    vertical-align: middle;
}

.accprofile_info p {
    font-size: 15px;
    color: #000;
    font-weight: normal;
    margin: 0;
}

.accpoints_sec {
    padding: 15px 7px 0px
}

.accpoint_tt span {
    background: #fff;
    padding: 0 4px;
    position: relative;
    display: inline-block;
}

.accearn_points {
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 15px 10px;
}

.accearn_pointtop {
    position: relative;
    padding-left: 52px;
}

.accearn_pointtop i {
    position: absolute;
    left: 0;
    top: 7px;
}

.accearn_pointtop h4 {
    font-size: 15px;
    margin: 0;
    color: #fff
}

.accearn_pointtop span {
    font-size: 45px;
    display: inline-block;
    line-height: 0.8;
    color: #ffc800;
}

.accearn_pointtop sub {
    font-size: 60%;
    bottom: 0;
}

.accearn_points p {
    font-size: 13px;
    margin: 0;
}

.accpoint_ec {
    position: absolute;
    right: 13px;
    bottom: 8px;
}

.accsec_right {
    width: calc(100% - 265px);
    width: -webkit-calc(100% - 265px);
    display: inline-block;
    padding: 40px 50px;
    background-color: #fff;
}

.accsec_right h1 {
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: 500;
    margin-bottom: 10px;
}

.accsec_right p {
    font-size: 18px;
    font-weight: 500;
    font-family: 'poppins';
}
.css-1k3avv{
    font-size: 16px;
}
.acc-inform .form-group {
    margin-bottom: 11px;
}

.other-add-body {
    height: 175px;
    border: 1px solid #f1efef;
    border-radius: 7px;
    padding: 16px 14px 0px 14px;
    background: #fff;
}

.other-add-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

.other-add-row li {
    width: 32.1875%;
    font-size: 18px;
    margin-right: 1.6666666666666666%;
    position: relative;
    margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}

.other-add-row li a {
    background: #f9f9f9;
    padding: 15px 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 15px;
    font-family: 'BrandonGrotesque-Regular';
    border-radius: 7px;
    cursor: default;
}

.other-add-row span {
    position: absolute;
    top: 6px;
    right: 8px;
    background: url(../images/spirite_whole.png) no-repeat;
    width: 10px;
    height: 10px;
    background-position: -35px -315px;
    cursor: pointer;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.other-add-row span:hover {
    opacity: 0.5;
}

.setting_menu_list {
    /*position: absolute;*/
    left: 0;
    right: 0;
    bottom: 0;
}

.setting_menu_list > ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    position: relative;
    z-index: 11;
}

.setting_menu_list > ul li {
    position: relative;
}

.setting_menu_list > ul > li > a {
    padding: 13px 10px 12px 20px;
    background: #000;
    color: #fff;
    display: block;
    font-size: 18px;
    /*text-transform: uppercase;
    border-bottom: 2px solid #fff;*/
    margin-bottom: 0;
    font-family: 'poppins';
    font-weight: 300;
}

.setting_menu_list > ul li a:hover {
    background: #0271e5;
    color: #000;
}

.setting_menu_list > ul li a.active {
    background: #0271e5!important;
    color: #fff;
    width: 270px;
    border-radius: 5px;
}

.setting_menu_list > ul li:last-child a {
    /*border-bottom: 0;*/
}

.setting_menu_list > ul li:hover .myacc_dropmenu {
    opacity: 1;
    visibility: visible;
}
.myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    padding-left: 0;
    background: rgba(0, 0, 0, 0.77);
    padding: 30px 35px;
    z-index: 1;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
}

.myacc_dropmenu::after {
    position: absolute;
    content: "";
    border-right: 14px solid #3b3b3b;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -7px;
}

.myacc_dropmenu::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}

.myacc_dropmenu li a {
    color: #fff;
}

.myacc_dropmenu .tg-list {
    position: absolute;
    top: 0;
    right: -12px;
    padding-top: 0;
}

.main_tabsec_inner {
    padding: 20px 0px 0;
    box-shadow: 0px 3px 28px 0px rgb(1 1 1 / 14%);
    transition: 0.4s linear all;
}

.main_tabsec_inner> .nav-tabs>li {
    width: 33.17%;
    margin-right: .24154589371980675%;
    position: relative;
}

.main_tabsec_inner> .nav-tabs>li:last-child {
    margin-right: 0;
}

.main_tabsec_inner> .nav-tabs {
    text-align: center;
}

.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}

.main_tabsec_inner> .nav-tabs>li.active>a {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec_inner> .nav-tabs>li> a > span > b {
    font-weight: normal;
}

.count_num_t {
    background: #a7a7a7;
    width: 70px;
    height: 100%;
    text-align: center;
    font-style: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    color: #f1f0f0;
    font-size: 20px;
    border-top-left-radius: 3.2em 5em;
    border-bottom-left-radius: 3.2em 5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn.spirite_image {
    height: 56px;
}

.spirite_image {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.spirite_image i {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
}

.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0;
    border-bottom: 0;
}

.filter_tabsec .nav-tabs>li {
    width: 50%;
    float: left;
    /*border-bottom: 5px solid #f0f0f0;*/
    border-bottom: 0;
    position: relative;
}

.filter_tabsec .nav-tabs>li>a span {
    display: inline;
}

.filter_tabsec .nav-tabs>li>a:after {
    height: 5px;
    bottom: -5px;
    background: #fbaa1a;
    max-width: 253px;
    margin: auto;
    width: 100%;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.ord-pagination-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 0 10px 0;
}

.ord-pagination-col::after {
    display: block;
    content: "";
    clear: both;
}

.ord-pagination-col span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight:bold;
}

.ord-pagination-col p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight:bold;
}

.ord-pagination-col .form-group {
    margin-bottom: 0;
    padding-right: 5px;
}

.ord-pagination-col .form-group select {
    background: #d7d4d8;
    border: 0;
    font-weight:bold;
}

.cur-order-body {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}

.cur-order-del li a.btn:hover {
    color: #fff;
}

.cur-order-del li span {
    padding-left: 5px;
}

.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.calender-icon {
    background-position: -215px -258px;
}

.time-icon {
    background-position: -249px -257px;
}

.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}

.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cur-ord-rec li a {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #2e2e2c;
}

.cur-ord-rec li a:hover {
    color: #909090;
}

.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}

.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}
.right-arrow i{
    float:right;
    margin-top:6px;
}
.order-cat {}

.order-cat-hea {
    background: #fff;
    padding: 8px 35px;
    max-width: 331px;
    margin-bottom: 10px;
}

.order-cat h2 {
    font-size: 20px;
    color: #0b0405;
    margin-bottom: 0;
}

.order-cat-table {
    padding: 0 35px;
}

.order-cat-table h4 {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 11px;
}

.order-cat-table table {
    border: 0;
    max-width: 364px;
    margin-bottom: 10px;
}

.order-cat-table table td {
    font-weight: 300;
    font-size: 16px;
    color: #3d3d3d;
    padding: 0 5px;
}

.order-cat-table table td.p_name {
    padding-left: 0;
}

.p_name {
    width: 247px;
}

.p_val {
    width: 80px;
}

.ord-amount {
    padding: 10px 0 0 35px;
    border-top: 1px solid #efefee;
}

.ord-amount .row {
    margin-right: -20px;
}

.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}

.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}

.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}

.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}

.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}

.congrats::after {
    display: block;
    content: "";
    clear: both;
}

.congrats-inner {
    margin: 0px auto 0;
    text-align: center;
    padding: 50px 15px;
    background: #000000;
}

.congrats-inner h3 {
    font-size: 25px;
    color: #fff;
    margin-bottom: 4px;
     font-family: 'poppins';
    text-transform: capitalize;
}

.congrats-inner h2 {
    font-size: 42px;
    color: #0271e5;
    margin-bottom: 0;
}

.rewards-tab-section .ord-amount {
    border-top: inherit;
    padding-top: 0;
}

.rewards-tab-section .order-cat-hea {
    margin-bottom: 7px;
}

.earned_points {
    max-width: 300px;
    padding-left: 0;
    list-style: none;
    float: right;
    padding-right: 24px;
}

.earned_points li span {
    color: #bfbebe;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}

.earned_points li h2 {
    color: #b69a6b;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.redeem_popsec {
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    margin: 0px auto;
}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {
    width: 25%;
    height: 270px;
}

.redeem_popsec .promo-earned-content {
    padding: 30px 20px 20px;
    text-align: center;
}

.redeem_popsec .promo-earned-content h4 {
    font-size: 24px;
    margin: 0px 0px 10px 0px;
}

.redeem_popsec .promo-earned-content h2 {
    font-size: 42px;
    color: #3c3c3c;
}

.redeem_popsec .promo-earned-content p {
    margin: 7px 0 0;
}

.redeem_popsec .abc_code {
    padding: 20px 0 0px;
}

.redeem_popsec .promo-earned-content h2 span sup {
    font-size: 26px;
}

.redeem_pop_image img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.redeem_popsec .pouup_in {
    padding: 30px 55px 30px;
}

.redeem_popsec .pouup_in p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
}

.redeem_popsec .btn {
    background: #fbaa1a;
    color: #fff;
    display: block;
    border-radius: 6px;
    margin: 0 auto 0px;
    text-align: center;
}

.redeem_popsec .btn:hover {
    background: #200200;
    color: #fff;
}

.redeem_popsec .mfp-close {
    background: #d9d7d7;
    border-radius:5px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    top: 10px !important;
    right: 10px !important;
}

.redeem_popsec .mfp-close:after {
    background: url(../images/cross-close.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.redeem_popsec .mfp-close:hover {
    background: #b5b5b5;
}

.input_area {
    position: relative;
}

.input_area {
    position: relative;
}

.cal_icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 45px;
    pointer-events: none;
}

.cal_icon b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow-down.png) no-repeat center;
}

.pop-whole .kep-login-facebook.metro {
    display: block;
    margin: 0 auto;
    background: #3d5a98;
    text-transform: inherit;
    border-radius: 5px;
    width: 100%;
    padding: 8px 15px;
    font-size: 15px;
        font-family: 'poppins';
    font-weight: normal;
        display: flex;
    justify-content: center;
    align-items: center;
}
.pop-whole .kep-login-facebook.metro i{ font-size: 25px; }

.pop-whole .kep-login-facebook.metro:hover {
    background: #000;
}

.combo-input-label {
    height: 100%;
    border: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #000;
    border-radius: 5px;
    line-height: 34px;
}

.compo_acc_innerdiv {
    padding: 10px 20px 0px 20px;
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.back_arrow {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 43px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
    z-index: 4;
}

.back_arrow:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: -160px -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 15px;
    background: #523429;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;
    z-index: 111;
}

.scrollup:hover {
    background: #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
}

.cms-page {
    margin: 0px 0 30px;
}

.cms-page .container {
    background: #fff;
    padding: 40px 50px 30px;
    position: relative;
    margin-bottom: 100px;
}

.cms-page h3 {
    font-size: 27px;
    color: #040507;
    font-family: 'poppins';
}
.cms-page p {
    font-family: 'poppins';
    font-size: 18px;
}

.contactus_wrapper {
    margin-bottom: 30px;
}

.contact_left p a {
    color: inherit;
}

.contact_left p a:hover {
    color: #b69a6b;
}

.contact_left p.open-hours {
    border-top: 1px solid #eaeaea;
    padding-top: 25px;
    margin-top: 26px;
}

.contactus_pg .contact_form h3 {
    margin: 0 0 5px;
}

.contact_form .help-block {
    margin: 0 0 24px;
}

.product_tag {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 5px 0 10px;
}

.product_tag li {
    padding-right: 10px;
}

.product_tag li b {
    margin-right: 10px;
}

.product_tag li a {
    display: inline-block;
    color: #5b5b5b;
    font-weight: 700;
}

.catogry_row_fixed .bakery_slider {
    position: fixed;
    top: 96px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    margin: auto;
    text-align: center;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    max-width: 1260px;
    padding-top: 0 !important;
}

.tbars .bakery_row_fixed {
    top: 171px;
}

.bakery_row.bakery_row_fixed .slick-next {
    right: 0;
}

.chosen_file {
    position: relative;
    display: inline-block;
}

.chosen_file input[type="file"] {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.chosen_file label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
    margin: 0;
    pointer-events: none;
}

.catering .ttsplitter span {
    background: #fff;
}

.catering .ttsplitter {
    margin: 0 auto 20px;
}

.container_720 {
    margin: 0 auto;
    max-width: 720px;
}

.date_list {
    margin-bottom: 30px;
    position: relative;
    padding: 40px 120px 30px;
    border: 2px solid #dddddd;
    background: #fff;
    border-radius: 6px;
}

.date_list:after {
    content: "";
    display: table;
    clear: both;
}

.datelist_bracktime h3,
h3.choose-eventtype {
    text-align: center;
    font-size: 26px;
    margin: 0 0 20px 0;
    font-family: 'CenturyGothic';
}

.ordering-more {
    text-align: center
}

.ordering-more .button.no {
    background: #000;
}

.ordering-more .button.yes {
    background: #769e90;
}
.more-time-slots{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 476px;
    margin: 0 auto;
}
.more-time-slots a{
    width: 49%;
    margin: 0;
    padding: 17px 5px 16px;
    border-radius: 5px;
        font-size: 19px;
}

#nobtn_id {
    margin-right: 5px
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
    background: #333;
}

.ordering-more h3 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight:bold;
    margin: 0 0 15px 0;
    font-family: 'CenturyGothic';
    
}

.date_list h4 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight:bold;
    margin: 0 0 15px 0;
     font-family: 'CenturyGothic';
}

.venue-details-inner h3 {
    display: block;
    font-size: 14px;
    color: #3c3a3a;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 5px;
}

.show {
    display: block;
}

.no-padding {
    padding: 0px;
}
.errorInfo-div {
    display: none;
    background: #ef1616;
    padding: 5px;
    color: #fff;
}

.making-catering {
    width: 36%;
    float: left;
    padding: 5px 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.17)
}

.making-catering p {
    margin: 0;
}

.making-place {
    font-weight:bold;
    font-size: 16px;
}

.making-place.making-place-padding {
    display: block;
}

.change-location a {
    text-decoration: underline;
    font-size: 17px;
    color: #000;
}

.change-location a:hover {
    color: #769e90;
}

.event-time {
    width: 33%;
    padding: 5px 0px 5px 20px;
}

.event-time .title4 {
    margin-bottom: 3px;
}

.event-time .row {
    margin: 0 -4px;
}

.event-time [class*="col-"] {
    padding: 0 4px;
}

.event-time .form-group {
    margin: 0;
}

.event-time .form-control {
    height: 57px;
}

.order-select-brktime ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.order-select-brktime ul .time-cls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
    margin: 0;
    position: relative;
    border: 1px solid #e4e4e4;
}

.order-select-brktime ul .time-cls label {
    width: calc( 100% - 200px);
    font-size: 18px;
    font-weight:bold;
    color: #020202;
    text-transform: uppercase
}

.viewmenu-top-div {
    margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
    padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
    font-size: 30px;
    top: 5px;
}
ul li .cat-product-info{
    background: rgba(0, 0, 0, 0.60);
    padding: 45px 15px 45px;
    color: #fff;
    line-height: 1.4;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.35s ease-in-out;
        display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
ul li .cat-product-info h4{color: #ffffff;margin: 0 0 5px 0; }
.cat-product-button{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.cat-product-button a{
    margin: 0;
    display: block;
    height: 45px;
    padding: 15px 10px;
}
.btn_download {
    font-size: 18px;
    color: #fff;
    padding: 8px 20px 7px;
    height: 38px;
    border: 0;
    border-radius: 30px;
    background: #769e90;
    -webkit-box-shadow: 0 2px 0px 0px #0b0405;
    box-shadow: 0 2px 0px 0px #0b0405;
    margin: 6px 0px 12px;
}

.btn_download:hover {
    background: #ecdfcc;
    color: #0b0405;
    -webkit-box-shadow: 0 2px 0px 0px #b1b1b1;
    box-shadow: 0 2px 0px 0px #b1b1b1;
}

.product_modifiers_listinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.cross_multi {
    font-size: 15px;
    color: #5d5d5d;
    margin: 0 0px 5px 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 6px 12px;
    z-index: 1;
    background: #ffffff;
    font-family: 'CenturyGothic-Bold';
    letter-spacing: 1px;
    border: 1px solid #d6d6d6;
    line-height: 1;
    border-radius: 4px;
    text-align: center;
}

.checkout_time {
    padding: 0px 0px 20px;
}

.checkout_time label {
    color: #000000;
    line-height: 40px;
    padding: 4px 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight:bold;
}

.total_amt {
    background: #1c1c1c;
    padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
    font-size: 16px;
    color: #fff;
    font-weight:bold;
}

.total_amt .total {
    font-size: 25px;
    color: #fff;
    font-weight:bold;
    text-transform: uppercase;
}

.total_amt .rt {
    text-align: right;
}

.brline {
    background: #f5f5f5;
    height: 2px;
    margin-left: 40px;
}

.download-quote {
    padding: 20px 0px 0px;
    text-align: center;
}

.download-quote .btn {
    font-size: 18px;
    color: #fff;
    padding: 7px 20px;
    border: 0;
    background: #769e90;
}

.catering_thak_you .tnk-header {
    text-align: unset;
}

.catering_thak_you .tnk-detail {
    text-align: center;
}

.catering_thak_you .tnk-header .tick {
    text-align: center;
}

.catering_thak_you .order_details .qtybxs {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    line-height: 38px;
    text-align: right;
}

.catering_thak_you .package .package-list {
    padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
    border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
    margin-left: 0px;
}

.promotion_popup {
    font-size: 16px;
    max-width: 650px;
}

.promo_poptt {
    margin: 0 0 30px;
}

.promo_poptt .title1 {
    margin-bottom: 8px;
}

.popoff_bar {
    padding: 10px 0 15px;
    border: 1px solid #ddd;
    border-right: 0;
    border-left: 0;
    margin: 0 0 35px;
}

.popoff_bar span {
    display: block;
    font-size: 36px;
    font-weight: 700;
    color: #000;
}

.popoff_bar b {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
}

.popwel_formsec {}

.popwel_formsec h4 {
    margin: 0 0 10px;
}

.popwel_inputbx {
    position: relative
}

.popwel_inputbx .form-control {
    height: 51px;
    font-size: 16px;
}

.popwel_inputbx .btn {
    position: absolute;
    right: 0;
    top: 0;
    border-color: #ecdfcc;
}

.rating-option {
    position: relative;
    display: inline-block;
}

.rating-option input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.rating-option span {
    padding: 5px 0 0 40px;
}

.rating-option span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid #aba8a3;
    left: 0;
    top: 3px;
    pointer-events: none;
    line-height: 21px;
    border-radius: 50%;
}

.rating-option input[type=radio]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #72624f;
    border-color: #ecdfcc;
}

.contact_left p {
    margin: 0 0 10px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

#pay-conf-popup .process_inner {
    text-align: center;
}

#pay-conf-popup .process_inner .btn-block {
    display: inherit;
    width: auto;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    /*background: rgba(255, 255, 255, 0.8);*/
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 98;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header_middle .container {
    position: relative;
}

.htico_search > a, .htico_search_mbl > a {
    position: relative;
}

.htico_search_mbl {
    display: none;
}
.htico_search {
    display: block;
}

.header_bot {
    display: inline-block;
}

.banner-info {
    text-align: center;
    position: absolute;
    top: 46%;
    left: 0;
    right: 0;
}
.banner-info h2{
    font-family: 'Poppins';
    color: #F27202;
    text-shadow: #ffffff -1px -1px 0px, #ffffff -1px 1px 0px, #ffffff 1px 1px 0px, #ffffff 1px -1px 0px;
    font-weight: bold;
    font-size: 82px;
    /*-webkit-text-stroke: 1px #fff;
    font-size: 75px;
    text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff;*/

   
}
.banner-info p {
    color: #F27202;
    margin: 0;
    line-height: 40px;
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: bold;
    text-shadow: #ffffff -1px -1px 0px, #ffffff -1px 1px 0px, #ffffff 1px 1px 0px, #ffffff 1px -1px 0px;
}
.banner-info a{
    margin-bottom: 0;
    padding: 25px 25px 25px 25px;
    margin-top: 5px;
}

.home-banner-full {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
.home-banner .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
}

.header-top {
    background: #000;
    text-align: center;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-top p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 0px;
}
.header-logo {
    background: rgb(0 0 0 / 40%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-logo img{
    width: 625px;
}
.header-top-cls {
    padding: 7px 0 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 110;    
    background: #fff;
}
header.small_sticky + .header-top-cls  {box-shadow: 0 2px 16px 0 rgb(0 0 0 / 8%);}
.logo img {
    transition: 0.3s linear all;
    max-height: 104px;
}
.small_sticky .logo img{max-height: 90px}

.logo-main-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    align-items: center;
}
.hmenu_sec {padding-right: 5px;}
.hsign_sec {margin-top: 5px;}
.menu_icon.trigger_menunav_act {
    display: none;
}
.mbl_search_liblk{display: none}

.slick-slide {
    position: relative;
}

.wppc-checked {
    color: red;
}

.wppc-rating-full {
    background-image: url("../images/star-bg.png");
    background-repeat: no-repeat;
    padding: 5px 10px;
    margin-top: 0px;
    margin-left: 44px;
}

.subtle {
    position: absolute;
    left: 0;
    right: 0;
    background: rgba(41,41,41, 0.50);
    padding: 15px 5px 10px;
    top: 50%;
    transform: translateY(-50%);
}
footer {
    position: relative;
    margin: 80px 0 0 0;
}
.footer-bottom {
    padding-top: 30px;
    padding-bottom: 25px;
}

.footer-bottom .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 150px;
    position: relative;
}
.footer-bottom .container:before, .footer-bottom .container:after{display: none;}


.footer-right {
    text-align: right;
    padding: 0 0 10px 0;
}
.footer-right img {
    text-align: right;
    margin-top:-65px;
    padding: 0 0 10px 0;
}
.footer-left {
    text-align: left;
    margin-top: -158px;
    padding: 0 0 10px 0;
}
/* .foot-left:after{
        background: url('../images/home-logo.png') no-repeat;
    background-size: cover;
    position: absolute;
    top: 960px;
    left: 0;
    width: 100%;
    content: "";
    height: 106px;
    z-index: 9;
} */

.footer-contact a,
.footer-bottom-right h3 {
    font-size: 15px;
    text-decoration: none;
    color: #000;
}

.footer-bottom-middle ul,
.footer-bottom-right ul {
    list-style: none;
    padding: 0;
}

.footer-bottom-middle ul li a {
    color: #000;
    text-decoration: none;
    font-size: 15px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    text-transform: uppercase;
    position: relative;
    padding: 1px 0;
    font-family: 'CenturyGothic';
}

.footer-bottom-middle ul li a::before, .footer-bottom-middle ul li a::after {
    position: absolute;
    content: "";
    opacity: 1;
    width: 0;
    height: 2px;
    background-color:#fbc92a;
    -webkit-transition: width 0.4s 0.1s;
    transition: width 0.4s 0.1s;
}
.footer-bottom-middle ul li a::after{
    background-color: #fbc92a; 
}
.footer-bottom-middle ul li a::before {
    top: 0;
    right: 0;
}
.footer-bottom-middle ul li a::after {
    top: 94%;
    left: 0;
}
.footer-bottom-middle ul li a:hover::after, .footer-bottom-middle ul li a:hover::before {
    width: 100%;
}
.footer-bottom-middle ul li a:hover{ color: #ff8b27 }

.footer-bottom-middle ul li {
    display: inline-block;
    margin: 0 5px
}

.footer-bottom-right ul li a {
    color: #000;
    font-size: 22px;
    line-height: 1;
}
.footer-bottom-right ul li a:hover {color: #fbc92a;}
.footer-bottom-right {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    position: absolute;
    right: 0;
    bottom: 0;
}
.footer-bottom-right ul,
.footer-bottom-right h3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.footer-bottom-right h3{margin-bottom: 0; margin-right: 10px; color: #000;font-family: 'CenturyGothic';font-size: 15px;}

.footer-bottom-right ul li {
    padding-left: 5px;
    vertical-align: middle;
}

.copyright-section p {
    font-size: 14px;
    color: #242a35;
    text-align: center;
    margin-bottom: 0px;
}

.home-banner {
    position: relative;
    z-index: 2;
}
.container-full{ 
    max-width: 1530px;
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
}

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
    display: none !important
}

.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}


.slick-track .slick-slide .featured-products-single {
    height: 100%;
    background-color: #fff;
}

.addcart_row .qty_bx {
    float: left;
}

.addcart_row .btn {
    margin: 0 0 0 2%;
    float: left;
    font-size: 14px;
    color: #fff;
    height: 32px;
    border: none;
    cursor: pointer;
    padding: 5px 15px
}

.addcart_row .btn:hover,
.addcart_row .btn:focus {
    background: #000;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

.innerproduct-item-text h4 {
    font-size: 20px;
    font-family: 'CenturyGothic-Bold';
    color: #0a0b10;
    padding: 10px 0 8px 0;
    margin-bottom: 0;
}

.bakery-review .fa-star {
    color: #000;
}

.bakery-review .wppc-checked {
    color: #ed8a19;
}

.innerproduct-item-text p {
    line-height: 20px;
    font-size: 14px;
    color: #949292;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    margin-top: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    font-family: 'CenturyGothic-Bold';
    color: #000;
    margin-bottom: 0;
}

.innerproduct-item-text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.menu-section_full {
    overflow: hidden;
}

.checkout-heading {
    background: #0271e5;
    color: #fff;
    font-size: 17px;
    padding: 12px 10px;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'Open Sans';
    font-weight: bold;
    line-height: 1;
}
.checkout-heading-right {
    background: #060203!important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    }

header {
    position: sticky;
    top: 0;
    z-index: 99;
    margin: auto;
    /*background: #fff;*/
}

.logo a {
    position: relative;

}

.header-bottom .header-bottom-innersec {
    position: relative;
}

ul.hmenu_list > li > a {
        font-family: 'poppins';
    font-weight: bold;
    font-size: 15px;
    color: #1b1b1b;
    text-decoration: none;
    text-transform: uppercase;
    padding: 20px 10px 14px;
    display: block;
    transition: 0.3s linear all;
    position: relative;
    margin: 0 3px;
    line-height: 1;
}
.hmenu_list > li.active > a, .hmenu_list>li:hover>a  {color: #0271e5;}

/* .hmenu_list > li.active > a {color: #0271e5;} */

ul.hmenu_list > li > a:after{
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: #0271e5;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}
ul.hmenu_list > li > a:hover:after, .hmenu_list > li.active > a:after{ 
    width: 100%;
    left: 0;
}

.hsign_sec > span {
    font-size: 17px;
    vertical-align: text-bottom;
    position: relative;
    top:2px;
    color: #1b1b1b;
    margin: 0 5px;
}

.hsign_sec a {
   font-family: 'poppins';
   font-weight: bold;
    font-size: 15px;
    color: #1b1b1b;
    text-transform: uppercase;
    text-decoration: none;
}

.hsign_sec a:hover, .hsign_sec a.active {
    color: #0271e5;
    text-decoration: none;
}

.haction_sec {
    text-align: center;
    position: relative;
}

.hmenu_actions {
    position: absolute;
    top:10px;
    right: 0;
    padding: 0;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.htico_search> a > img{
    width: 23px;
    height: 23px;
    display: block;
}

.htico_cart> a img {
    width: 22px;
    height: 28px;
    display: block; 
}

.header_in {
    padding-top: 45px;
    position: relative;
}

.header_in::before,
.header_in::after {

}



.hcart_round {
    background: #ff9f28;
    min-width: 16px;
    min-height: 16px;
    font-size: 7px;
    border-radius: 100%;
    position: absolute;
    right: -7px;
    top: 0px;
    text-align: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.home-banner .slick-track .slick-slide > div {
    line-height: 0;
    width: 100%;
}

.featured-products-full .slick-dots {
    display: none !important
}

.checkout-terms-and-condition {
    height: 136px;
    overflow-y: auto;
    margin: 0 0 15px 0;
    font-size: 16px;
    border: 1px solid #d0cfcb;
    padding: 15px 10px
}

a.change-edt {
    color: #666;
    border-bottom: 1px solid #999;
    margin: 5px 0 0 0;
    display: inline-block;
    font-size: 16px;
}

a.change-edt:hover {
    color: #769e90;
    border-color: #769e90;
}

.page404-main-div {
    text-align: center;
      height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-flow: column wrap;
   flex-flow: column wrap;
}

.page404-main-div .gohome {
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    background: #000;
    padding: 15px 50px;
    border-radius: 4px;
    text-align: center;
    background: #000;
    margin: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    color: #FFF;
}
.page404-main-div h1{     
    margin-bottom: 10px;
    margin-top: 40px;}
.copyright-section {
    background: #0271e5;
    padding: 10px 10px 30px;
}

.innerproduct-button .disabled {
    background: #d5d1d2 !important;
    color: #769e90;
}

.innerproducts-list-maindiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.innerproduct-button .btn_minwid {
    background: #98684a;
}

.prodetailinner-main-div {
    min-height: 105px;
}

.product-detail-empty {
    padding: 52px 0px 35px 0px;
    text-align: center;
    color: #cb4d36;
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.custom-select-bxcls {
    position: relative
}

.custom-select-bxcls:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    height: 48px;
    pointer-events: none;
        border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.custom-select-bxcls:after {
    height: 48px;
}

.custom-select-bxcls select {
    margin: 0 !important;
    width: 100%;
    height: 50px;
    padding-left: 10px;
}

.disable-addtocart {
    pointer-events: none;
    cursor: default;
}

.disable-addtocart:after {
    background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    content: '';
}

.modfr-alert-danger {
    display: none;
    background: #ffdfdf;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    color: #c10606;
}

.modfir_addcart_cls .qty_bx input {
    color: #fff;
}

.mdf_common_rows {
    position: relative;
}

.compo_pro_acc {
    background: #F9F9F9;
}

.prd_chosen_row.compo_top_div {
    background: #ffffff;
}

.compoMdfr_item_left {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.compoMdfr_item_left .product_chosen_addons {
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.compoMdfr_item_left .product_chosen_hea {
    margin: 0px;
}

.compoMdfr_item_left .form-group {
    width: 70%;
    float: left;
    margin: 0;
}

.compoMdfr_item_left .product_chosen_hea h6 {
    font-size: 14px;
}

.compopro_acc_head {
    padding: 12px 20px 12px 20px;
    cursor: pointer;
}

.compopro_acc_head::before,
.compopro_acc_head::after {
    background: #5e5e5e;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: -webkit-transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}

.compopro_acc_head::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.compo_acc_active .compopro_acc_head::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.compopro_acc_head::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.compo_acc_active .compopro_acc_head::after {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

.text-uppercase {
    text-transform: uppercase
}

.cart_footer p,
.cart_footer span {
    color: #060203;
    font-weight: 600;
    font-family: 'Open Sans';
}
.cart_footer{
    /* padding: 15px 15px 10px 15px;
    background-color: #f5f4f4;
    position: relative;
    width: 100%;
    margin-bottom: 50px; */

}
.product-rhs .cart_footer {
   padding: 20px 20px;
    box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14);
    margin-top: 15px;
}
.product-rhs .product_orders_top{display: none}
.cart_row.grant-total-cls p,
.cart_row.grant-total-cls span {
    font-size: 22px;
    line-height: 1.2;
    font-family: 'Open Sans';
    font-weight: bold;
    color: #060203;
}

.cart_row.grant-total-cls sup {
    font-size: 60%;
    position: relative;
    top: -6px;
}

.cart_footer .cart_row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.delivery-cart-div h4 {
    font-weight:bold;
    color: #0d0b06;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
   font-family: 'poppins', sans-serif;
}

.delivery-cart-div p {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    margin: 0
}

.cart-header-second h5 {
    font-weight:bold;
    color: #060203;
    font-family: 'Poppins';
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}
.cart-header-second h3 {
    /* font-weight: bold; */
    color: #060203;
    font-family: 'Poppins';
    font-size: 16px;
    text-transform: uppercase;
    margin: 0px 0px 10px 0;
}
.cart-header-second p {
    font-weight:bold;
    color: #1c1b1b;
    font-size: 28px;
}

.cart_footer .cart_remove {
    top: 3px;
    right: 0px;
}

.product-details {
    padding: 15px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 2px solid #e6e6e6; */  
}
.product-details .container-full {max-width: 1380px;}
.product-details .container-full > div .inn-product-popup {border-radius: 20px;border: 2px solid #971568;position: relative;overflow: hidden;width: 100%;display: flex;flex-flow: row wrap;flex-direction: row;justify-content: center;padding: 45px 25px;}
.product-details .container-full > div .inn-product-popup .prodet_bansec {flex: 1 1 50%;width: 100%;max-width: 50%;padding: 0 25px;}
.product-details .container-full > div .inn-product-popup .inn_product_row {flex: 1 1 50%;width: 100%;max-width: 50%;margin: 0;padding: 0 15px;}
.product-details .container-full > div .inn-product-popup .slick-slider .slick-list .slick-track .slick-slide {display: block !important;}
.product-details .container-full > div .inn-product-popup .slick-slider .slick-list .slick-track .slick-slide img {width: 100%;max-height: 767px;}
.product-details .container-full > div .inn-product-popup .slick-slider .slick-thumb {
    display: flex !important;
    flex-flow: wrap;
    justify-content: flex-start;
    flex-direction: row;
    padding: inherit;
}
.product-details .container-full > div .inn-product-popup .slick-slider .slick-thumb li:first-child {padding: 10px 0;}
.product-details .container-full > div .inn-product-popup .slick-slider .slick-thumb li {
    position: relative;
    padding: 10px 0 10px 12px;
    cursor: pointer;
    max-width: 250px;
    list-style: none;
}
.product-details .container-full > div .inn-product-popup .slick-slider .slick-thumb li img {
    display: block;
    max-width: 144px;
    width: 100%;
    max-height: 122px;
}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_prod_item {
    background: #F5F4F4;
    border-radius: 40px;
    padding: 30px;
    max-width: 583px;
    margin: 30px auto 0;
}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_prod_item h4 {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 15px;
}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row::-webkit-scrollbar-thumb  {
    background: #971568;
}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row::-webkit-scrollbar {height: 3px;}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row::-webkit-scrollbar-track {background: #FFFFFF;}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row .more_item_pro {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row .more_item_pro .more_item_innr {
    position: relative;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 159px;
    margin-bottom: 10px;
    margin-right: 15px;
    background: #fff;
    padding: 10px;
}
.product-details .like {position: absolute;top: 13px;right: 8px;}
.product-details .like:hover img,
.product-details .like.active img {filter: brightness(0);}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row .more_item_pro .more_pro_txt h5 {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    margin: 10px 0;
    font-weight: 600;
}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row .more_item_pro .more_pro_txt .more_price {
    color: #606060;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    margin: 0;
}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row .more_item_pro .more_pro_txt .more_price  sup {}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row .more_item_pro .more_pro_foot {}
.product-details .container-full > div .inn-product-popup .prodet_bansec .more_item_row .more_item_pro .more_pro_foot .more_btn {margin: 5px 0  0;width: 100%;}
.product-details .container-full > div .inn-product-popup .inn_product_row .prodet_baninfo {text-align: left;padding: 0;}
.product-details .container-full > div .inn-product-popup .inn_product_row .inn_product_hea_left h6 {
    font-family: 'poppins';
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: capitalize;
    color: #101010;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .inn_product_hea_left h3 {text-align: left;font-size: 34px;font-weight: 700;}
.product-details .container-full > div .inn-product-popup .inn_product_row .product_desc {
    font-family: 'BrandonGrotesque-Regular';
    font-size: 20px;
    color: #0D0B06;
    margin: 0;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_row.compo_top_div {}

.product-details .container-full > div .inn-product-popup .inn_product_row .product_tab_item {display: none;}
.product-details .container-full > div .inn-product-popup .inn_product_row .tab_item_active {display: block;width: 100%;padding: 50px 0 30px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container {position: relative;display: block;width: 100%;margin: 50px 0 60px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul {width: 100%;padding: 0;}
.product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul li {position: relative;width: 50%;float: left;text-align: center;list-style: none;}
.product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul li a {font-family: 'Poppins', sans-serif;font-size: 17px;font-weight: bold;text-transform: uppercase;color: #101010;display: block;width: 100%;border-bottom: 5px solid #F5F4F4;}
.product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul li a.active,
.product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul li a:hover {border-bottom: 5px solid #971568;-webkit-transition: all .2s linear;transition: all .2s linear;}

.product-details .container-full > div .inn-product-popup .inn_product_row .compo_pro_acc {background: #F5F4F4;border-radius: 15px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .compopro_acc_head::after {
    content: "";
    display: block;
    font-size: 25px;
    line-height: 0;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    background-image: url(../images/accord-arrow.png);
    background-color: transparent;
    width: 21px;
    height: 12px;
    transition: -webkit-transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
    z-index: 2;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .compopro_acc_head::after {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.product-details .container-full > div .inn-product-popup .inn_product_row .compo_acc_active .compopro_acc_head::after {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.product-details .container-full > div .inn-product-popup .inn_product_row .compopro_acc_head::before {display: none;}
.product-details .container-full > div .inn-product-popup .inn_product_row .product_chosen_hea h6 {font-size: 17px;font-weight: bold;font-family: 'Poppins', sans-serif;background: transparent;color: #060801;}

.product-details .container-full > div .inn-product-popup .inn_product_row .compo_mdfselect_maindiv {margin: 0 15px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .compo_mdfselect_maindiv .form-group {position: relative;display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .compo_mdfselect_maindiv .form-group .custom_radio input[type=radio]:checked + span:before {background: url(../images/pink-tick.png);background-position: inherit;width: 29px;height: 29px;top: 0;}
.product-details .container-full > div .inn-product-popup .inn_product_row .compo_mdfselect_maindiv .form-group .custom_radio span:before {width: 29px;height: 29px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .compo_mdfselect_maindiv label {color: #060801;font-family: 'Century Gothic';font-size: 17px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .compo_mdfselect_maindiv label span {color: #000;}

/* .product-details .container-full > div .inn-product-popup .inn_product_row .qty_bx span {
    background: #971568;
    border: none;
    border-radius: 50%;
    width: 39px;
    height: 39px;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .qty_bx span.qty_minus:before {background: #fff;} */
.product-details .container-full > div .inn-product-popup .inn_product_row .combo-input-label {color: #000;background: transparent;font-family: 'Montserrat', sans-serif;font-size: 18px;}
/* .product-details .container-full > div .inn-product-popup .inn_product_row .qty_bx span.qty_plus:before, 
.product-details .container-full > div .inn-product-popup .inn_product_row .qty_bx span.qty_plus:after {background: #fff;} */
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row .qty_bx input {
    color: #000;
    background: transparent;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
}
/* .product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .qty_bx_grp {
    border: 2px solid #F6ECF5;
    border-radius: 10px;
    padding: 10px;
    height: auto;
    max-width: 202px;
} */
/* .product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .qty_bx {width: 100% !important;right: 0px;} */
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_left {flex-direction: column;}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col .addcart_row h6 {font-size: 15px;font-family: 'Poppins', sans-serif;margin: 0 0 10px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_right {
    width: 100%;
    flex: 1 1 243px;
    max-width: 215px;
    min-width: 120px;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_right  button {border-radius: 10px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row > p {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    flex: 1 1 145px;
    max-width: 145px;
    min-width: 100px;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review {
    margin-top: 50px;
    position: relative;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_title {
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 20px;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_title h4 {
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 10px;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row {display: block;width: 100%;}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row .pro_det_item {
    display: flex;
    align-items: center;
    flex-flow: row;
    margin: 0 0 25px;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row .pro_det_item .pro_det_icon {}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row .pro_det_item .pro_det_icon img {
    max-width: 58px;
    max-height: 58px;
    border-radius: 50%;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row .pro_det_item .pro_det_cnt {margin-left: 15px;}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row .pro_det_item .pro_det_cnt h6 {
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin: 0 0 5px;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row .pro_det_item .pro_det_cnt p {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    margin: 0 0 0;
    color: #0D0B06;
}
.product-details .mfp-close {
    width: 24px; height: 24px; background: #060203; border-radius: 6px; right: 10px; top: 10px; opacity: 1;z-index: 10;
}
.product-details .mfp-close::after {
    background-image: url(../images/crosslg1.png); background-position: center;content: "";
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}
.product-details .mfp-close:hover{
    background: #000!important;
}
.inn_produc_title {
    color: #060801;
    font-size: 51px;
    font-family: 'Poppins';
    text-align: center;
    margin: 0;
}
.back-btn {max-width:120px; position: relative;top: 0;font-family: 'Poppins';font-size: 17px;text-transform: uppercase;font-weight: bold;display: flex;align-items: center;color: #000;margin: 20px 0;}
.back-btn:hover {color: #0271e5;}
.back-btn img {margin-right: 5px;}
.product_det_list .products-ordernow-action .button {
    width: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 17px
}
.order-details-with-clear, .order_card_head{
    display: flex; 
    justify-content: space-between; 
    padding: 10px 15px;
    background: #060203; 
    align-items: center;
    line-height: 1;
    font-family: 'CenturyGothic-Bold';
    color:#fff
    /* background: #060203;
    border-radius: 15px; */
}
.order-details-with-clear h5, .order_card_head h5{ color: #fff;    font-family: 'CenturyGothic-Bold'; margin: 0; font-size: 18px; text-transform: uppercase; font-weight:bold; letter-spacing: 0.3px; }
.cart_body {
    padding: 10px 20px;
}
.order-details-with-clear a{
    font-family: 'CenturyGothic-Bold';
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    color: #fff;
}
/*.cartaction_bottom .btn {
    margin: auto;
    width: 100%;
    text-align: center;
    display: block;
    background: #fbaa1a;
    padding: 10px 10px;
    border-radius: 5px;
    height: auto;
    font-size: 16px;
        letter-spacing: 0.5px;
    color: #fff;
    font-family: 'CenturyGothic-Bold';
    text-transform: uppercase;
    transition: 0.3s linear all;
}*/

/*.cartaction_bottom .btn:hover {
    background: #000;
}*/
.cart_row.grant-total-cls {
    padding-top: 10px;
    margin-bottom: 20px;
}
.Viewreceipt .cart_row.grant-total-cls{ border-top: 0; margin-bottom: 0; padding-top: 0 }
.Viewreceipt .tnk-delivery .cart_footer.tnk_cart_footer {
    padding: 10px;
    background: #f5f5f5;
}
.Viewreceipt .delivery_total::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}
.Viewreceipt .tnk-delivery .delivery_total_number:before{
    display: none;
}

#cart-close-span{    
    display: block;
    width: 30px;
    height: 30px;
    background: #000;
    position: absolute;
    padding: 5px;
    top: 15px;
    border-radius: 5px;
    right: 25px;
    cursor: pointer;
}

#cart-close-span:before, #cart-close-span:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #fff;
}
#cart-close-span:before {
    transform: rotate(45deg);
}
#cart-close-span:after {
    transform: rotate(-45deg);
}
.delivery-cart-div, .cart-header-second{display: flex; justify-content: space-between; flex-wrap: wrap;}
.delivery-cart-div .col-sm-cls,
.cart-header-second .col-sm-cls {
    width: 48%;
}
.cart_left {
    width: calc( 100% - 95px );
}
.cart_right {
    /* width: 95px; */
        width: 120px;
}

.cdd-details .cdd-details-rhs .cart_right {
    /* width: 95px; */
        width: 120px;
}

.gst-row {
    margin-top: 15px;
}
.productlist-main-div {
    padding: 25px 0 10px;
    width: 100%;
}
.tnk-you .tnk-delivery .order-items-maindiv .cart_right{
    width: 114px;
}
.form-group.half-fg {
    display: flex;
    justify-content: space-between;
}
.form-group.half-fg .input_field{ width: 49%; }
.cart_body .hclear_cart:hover {
    color: #fbc92a;
}

.compo_minmax_maindiv .combo-input-label {
    display: block;
}

.compo_minmax_maindiv .max-min-bar {
    padding-bottom: 10px;
    font-size: 17px;
    color: #060801;
    font-family: 'Century Gothic';
}

.compo_minmax_maindiv .error_combo_div {
    color: red;
    padding-bottom: 10px;
    display: none;
}

.compo_minmax_maindiv .combo_pro_price {
    color: #060801;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}

.custom_close span {
    color: red;
}

.alert-success:before,
.single-danger:before {
    top: 10px;
    left: 8px
}

.jquery-success-msg {
    margin-left: 20px;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.btn-medium {
    min-width: 190px;
}

.cart-header-second {
    padding: 15px 0 0 0;
    width: 100%;
}

.hcart_dropdown .cart-header-first .cart_left h4{
    margin-bottom: 10px;
}

.hcart_dropdown .cart-header-first .cart_left p{
    margin-bottom: 5px;
}

.cart-direction {
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}
.tnk-delivery .delivery-cart-div .cart-direction::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 1px solid #dfdad7;
}
.innersection_wrap {
    padding: 20px 0 0px 0;
}

.tnk-chk-order .button {
    display: block;
    margin: 0;
    width: 100%;
    padding: 18px 20px 18px;
    background:#fff;
    border: 2px solid #0271e5;
    color: #0271e5;
    font-size: 17px;
}

.tnk-chk-order .button:hover {
    color: #fff;
    background: #0271e5;
}

.thank-order-detaildiv {
    max-width: 580px;
    margin: 0 auto;
    margin-bottom: 0px;
    -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
}

.mainacc_toptext {
    text-align: center;
    padding: 0 0 20px 0;
}

.mainacc_toptext h2 {
    margin: 20px 0 5px 0
}

.mainacc_toptext p {
    font-size: 18px;
    margin: 0;
    color: #000;
    font-weight:600;
}
.myacc_order_details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
     justify-content: space-between; 
/*    justify-content: space-around;
*/}

.myacc_order_details .current_order {
    width: 48.5%;
    margin-bottom: 20px;
    box-shadow: 5px 5px 5px 5px grey;
    background: #fff;
    padding: 0 0 120px 0;
    position: relative
}

.myacc_head_sec {
    background: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 15px;
    overflow: hidden
}
.myacc_head_sec .head_left .head-group h4{
    color:#fff!important;
    font-family: 'Poppins';
}
.head_right .head-group {
    z-index: 2;
    position: relative
}

.head_left h4 {
    color: #000000;
    margin: 0;
    font-size:19px
}

.head_right h4 {
    color: #fff;
    margin: 0;
    text-transform: capitalize;
    font-size: 17px
}

.head_right::before {
    content: "";
    display: block;
    background: #ff8b27;
    width: 40%;
    position: absolute;
    right: -30px;
    height: 100%;
    top: 0;
    -webkit-transform: skew(-39deg);
    transform: skew(-39deg);
    z-index: 1;
}

.order_details_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.order_btns,
.order_amt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.order_btns .button {
    margin: 0;
    width: 50%;
    display: block;
    padding: 16px 10px 16px;
    color: #000;
    border-radius: 0;
    box-shadow: none;
}

.order_btns .print_invoice {
    background: #f0f0f0;
    border-radius: 25px;
    border: 2px solid #000;
    font-family: 'Poppins';
}

.order_btns .view_recipt {
    background: #000;
    border-left: 2px solid #fff;
    border-radius: 25px;
    color: #fff;
    font-family: 'Poppins';
}
.order_btns .view_recipt.button_full_view{width: 100%}
.order_btns .button:hover {
        color: #fff;
    background: #fdaf1a;
}

.order_amt {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #000;
    padding: 16px 15px
}

.order_amt h3 {
    margin: 0;
    color: #fff;
    /* font-family: 'Poppins'; */
    font-family: 'BrandonGrotesque-Regular';
    font-size: 22px
}

.order_amt-right sup {
    font-size: 75%;
    position: relative;
    top: -5px;
    margin: 0 2px 0 0;
}

.order_details_body {
    padding: 15px
}

.order_no_deatails {
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 21px;
    font-family: 'BrandonGrotesque-Regular';
}
.order_no_deatails span {
    display: block;
}

.delivery_total_left h3 {
    font-weight:bold;
    font-size: 18px;
    font-family: 'BrandonGrotesque-Regular';
}

.delivery_total_left img {
    margin: 0 0 10px 0
}

.delivery_total {
    position: relative;
}

.delivery_total_text::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}

.web_order_typecls {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
    margin: 0 0 50px 0
}

.web_order_typecls > li {
    width: 50%;
    border-left: 2px solid rgba(255, 255, 255, 0);
}

.web_order_typecls li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 17px 10px;
    background: #e4e4e4;
    color: #000
}

.web_order_typecls li.active a,
.web_order_typecls li a:hover {
    background: #ff8b27;
}

.web_order_typecls li a h5 {
    margin: 0;
    color: #000;
    text-transform: uppercase
}
.web_order_typecls li.active a h5, .web_order_typecls li a:hover h5{ color: #fff; }

.sprite_lst_icon {
    margin: 0 10px 0 0
}

.sprite_lst_icon.order_t {
    max-width: 33px;
}

.sprite_lst_icon.catering_icon_t {
    width: 22px;
}

.sprite_lst_icon.reserve_icon_t {
    width: 22px;
}

.myacc_order_details .reserv-orderlist {
    padding: 0;
    border-bottom: 2px solid #000000
}

.myacc_order_details .reserv-orderlist .order_no_deatails {
    margin: 15px 0px 0;
    border-top: 1px solid #e4e4e4;
    padding: 20px 0 0 0;
}

.myacc_order_details .reserv-orderlist .order_no_deatails h3 {
    margin: 0;
    color: #000;
    font-size: 19px;
    text-transform: uppercase;
}

.myacc_order_details .reserv-orderlist .order_no_deatails p {
    margin: 0;
    color: #98684a;
    font-size: 21px;
    font-weight:bold;
}

.reserv-loader-div {
    display: inline-block
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 19px;
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h2 {
    margin-bottom: 5px
}

.reserv-orderlist .delivery_total.delivery_total_number {
    margin: 0;
    padding: 0
}

.web_order_typecls li:hover .count_num_t,
.web_order_typecls li.active .count_num_t {
    background: #85ae04;
}

.ord-pagination-col select {
    width: auto;
    height: auto;
    padding: 1px 0 1px 1px;
    border: 0;
    margin: 0 5px;
    background: none;
}

.delivery_submit_div .button {
    width: 100%;
}

.mainacc_menulist #masterCount {
    background: #ff8b27;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-left: 5px;
    font-size: 11px;
    line-height: 19px;
    vertical-align: top;
    margin-top: 1px;
    padding: 0 4px;
    min-width: 20px;
    font-family: 'CenturyGothic';
}


.reward-list-body .delivery_total.delivery_total_number {
    padding: 0;
    margin: 0
}

.reward-list-body .delivery_total_left {
    display: inline-block;
    width: auto;
    float: none;
    margin: 0 15px 0 0;
}

.reward-list-body .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 17px
}

.reward-list-body .order_amt_div h3 {
    margin: 0;
    font-size: 22px;
    font-family: 'BrandonGrotesque-Regular';
    font-weight: bold;
    padding: 10px 0 0 0;
}

.reward-list-body .order_amt_div h3 sup {
    font-size: 75%
}

.reward-list-body .order_amt-right h3 {
    color: #fbaa1a;
}

.reward-list-body .checkoutDate,
.reward-list-body .checkoutTime {
    position: relative;
    padding: 0 0 0 22px;
    color: #474747 !important
}

.reward-list-body .checkoutDate:before {
    background: url(../images/calenderorder.svg) no-repeat;
}

.reward-list-body .checkoutTime:before {
    background: url(../images/clock-round.svg) no-repeat;
}

.reward-list-body .checkoutDate:before,
.reward-list-body .checkoutTime:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    content: "";
}

.reward-list-body .myacc_order_details .current_order {
    padding: 0 0 110px 0
}

.promo-form {
    text-align: center;
    padding: 40px 15px;
    margin: 0;
    background: #000;
}

.promo_wrap .both-order-tab {
    padding: 10px 40px 0 40px;
}

.promo-form .myuacc-promo {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.promo-form input[type="text"] {
    margin: 0;
    height: 47px;
    font-weight: 600;
    font-family: 'Poppins';
    padding: 5px 150px 5px 20px;
    background: #fff;
    font-size: 14px;
    color: #615f5f;
}

.promo-form .applyBtn {
    margin: 0;
    border: none;
    position: absolute;
    border-radius:30px;
    right: -20px;
    top: 0;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    background: #920062;
    padding: 13px 20px;
    height: 47px;
    min-width: 146px;
}
.promo-form .applyBtn:hover{ 
   background: #52FA00;
   color: #000;
}

.promo-form h3 {
    color: #fff;
    font-size: 35px;
    margin: 0 0 15px;
    text-transform: uppercase;
    line-height: 1.8;
    font-family: 'Poppins';
}

.promo-form h3 span {
    font-size: 35px;
    display: block;
    font-family: 'poppins';
    text-transform: capitalize;
    line-height: 1;
}


.promo-earned-col-image {position: relative;
    width: 50%;
}
.promo-earned-col-image img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover;max-width: 206px;    max-height: 206px;
    object-position: center; }  
.promo-earned-col-item { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; border: 1px solid #fff; -webkit-box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14); box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14); background: #fff;}
.promo-earned-info{
    /*display: -webkit-box;*/
    /*display: -ms-flexbox;*/
    /*display: flex;*/
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;padding: 30px 10px;
    width: 100%;
    -webkit-box-align: center;-ms-flex-align: center;align-items: center;    -webkit-box-pack: center;    -ms-flex-pack: center;    justify-content: center; text-align: center; position: relative;z-index: 1}
.promo-earned-info:before{  content: "";
    content: "";
    z-index: -1;
    position: absolute;
    top: -1px;
    left: -40px;
    bottom: -1px;
    background: #ffffff;
    width: 80px;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg); }
.promo-earned-code{  font-size: 17px;margin: 0 0 5px 0; }
.promo-earned-code span{display: block; font-size: 35px}
span.promo-valid { color: #a6a6a6;  font-size: 18px;     font-family: 'BrandonGrotesque-Regular';
    padding: 0 0 0px 0;display: block;}
.promation_btn{line-height: 0}
.promation_btn .button{ margin: 0; background: #fbaa1a; -webkit-box-shadow: none; box-shadow: none; border: 1px solid #fbaa1a;border-radius: 4px; font-size: 15px; padding: 12px 18px 12px; color: #101010}
.promo-earned-info p{ font-size: 20px;  color: #4a4646; }
.promation_btn .promation_btn-one{ background: #f7f7f7; color: #000; border: 1px solid #d4d4d1; margin-right: 10px }
.promation_btn .button:hover{ background: #000; color: #fff; border-color: #000; }

.promo-bot-left span {
    display: block;
}

.promo-bot-left p {
    margin: 0;
    color: #333;
    text-transform: uppercase;
    font-size: 18px;
}

.promo-bot-left p span {
    display: block;
    font-size: 14px;
}

.promation_btn {
    line-height: 0
}


.promo-earned-col .tag {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    background: #000000;
    margin: 0;
    padding: 10px 12px 5px;
    position: absolute;
    left: -43px;
    top: 30px;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    width: 220px;
    line-height: normal;
    text-align: center;
}

.promo-earned-col .tag span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mypromo-main-div .myacc_order_details {
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}
.mypromo-main-div .myacc_order_details .promo-earned-col{
    width: 49%; 
    background: #fff;
    margin: 0 0 40px 0
}


.mypromo-main-div .myacc_order_details li:nth-child(3n) {
    margin-right: 0
}


.myvouchar-main-div .myacc_order_details {
    justify-content: normal;
    padding: 0;
    margin: 0;
    list-style: none;
}

.myvouchar-main-div .myacc_order_details .promo-earned-col {
    width: 33.33%;
    padding: 0 10px;
    background: #fff;
    margin: 0 0 40px;
}

.myvouchar-main-div .myacc_order_details .promo-earned-top {
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 195px);
    width: 100%;
}

.vouchar-popup .promo-earned-content .voucher_btn{
    margin-top: 20px;
}

.myvouchar-main-div .myacc_order_details .promo-earned-col .promo-earned-col-item .promo-earned-col-image{
    height: 150px;
    width: 100%;
    display: flex;
    --webkit-box-pack: center;
    justify-content: center;
    align-items: flex-end;
}

.myvouchar-main-div .myacc_order_details .promo-earned-col .promo-earned-col-item .promo-earned-col-image img{
    max-width: 100%;
    max-height: 100%;
}

.myvouchar-main-div .myacc_order_details .promo-earned-col .promo-earned-col-item .promo-earned-info{
    padding: 17px 13px 18px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 150px);
    width: 100%;
    background: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}

.myvouchar-main-div .myacc_order_details .promo-earned-col .promo-earned-col-item{
    height: 100%;
    flex-direction: column;
}

.vouchar-popup.redeem_popsec .promo-earned-content{
    text-align: center;
}

.myvouchar-main-div .promo-earned-info:before{
    display: none;
}

.vouchar-popup .promo-popup-image img{
    width: 100%;
}

.myvouchar-main-div .promo-earned-top h4.promo-earned-code span {
    font-size: 24px;
}

.myvouchar-main-div .promo-earned-top span {
    font-size: 20px;
}

.pickup-cart-div .cart-direction:after {
    display: none
}

.pickup-cart-div .cart-direction img {
    width: 50px;
    margin: 0 auto
}

.pickup-cart-div .cart-header-first .row {
    display: block;
}

.pickup-cart-div .cart-header-first .cart_left {
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    display: block;
}

.mobile-order-nowbtn {
    display: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
}



span.myaccount_separate {
    width: 2px;
    height: 16px;
    display: inline-block;
    background: #fff;
    margin: 0 7px 0 5px;
}

.load_more_div {
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    padding: 30px 0 0 0
}

.load_more_div .load_more_data {
    margin: 0 auto;
    min-width: 160px
}

.make-pay-div h4 {
    font-size: 20px;
}

.pay-cart-header {
    padding: 18px 0px 5px 0px !important;
}

.inner_banner {
    height: 400px;
}

.inner_banner img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

.err_alt {
    border: 1px solid #ff1313 !important;
}

.err_tarms_chk span {
    color: #ff1313;
}

.mfp-bg {
    background: rgba(0, 0, 0, 0.8);
}

#scrollbutton {
    position: fixed;
    bottom: 75px;
    right: 25px;
    width: auto;
    height: auto;
    z-index: 999;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #353535;
    display: inline-block;
    position: relative;
    color: #fff;
    text-decoration: none;
    line-height: 0;
    padding: 10px 14px;
    overflow: hidden;
    border-radius: 4px;
    font-size: 18px;
}

#scrollbutton a:hover {
    background: #0271e5;
}

#scrollbutton a i.ars {
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars {
    -webkit-transform: translateY(-25px) rotateY(360deg);
    transform: translateY(-25px) rotateY(360deg);
    opacity: 0;
    -webkit-transition: all 0.3s linear 0.2s;
    transition: all 0.3s linear 0.2s;
}

#scrollbutton a i.ars1,
#scrollbutton a i.ars2 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
    opacity: 0;
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a i.ars2 {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

#scrollbutton a:hover i.ars1,
#scrollbutton a:hover i.ars2 {
    bottom: 10%;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
    bottom: 0%;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.4s;
    transition: all 0.2s linear 0.4s;
}
.oreder-row-inv {
    margin: 0;
    width: 100%;
}

.oreder-row-inv .cart_img {
    padding: 0;
}

.oreder_itm_row {
    padding: 18px 5px 5px 5px !important;
}

.order-items-maindiv {
    background: #f5f5f5;
}
.Viewreceipt .order-items-maindiv{
    background: #fff;
}
.Viewreceipt .oreder_itm_row{
    padding: 10px 0 10px !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
        align-items: flex-start;
}
.Viewreceipt .cart_price, .thank-order-detaildiv .cart_price{ padding-right: 0 }
.Viewreceipt .tnk-delivery .delivery_total_number{
    border-bottom: 1px solid #e4e4e4;
        padding-bottom: 20px;
    margin-bottom: 10px;
}
.oreder-row-inv .cart_left {
    text-align: left;
}

.month-select-div, .choose-date-div {
    margin: 0 auto 40px auto;
}

.tarms-error {
    display: none;
    color: #f03011;
}

.catering-product-rowlist span.min_pax_spn {
    display: block;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
      font-family: 'CenturyGothic-Bold';
}

.catering-product-rowlist .price_nor {
    font-size: 22px;
    display: block;
    padding: 10px 0 0 0;
        font-family: 'CenturyGothic';
    font-weight: bold;
}

.catering-product-rowlist .price_nor sup {
    font-size: 80%;
    font-family: 'CenturyGothic-Bold';
    text-transform: uppercase;
}

.dotted-line-top {
    border-top: 1px dotted #ccc;
    padding: 20px 0 0 0;
    margin: 10px 0 0 0;
}

.custom-tarms-div .sign_reg {
    padding-left: 30px
}

.custom-tarms-div span:before {
    width: 20px;
    height: 20px;
    border: 2px solid #769e90;
    margin-top: 2px;
    border-radius: 0;
}

.custom-tarms-div .custom_checkbox input[type=checkbox]:checked + span:before {
    background: none;
    content: "\F00C";
    font-family: FontAwesome;
    text-rendering: auto;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: #769e90;
    border: 2px solid #769e90;
}

.error_div_cls {
    color: red;
    display: none
}

.single_outlet_info {
    padding-top: 15px;
    text-align: center;
}

.single_outlet_info p {
    margin: 0;
    font-size: 16px;
}

.single_outlet_info .mainacc_toptext p {
    font-size: 20px;
    font-style: normal;
}

.isDisabled {
    cursor: default;
    text-decoration: none;
}

.disableDivCls {
    display: none;
}

.event-type-single {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.rmv-border-bottom {
    border-bottom: 0px !important;
}

.no-stock-product .innerproduct-item::after {
    opacity: 1 !important;
}

.no-stock-product .innerproduct-button {
    opacity: 1 !important;
    visibility: visible !important;
    top: 50% !important;
}

.no-stock-product .innerproduct-button .btn {
    cursor: not-allowed;
    pointer-events: all !important;
}

.guest_btn {
    text-align: center;
}

.controls.single-link {
    text-align: center;
}

.controls.single-link a {
    font-size: 15px;
    display: inline-block;
    color: #000;
    font-weight: 500;
        font-family: 'poppins';
    text-transform: uppercase;
}

.controls.single-link a:hover{
    color:#0271e5;
}

.back-to-listbtn a {
    width: 100% !important;
}

.apply-red {
    color: red!important;
}

.pdpa_trmcont_link {
    font-size: 14px;
}
.pdpa_trmcont_link span{
    display: inline-block
}

.delivery_outletpoup .self_popup_hea_row { background: #000; }
.delivery_outletpoup .outlet-scooter-img { width: 86px; }

.outleterror { color: #769e90; }
.delete-act-icon {
    position: absolute;
    right: 2px;
    top: 0px;
}
.act-remove-topdiv {
    position: relative;
    padding-right: 20px;
}

.venue_popup .close-icon-but {background: #98684a;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;}
.hide_section_cls, .back_click_mbl{display: none}
.back_click_mbl a:before {
    content: "\f104";
    font-family: FontAwesome;
    margin-right: 5px;
    font-size: 18px;
    font-weight: bold;
}
.custom_center_alertcls {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: 450px;
    margin: auto !important;
    padding: 10px;
    font-size: 22px;
    border-radius: 10px;

    display: inline-block;
    text-align: center;
}
.custom_center_alertcls.alert-danger{     
    color: #ca322f;
    background-color: #fdd1d1;}
.mfp-custom-close {
    position: absolute;
}
img.view-img-span {
    width: 20px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}
img.close-img-span {
    width: 19px;
    position: relative;
    top: 1px;
    margin-left: 5px;
    display: none;
}
.chk-payment .form-group.custom-tarms-div {
    padding: 0 20px 20px;
    text-align: left;
}
.text-lbl {
    display: block;
    font-weight:bold;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.mk-catering-payment .chk-payment-col-radio li{ margin-right: 0 }
.price_nor dl {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-top;
    font-family: 'CenturyGothic-Bold';
    text-transform: uppercase;
}
.ordrdatetime_error .error { width: 100%; }
.syd_merge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.syd_date {  width: 48%;}
.syd_time.delivery_submit_cls {  width: 48%;}
.syd_merge .form-group {margin: 0}

.checkout-control-group-middle .react-datepicker-wrapper {
    display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
    position: relative;
    display: block;
}
.datetime_selt_sec .react-datepicker-wrapper, .datetime_selt_sec .react-datepicker__input-container {
    display: block;
}

.membership_status {
margin-top: -33px;
position: relative;
}

.membership_status span {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    text-transform: uppercase;
    font-family: 'CenturyGothic-Bold';
    font-size: 18px;
}

.testi-author{ padding: 55px 0 0; display: flex;justify-content: space-between;text-align:center}
.testi-author-img {
    width: 130px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testi-author-name {
    width: calc( 100% - 10px );
        display: flex;
    flex-flow: column wrap;
    font-family:'BrandonGrotesque-Regular';
    justify-content: center;
    text-align: left;
}

.testi-author-img img {
    height: 58px;
    width: 58px;
    border-radius: 50%;
    overflow: hidden;
}
.testi-author-name h4{ margin: 0 0 3px 0;
    text-transform: inherit; 
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #000;  }
.testi-author-name span{
    color: #8d8d8d;
    font-size: 15px;
    font-family: 'Poppins';
    font-size: 16px;}

.abt-inner {
    padding: 50px 60px 50px;
}    

.our-promotions-section .happening-section{
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}

.about-section .abt-inner h4{
    font-family: 'Poppins';
    font-size: 40px;
    font-weight: bold;
    color:#fff
}
.abt-inner h6{
    font-family: 'Budhayanti Script';
    color:#fff
}
.abt-inner a{
    float: left;
}
.abt-inner p{
    font-family: 'poppins';
    color:#fff
}

.activity-inner h6{
    font-family: 'Budhayanti Script';
    color:#fff
}
.activity-inner a{
    float: left;
}
.activity-inner p{
    font-family: 'poppins';
    color:#fff
}
.activity-inner a{
    width:100%!important;
    max-width: 100%;
}

.activity-inner a:hover{
    background: #0271e5;
    color:#fff;
}

.about-section{
    padding-bottom: 50px;
    padding-top: 50px;
    background: url(../images/about-bg.png) no-repeat;
    margin-bottom: 0px;
    background-position: top;
}
.about-inner-2{
    padding-top: 60px;
  
}

/*myaccount - 13 jan 2020*/
.membership-desc { background-color: #769e90; border: 2px solid #fff; padding: 12px 5px 10px; font-size: 20px; text-transform: uppercase; font-family: 'CenturyGothic-Bold'; margin: 15px auto; max-width: 250px; }
.membership-desc img{ width: 20px; margin-right: 8px; }
.user-id{ margin: 10px 0; }
.user-id span {text-transform: uppercase; font-weight:bold; }
.membership-progress {background: #4f7873; margin: 15px -10px 0; padding: 20px 0 15px; }
.membership-progress p {font-weight:bold; margin: 9px auto 0; max-width: 170px; line-height: 1.2; }
.pg-bar{ display: block; position: relative; width: 100%; height: 15px; border-radius: 25px; background-color: #2a2521; max-width: 175px; margin: 0 auto;}
.pg-bar span{ position: absolute; height: 100%; width: 0%; background-color: #fff; top: 0; left: 0; border-radius: 25px; -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3); box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);}
.member-discount-desc { display: inline-block;background: #769e90;border-radius: 10px;padding: 3px 10px;font-size: 12px;color: #fff;margin: 5px 0;line-height: 1;text-align: center; }
.member-discount-total {color: #98684a; font-weight:bold; font-size: 14px; }


.product-tag-list ul {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.product-tag-list ul li {
    margin: 0 5px;
    background: #f3ddf2;
    font-family: 'Open Sans';
    text-transform: uppercase;
    color: #060801;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
}
.product-tag-list ul li:empty {display: none;}
.product-tag-list ul li img{max-width: 16px; max-height: 16px;}


.products-image-div, .det_inner_image{height: 306px;  display: -webkit-box; display: -ms-flexbox; display: flex; --webkit-box-pack: center; -ms-flex-pack: center; -webkit-box-pack: center; justify-content: center; -webkit-box-align: end; -ms-flex-align: end; align-items: flex-end }
.product-info-div, .det_inner_content{
padding:0px 13px 18px 13px ;    
display: flex;
-webkit-box-flex: 1;
flex: 1 1 auto;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
flex-direction: column;
height: calc(100% - 306px);
background: #fff;    
transition: 0.3s linear all;    
}
.det_inner_content h5{text-transform: inherit}
.product-title-maindiv {
    position: relative;
    margin: 0 0 0px 0;
    padding: 0 0 10px 0;
}
.product-title h5 {
    margin-bottom: 10px;
}
.product-tag-list {
        display: flex; flex-wrap: wrap; justify-content: center;
}
.product-short-description{ 
    color: #757373;
    font-family: 'Open Sans';
    font-size: 15px;
        margin: 0 20px 20px 20px;
    line-height: 1.3;
    flex: 1 1 auto;
    /*height: 55px;
    overflow: hidden;*/
}
.product-price h3{
    margin: 0 0 5px;
    font-size: 24px;
    font-family: 'Poppins';
    color: #0a0b10;
    font-weight: 600;

}



.product-price h3 sup{ font-size: 70%}

.checkout-body .product-price {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    }
    .checkout-body .products-single-div{
    padding-bottom:100px;
}
.product-price-ckot {
    /*display: flex;*/
    flex-direction: column;
}

.product-short-description p {
    margin: 0;
    font-family: 'BrandonGrotesque-Regular';
    color: #757373;
    font-size: 17px;
}
.products-single-li{
    position: relative; 
    z-index: 2;     
    -webkit-box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .1);    
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .1);
    overflow: hidden;background: #fff;
    height: 100%;     
    /* border: 2px solid rgb(232 90 0); */
    /*border: 2px solid rgba(255,255,255,0.5);*/
    transition: 0.3s linear all;
}
/* .products-single-li:hover{ border-color: #a81774 } */

.events-single-li{
    position: relative; 
    z-index: 2; 
    overflow: hidden;
    -webkit-box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .1);
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .1);
    background: #fff;
    height: 100%;     
    /* border: 2px solid rgb(232 90 0); */
    /*border: 2px solid rgba(255,255,255,0.5);*/
    transition: 0.3s linear all;
}
/* .events-single-li:hover{ border-color: #a81774 } */

.featured-products-section h2{text-align: center; text-transform: capitalize;padding-bottom: 20px}
.products-ordernow-action .button.disabled, .products-ordernow-action .button.disabled:hover{ opacity: .4; cursor: not-allowed; -webkit-box-shadow: none; pointer-events: none; }
.hmenu-login-section ul {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.doorstep-block-section{position: relative}

.guest-checkout-popupdiv {
    background: #ededed;
    margin: 0 -55px;
    padding: 25px 55px;
}
.guest-checkout-popupdiv .button {
    margin: 0;
    background: #464646;
    display: block;
    width: 100%;
    padding: 17px 5px;
    font-family: 'CenturyGothic-Bold';
}
.signup-gender-selct {
    margin-bottom: 0;
}
span.birthday_info_sp {
    font-size: 12px;
    color: #737373;
}

span.birthday_info_sp b {
    font-weight: 500;
    font-style: italic;
}
.product-menu-listing{padding: 0px 0 10px 0;position: relative;}
.product-menu-listing .container { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;     align-items: flex-start}
.product-menu-listing .container:before, .product-menu-listing .container:after{ display: none;}
.productpage-main-div .product-menu-listing .container{
    display: block;
}
.productlist-main-div .products-list-ulmain, .product_det_cards > ul{ display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;padding: 0;list-style: none}
.productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card{ text-align: center;width: 23.5%;flex-wrap: wrap;height: auto;margin: 0 2% 25px 0; background: #fff;     -webkit-box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14);     box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14); transition: 0.4s linear all;padding-bottom: 110px;}

/*.productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card{ text-align: center;width: 23.828125%;flex-wrap: wrap;height: auto;margin: 0 1.5625% 20px 0; background: #fff;     -webkit-box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14);     box-shadow: 0px 3px 28px 0px rgba(1, 1, 1, 0.14); transition: 0.4s linear all;padding-bottom: 110px;}*/

.productlist-main-div .products-list-ulmain > li:nth-child(4n), .product_det_cards > ul li.det_card:nth-child(4n){margin-right: 0}
.productlist-main-div .products-list-ulmain > li.active, .productlist-main-div .products-list-ulmain > li:hover, .featured-products-main>ul> li:hover{  }
.productlist-main-div .products-list-ulmain > li .product-price {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
}
.productlist-main-div .products-list-ulmain > li .product-price .product-price-innr {display: flex;flex-direction: column;}
section.menu-nav-section {
    padding: 10px 0 0 0;
    position: sticky;
    top: 140px;
    z-index: 1;
    /* background: #fff; */
}

.productpage-main-div .menu-nav-section{
    z-index: 1
}

.productpage-main-div .product-menu-listing{
    z-index: 0    
}

.menu-nav-section h3{
    color:#101010;
    font-family: 'Poppins';
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
    /*margin-top: 20px;*/
}
.menu-section-left {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;
}
.menu-section-left-inner .menu-list-intvl{ width: 25%;text-align: center; }
.menu-section-left-inner .menu-list-intvl a{ padding: 4px 2px; position: relative;cursor: pointer; color: #101010; font-family: 'poppins'; font-weight: 600; text-transform: uppercase; font-size:16px;line-height: normal; display: block;     height: 100%;}

.menu-section-left-inner .menu-list-intvl a:before, .more-menu-parent:before{
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom:0px;
    background: #0271e5;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.menu-section-left-inner .menu-list-intvl.active a:before, .menu-section-left-inner .menu-list-intvl a:hover:before, .more-menu-parent:hover:before, .more-menu-parent.active:before{ left: 0; right: 0; }
.menu-section-left-inner {
    width: calc( 100% - 20% );
    display: flex;
}

.more-menu {
    width: 20%;
}
ul.more_categor_info {
    padding: 0;
    list-style: none;
    min-width: 200px;
    max-width: 280px;
    background: #fff;
    position: absolute;
    right: 0;
    max-height: 60vh;
    overflow-y: auto;
    border-radius: 3px;
    top: calc(100% + 5px);
    border: 1px solid rgba(0,0,0,0.1);
}
ul.more_categor_info li a{ display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #f3f3f3;
    color: #0a0b10; font-family: 'CenturyGothic-Bold';
    text-transform: capitalize;
    font-size: 15px;
    line-height: 1;
    color: #000;}
ul.more_categor_info li:last-child a{ 
 border-bottom: 0;
}
ul.more_categor_info li a:hover, ul.more_categor_info li.active a{ color: #0271e5 }
.more-menu-parent {
    cursor: pointer;
    padding: 5px 0px 10px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #101010;
    font-family: 'poppins';
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    height: 100%;
}
label.open_more_category {
    width: 25px;
    text-align: right;
    cursor: pointer;
    font-size: 25px;
    color: #0a0b10;
    position: absolute;
    right: 10px;
}
.progress_bar_div{ 
    margin-top: 10px
}
.common-inner-blckdiv {}
.common-inner-banner {
    position: relative; 
    background:url(../images/products-bc.jpg) no-repeat;  
    display: flex;
    justify-content: center; 
    width: 100%;
    z-index: 1;
    align-items: center;
    text-align: center;
    padding: 100px 0;
}
.common-inner-blckdiv::after { 
    /* content: "";
    position: absolute;  
    background:url(../images/products-bc.jpg) no-repeat; 
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: bottom;
    z-index: 0;
    top: 6%; */
} 
.common-inner-banner p{
    margin: 0;
    font-family: 'poppins';
    font-size: 50px;
    line-height: 1;
    position: absolute;
    /*top: 5%;*/
    color: #fff;
/*    -webkit-text-stroke: 1px #fff;
    text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff;*/
}

.booking-common-inner p{
    top: 30%;
}

.common-inner-banner span{      font-family: 'poppins';
    font-size: 16px;
    /*margin-top: 85px;*/
    font-weight: 600;
    z-index: 21;
    color: #fff;

}

.common-inner-banner .dbest-myacc {
    top: 28%;
}

.dbest-myacc-span{
    position: relative;
    top: 25px;
}

.common-inner-banner img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}    
.menu-section-inner{max-height: 500px; overflow: auto;}
.fxd_lftmenu_div{position: fixed; top: 160px; width: 300px;}
.bottom_abs {  position: absolute; top: auto; bottom: 0; width: 300px;}

.prodet_top{text-align: center}
.prodet_top img{margin: 0 auto}
.prodetailinner-main-div .modal-body{ padding: 0; }
.detail-pg-slider .slick-list {
    max-width: 1280px;
}
.detail-pg-slider .slick-track .slick-slide {
    /* max-width: 350px; */
}

.detail-pg-slider .slick-track .slick-slide > div {
    margin: 30px 7px;
    -webkit-box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14);
    /* box-shadow: 0px 3px 20px 0px rgba(1, 1, 1, 0.14); */
    width: 100%;
    text-align: center;
        overflow: hidden;
}
.detail-pg-slider .product-title h3{font-size: 19px; margin: 0;}
.addonpro-slider-top{ margin: 0px 0 0px 0}
.chk-payment-title h3, .chk-addonpro-title h3{font-size: 20px;
    /* text-transform: uppercase; */
    text-align: center;
    padding-top: 25px;
    font-weight: bold;
    color: #060203;
    font-family: 'Open Sans';
}

.chk-payment-title p{
    font-size: 16px;
    /* text-transform: uppercase; */
    text-align: center;
    font-weight: 500;
    color: #060203;
    font-family: 'Open Sans';
}

.detail-pg-slider .product-tag-list{bottom: -8px;}
.chk-addonpro-title h3{margin-bottom: 0px}
.addcart_row:after { clear: both; content: ""; display: table;overflow: hidden;}
.birthday-info-div{padding: 25px 5px 0px 5px; text-align: center;}
.birthday-info-top h3{ font-size: 43px;color:#282828;font-family:'CenturyGothic';  font-weight: bold;text-transform: uppercase;margin-bottom: 0; padding-top: 10px; line-height: 1; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.birthday-info-top h3 img {margin: 0px 0 0 15px; position: relative; top: -5px;}
.birthday-info-middle{ font-family: 'poppins'; font-weight: bold; padding: 10px 0 0 0; font-size:16px; color: #060203 }
.left-input-div, .right-input-div {max-width: 280px;width: 100%;}
.birthday-inpt-act {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;
justify-content: space-between;-ms-flex-wrap: wrap;flex-wrap: wrap;max-width: 570px;margin: 0 auto;}
.narml_select { position: relative;}
.narml_select:after {  width: 30px; height: 46px; content: ""; position: absolute; right: 1px; background: #fff;    top: 1px;}
.birthday-info-top p{
    color: #282828;
    font-size: 20px;
    font-family: 'CenturyGothic';
    margin-bottom: 0px;
}

.myacc-info-top p{
    color: #000;
    font-size: 22px;
    font-family: 'BrandonGrotesque-Regular';
    margin-bottom: 0px;
    line-height: 8px;
    width: 200px;
    text-align: center;
}
.myacc-info-top h3{ font-size: 35px;color:#000;font-family: 'BrandonGrotesque-Regular';
    font-weight: bold;text-transform: uppercase;margin-bottom: 0;
     padding-top: 10px; line-height: 1; display: -webkit-box; 
     display: -ms-flexbox; display: flex; }
.myacc-info-top h3 img {margin: 0px 0 0 15px; position: relative; top: -5px;}
.normal-popup {
    max-width: 680px;
    text-align: center;
}
.myacc-info-top{
    margin-bottom: 15px;
}
.normal_popup_cont ul{padding: 0; list-style: none}
.normal-popup button.mfp-close{background:#769e90;
opacity: 1;
color:#fff;
border-radius: 2px;
width: 30px;
height: 30px;
line-height: 1;}

.promopopup-maindiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#promo-check-popup {
  max-width: 810px;
  background: none;
}
.promopopup-rhs {
  width: 53.08641975308642%;
  border: 1px solid #769e90;    
}
.promopopup-lhs {
  width: 46.91358024691358%;
  border-top: 30px solid rgba(0,0,0,0);
  border-bottom: 30px solid rgba(0,0,0,0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.frm-action-div{   background: #769e90; color: #fff;  padding:40px 30px; }
.promopopup-rhs img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; }
.promopopup-lhs .frm-top-title h3{ color: #fff; margin-bottom:0px; }
.promopopup-lhs .frm-inputbtn-div { position: relative;}
.promopopup-lhs .frm-inputbtn-div .button {
position: absolute;
right: 0;
top: 0;
margin: 0;
height: 48px;
background:
#000;
font-size: 15px;
padding: 10px 14px 10px;
font-family: 'poppins';
font-weight: bold;
border: 1px solid #fff;    
}
.promopopup-lhs .form-group input{border-color: #fff;padding-right: 110px;}
.promopopup-lhs .frm-bottom-text{ text-transform: uppercase; font-size: 14px; font-weight: bold;margin-bottom: 0}
.promopopup-lhs .frm-bottom-text p{margin: 0}
.promopopup-lhs .form-group{margin-bottom: 5px;}
#promomail-error { color:  #b70000; line-height: 1; font-size: 13px}
.promo-check-popup button.mfp-close{background:#769e90;
opacity: 1;
color:#fff;
border-radius: 6px;
width: 30px;
height: 30px;
line-height: 1;
top: -15px;
right: -15px;}

.prodetailinner-main-div button.close {
    width: 24px;
    height: 24px;
    background: #000;
    color: #fff;
    box-shadow: none;
    top: 5px;
    right: 5px;
        text-indent: 9999px;
    position: absolute;
    border-radius: 4px;
}
.prodetailinner-main-div button.close:after{ 
 background: url(../images/plus-white.svg) no-repeat;
 width: 16px;
height: 16px;
content: "";
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
    -webkit-transform: rotate(45deg);   
    transform: rotate(45deg);    
}
#ProductDetailMdl .modal-content{ border-radius: 0 }

@media (max-width: 1300px){
    .menu-section-innerr{max-height: 430px;}
}
@media (min-width: 1400px){
    .menu-section-inner{        max-height: 530px;}
}
@media (min-width: 1601px){
    .menu-section-inner{       max-height: 665px;}
}
@media (min-width: 1850px){
    .menu-section-inner{    max-height: 788px;}
}

.mbl-hiden{overflow-y: hidden}

.mobile-order-nowbtn a{
    padding: 16px 15px;
    background: #0271e5;
    font-size: 16px;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    display: block;
    color: #fff;
    letter-spacing: 0.5px;
        font-family: 'Poppins', sans-serif;
    font-weight: bold;
}
.mobile-order-nowbtn a:hover{ background: #fff;color:#0271e5 }
.mobile-login{display: none}
.mobile-login ul {
    padding: 0;
    list-style: none;
    position: absolute;
    top: 110%;
    left: 0;
    background: #000;
    border-radius: 6px;
    min-width: 150px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    z-index: 5;
    
}
.mobile-login ul.active{
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

.mobile-login {
    position: relative;
}

.mobile-login ul li a {
    display: block;
    line-height: 1;
    padding: 10px 20px;
    color: #fff;
    border-bottom: 1px solid rgba(255,255,255,0.22);
}
a.controller-nav img {
    width: 32px;
}

.mobile-menu {
    position: fixed;
    padding: 0;
    overflow: hidden;
    background-color: #ec6851;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    width: 100vw;
    z-index: 9999;
    max-width: 520px
}

.mobile-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 30px
}

.mobile-menu .menu li {
    list-style: none;
    position: relative
}

.mobile-menu .mobile-menu-body {
    overflow: hidden;
    overflow-y: auto;
    background:#fff;
    padding: 50px 40px 40px;
    height: 100%
}

.mobile-menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mobile-menu.active .menu a {
    -webkit-animation: a .4s .2s backwards;
    animation: a .4s .2s backwards
}

.mobile-menu.active .menu li:nth-of-type(2) a {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.mobile-menu.active .menu li:nth-of-type(3) a {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.mobile-menu.active .menu li:nth-of-type(4) a {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.mobile-menu.active .menu li:nth-of-type(5) a {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}
.mobile-menu.active .menu li:nth-of-type(6) a {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}
.mobile-menu.active .menu li:nth-of-type(7) a {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.mobile-menu .toggle-submenu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    display: block;
    height: 60px;
    cursor: pointer
}

.mobile-menu .toggle-submenu-button:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg)
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 1;
    content: ""
}

.mobile-menu .toggle-submenu-button:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn)
}


.mobile-menu .mobile-menu-close {

}

.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: block;
    width: 22px;
    height: 22px;
    position: relative
}

.mobile-menu .mobile-menu-close span:before {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg)
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
    background: #1b1b1b;
    display: block;
    height: 2px;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 24px;
    z-index: 2;
    content: ""
}

.mobile-menu .mobile-menu-close span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mobile-menu .mobile-menu-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    top: 0;
    right: 40px;
    left: 40px;
    height: 50px;
    background: #fff;
    z-index: 2;
    text-align: right;
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
}

.mobile-menu .menu a {
    display: block;
    font-size: 17px;
    color: #1b1b1b ;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    font-family: 'poppins';
    font-weight:bold;
    padding: 1.3rem 0;
    line-height: 1;
    text-transform: uppercase;
        letter-spacing: 1px;
}

.mobile-menu .menu a:hover {
    color: #ff8b27 ;
}

.mobile-menu .menu li.active a {
    color: #0271e5;
}

.mobile-menu .menu .sub-menu {
    display: none;
    padding: 0
}

.mobile-menu .menu .sub-menu li {
    padding-right: 0
}

.mobile-menu .menu .sub-menu li:last-child {
    border-bottom: 0
}

.mobile-menu .menu .sub-menu a {
    font-family: 'poppins';
}

.mobile-menu .menu li {
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
    padding-right: 40px
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}
.pkselect_tabsec .tab-content {
    background: #f9f7f8;
    padding: 0px 25px 20px;
}
.rest-list-cls { 
    margin-top: 20px;
}
.li-full-width {
    width: 100% !important;;
}
.btn-act-clss { 
    color: #fff;
    background: #000;
    border: 1px solid #769e90;
}

#delivery-continue-link, #takeaway-continue-link {
    display : none;
}

.takeaway-btn-part {
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.takeaway-btn-part .button {
    width: 48%;
    margin: 0;
    display: block;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'poppins';
    padding: 20px 10px;
    border-radius: 5px;
}
.takeaway-btn-part .btn {
    width: 48%;
    margin: 0;
    display: block;
    box-shadow: 0 7px 9px 0 rgb(0 0 0 / 21%);
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'poppins';
    padding: 15px 10px;
    border-radius: 5px;
}
.takeaway-btn-part .btn{
    background-color:#fff;
    color:#000
}
.takeaway-btn-part .btn:hover{
    background-color:#a81774;
    color:#fff
}

.self_pop_locbx {
    margin: 0 0 20px;
}
.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}
.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height: 500px;
}
.self_outlet_inner li {
    margin-bottom: 12px;
}
.self_outlet .self_outlet_inner>li>span {
    display: flex;
    background: #fff;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0 5px 13px 0 rgb(0 0 0 / 3%);
    text-align: left;
    overflow: hidden;
    color: #000;
    justify-content: space-between;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    cursor: pointer;
}
.outlet-list-lhs {
    width: 130px;
}
.self_outlet .self_outlet_inner>li.active .otulet-waiting span:before, .self_outlet .self_outlet_inner>li:hover .otulet-waiting span:before {
    background: url(../images/clock-black.png) no-repeat;
    background-size: contain;
}
.self_outlet .self_outlet_inner>li.active .otulet-distance span:before, .self_outlet .self_outlet_inner>li:hover .otulet-distance span:before {
    background: url(../images/placeholder-black.png) no-repeat;
    background-size: contain;
}
.self_outlet .self_outlet_inner>li.active .otulet-direction a, .self_outlet .self_outlet_inner>li:hover .otulet-direction a {
    color: #fff;
    border-color: #fff;
}

.otulet-direction a {
    border-bottom: 1px solid #8c8c8c;
}
.outlet-list-rhs ul li.otulet-waiting span:before {
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url(../images/clock-black.png) no-repeat;
}

.outlet-list-lhs {
    width: 130px;
}
.outlet-list-lhs>img {
    width: 130px;
    max-height: 85px;
    object-fit: cover;
}
.outlet-list-rhs {
    color: #181818;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: calc(100% - 145px);
}
.outlet-list-name {
    font-size: 14px;
    text-transform: inherit;
    font-weight: 600;
    color: #181818;
    font-family: 'poppins';
    margin: 0 0 5px;
}
.outlet-list-address {
    font-size: 13px;
    font-family: 'poppins';
    margin-bottom: 5px;
}
.outlet-list-rhs ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}
.outlet-list-rhs ul li {
    margin: 5px 15px 0 0;
    font-size: 13px;
}
.outlet-list-rhs ul li.otulet-waiting span {
    position: relative;
    padding: 0 0 0 24px;
}
.outlet-list-rhs ul li.otulet-distance span {
    position: relative;
    padding: 0 0 0 24px;
}
.outlet-list-rhs ul li.otulet-distance span:before {
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url(../images/placeholder-black.png) no-repeat;
}
.outlet-list-rhs ul li.otulet-direction span {
    position: relative;
    padding: 0 0 0 25px;
}
.fbold span{
    font-weight:bold;
}
.outlet-list-rhs ul li.otulet-direction span:before {
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url(../images/compass-black.png) no-repeat;
    background-size: contain;
}
.otulet-direction a {
    border-bottom: 1px solid #8c8c8c;
}
.outlet-list-rhs ul li:last-child {
    margin: 5px 0 0 30px;
}
.outlet-list-rhs ul li {
    margin: 5px 15px 0 0;
    font-size: 13px;
}

/* omise */
.omise-form-error input {
    -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    border-color: #ff7076;
    border-top-color: #ff5c61;
}

.pay-omiseconf-popup { max-width: 340px; border-radius: 10px;background: #f5f5f7}
span.omise-pay-img {
    display: block;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: -39px;
    left: 0;
    right: 0;
}

img.makisan-logo-img {
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: none;
}

.omise_pay_header {
    position: relative;
    padding: 50px 30px 25px;
    padding: 60px 30px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    border-bottom: 1px solid #d2d2d3;
    font-size:20px;
    background: #E8E9EB;
}
.omise_pay_body {
    padding: 30px;
    position: relative;
        border-top: 2px solid #fff;
}
.omisepop_in .form-control{ padding: 10px; height: 40px; font-size: 14px;border-radius: 4px; padding-left: 36px}
.expire_row_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.expire_row_inner > div {
    width: 32%;
    position: relative;
}
.omisepop_in .alt_btns .button {margin-bottom: 0;
    width: 100%;
    padding: 13px 10px 11px;
    border-radius: 4px;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 2px #00000057;
}
.omisepop_in .form_sec i{ position: absolute; left: 10px; color: #888;top:12px;  }
.omisepop_in .form_sec > div{ position: relative }
.omisepop_in .form_sec .uname-box  i{ left: 13px;}
.omisepop_in .form_sec .card-numbr-div i, .omisepop_in .form_sec .expire_left i {font-size: 16px;top:13px;}
.omisepop_in .form_sec .expire_right  i{ font-size: 19px; top: 12px;}
.expire_mdl .form-control{ padding-left: 15px }
.card {
    position: absolute;
    display: block;
    right: 10px;
    width: 28px;
    height: 19px;
    background: url(../images/cards/spritecard.png) no-repeat 0;
    top: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    background-position: 0 0;
    overflow: hidden;
}
.visa + .card {
    background-position: 0 -19px;
}
.master+ .card {
    background-position: 0 -38px;
}
.discover + .card {
    background-position: 0 -57px;
}
.american + .card {
    background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
    padding: 0;
    width: 21px;
    height: 21px;
    display: block;
    color: #fff !important;
    border-radius: 50%;
    line-height: 21px;
    background: #a5a5a5;
    border: 1px solid #929292;
    top: 10px;
    font-size: 20px;
    right: 10px;
    text-align: center;
}
.pay-omiseconf-popup button.mfp-close:hover{ background: #000; border-color: #000; }
.omisepay-mode-option {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #ffd025;
    line-height: 1;
    padding:9px 11px 8px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}
.omise-error-info-div {
    color: #ffffff;
    background-color: #b12f2f;
    border-color: #ebccd1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
}
.omise-error-icon { font-size: 14px; }
.omise_alert_close {
    position: absolute;
    right: 0px;
    color: #FFF;
    top: 0;
}
.omise-error-msg {
    margin: 0 0 0 11px;
    font-size: 14px;
}
.omise-error-info-div .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}
.displayDivCls { display: none; }

.instructions li{
    text-align: initial;
}
.paynow-notes h3{
    font-size: 40px;
}


.pay-omisepaynowconf-popup  .mfp-close{
    color: #fff!important;
    background: #c1c1c1;
    border-radius: 25px;
    top: 10px;
    right: 10px;
    line-height: 20px;
    height: 20px;
    position: absolute;
    width: 20px;
    font-size: 21px;
}
.pay-omisepaynowconf-popup  .mfp-close:hover{
    color: #fff!important;
    background: #000;
}

.omise-pay-now-notes {
    text-align:center;
    font-family:'poppins';
    font-size: 16px;
}
.omisepop_in {
    text-align:center;
}
.device-notes {
    text-align:left
}
.omise-paynow-amount-to-paid{
    font-size:20px
}

.device-mobile p{
    font-family:'poppins';
    font-size: 14px;
}
.device-desktop ul li{
    font-family:'poppins';
    font-size: 14px;
}

.pay-omisepaynowconf-popup .omise_pay_header .mfp-close-btn-in .mfp-close{
    color: #000;
}

.pay-omisepaynowconf-popup{
    max-width: 475px!important;
    border-radius: 10px;
}

.omisepop_in .alt_btns .button:hover{
    background: #000;
}

.would-like-bottom label.chk_hea {
    margin: 0;
    padding: 0 0 0 35px;
    font-size: 15px;
    text-transform: uppercase;
}

.would-like-bottom .custom_checkbox {
    left: 0;
    right: inherit;
    top: -4px;
}

.would-like-bottom {
    margin: 15px 0 10px;
}
.cart_row.promo-cart-row {
    position: relative;
    padding-right: 22px;
}
.contact_form {
    padding-top: 10px;
}
.contact_form h3 {
    font-size: 30px;
    position: relative;
    z-index: 0;
    font-family: 'BrandonGrotesque-Regular';
}
.product_sidebar_top{display: none}
.product-rhs .datetime-wrap .react-datepicker-wrapper{display: block}
.product-lhs-inner{ box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14); padding: 10px 0 0 0; }
.catering-product-list .product-lhs{ box-shadow: none;  padding: 0;}
.products_sidebar_inner ul{margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap}
.products_sidebar_inner ul li{ width: 24.6%;min-height: 56px;margin: 0 0.5% 5px 0;}
.products_sidebar_inner ul li a, .slick-slider .products_sidebar_content a{ color: #9d9d9d;border: 2px solid #999999; padding: 5px; text-align: center; display: flex; justify-content: center; align-items: center; color: #9d9d9d;  text-transform: uppercase;font-family: 'poppins'; font-weight:bold; transition: 0.3s linear all; height: 100%;border-radius: 5px; }
.products_sidebar_inner ul li:nth-child(4n){ margin-right: 0 }
.products_sidebar_inner ul li:hover a, .products_sidebar_inner ul li.active a, .slick-slider .products_sidebar_content a:hover, .slick-slider .products_sidebar_content.active a{ border: 2px solid #fbaa1a; color: #fff; background: #fbaa1a }
.product-lhs-inner .products_list{ padding: 22px 18px 20px 18px;  }
.products_sidebar_inner {
    margin-bottom: 15px;
}
.products_list_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.products_list_inner{width: 49%;}
.products_list_desc{width: 49%;}
body .accordion{border: 0; border-radius: 0 }
.product_acc_grp .accordion .accordion_title{ background: #282828; color: #fff; font-family: 'poppins'; font-weight:bold;padding: 12px 35px 10px 12px;  line-height: 1; text-transform: capitalize;cursor: pointer;position: relative;    display: flex;  justify-content: space-between;}
.product_acc_grp .accordion{margin: 0 0 10px 0}
.product_acc_grp .accordion .accordion_title span{ color: #ece223;font-family: 'Open Sans', sans-serif;font-size: 90%;}
.product_acc_grp .accordion .accordion_title::before, .product_acc_grp .accordion .accordion_title::after {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right:10px;
    top: 50%;
    transition: transform 0.25s ease-in-out 0s;
    -webkit-transition: transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}
.product_acc_grp .accordion .accordion_title::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.product_acc_grp .accordion .accordion_title::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.product_acc_grp .accordion .accordion_title.active::before{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.product_acc_grp .accordion .accordion_title.active::after{
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

a.button.add_to_cart {
    width: 100%;
}
.mobile-footer-nav{display: none}
.mobile-footer-nav .accordion {
    border: 0;
    border-radius: 0;
}
.mobile-footer-nav .accordion button.title {
    margin: 0;
    border: 0;
    box-shadow: none;
    background: #efefef;
    border-radius: 0 !important;
        font-size: 17px;
}
.mobile-footer-nav .accordion .accordion-item .title:after {
    content: "\f107";
    font-size: 25px;
    color: #000;
    margin-left: 0px;
    font-family: 'FontAwesome';
    position: absolute;
    right: 20px;
    top: 12px;
    transition: 0.3s linear all;
    transform: rotate(0deg);
}

.mobile-footer-nav .accordion .accordion-item.active .title:after{ content: "\f107";transform: rotate(180deg);  }
.mobile-footer-nav .accordion .accordion-item .panel {
    background-color: #fff;
    border:0;
    border-radius: 0px;
    box-shadow:none;
}
.mobile-footer-nav .accordion .accordion-item.active .panel {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
    border-left: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    padding: 20px;
}
.mobile-footer-nav .accordion .accordion-item .panel ul{ padding:0; list-style: none;}
.mobile-footer-nav ul li a {
    color: #464646;
    font-size: 16px;
    display: block;
    font-family: 'poppins';
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-bottom: 3px;
}
.menu-section-mobile-inner .slick-track, .mobile-menu-section-left .slick-track{
    border-bottom: 4px solid #e7e6e6;
}
.menu-section-mobile-inner .slick-track .slick-slide > div, .mobile-menu-section-left .slick-track .slick-slide > div{display: block; width: 100%;}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{
    padding: 5px 30px 5px 32px;
    position: relative;
    cursor: pointer;
    color: #c3c1c1;
    font-family: 'poppins';
    font-weight: bold;
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal;
    display: block;
    height: 100%;text-align: center}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #0271e5;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link:before{    left: 0; right: 0;}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link{color: #181818}
.menu-section-mobile-inner, .mobile-menu-section-left{display: none}
/* header.small_sticky {
    box-shadow: 0 2px 16px 0 rgba(0,0,0,.08);
} */
.slick-slide .menu-list-intvl, .products_sidebar_inner .slick-slide {
    height: 100%;
}
.products_sidebar_inner .slick-track .slick-slide > div {
    display: block;
    width: 100%;
}
.menu-section-mobile-inner button.slick-arrow, .products_sidebar_inner button.slick-arrow, .mobile-menu-section-left button.slick-arrow{
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    background:#000;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s linear all;
}
.menu-section-mobile-inner .slick-prev, .products_sidebar_inner .slick-prev, .mobile-menu-section-left .slick-prev{left: 0}
.menu-section-mobile-inner .slick-next, .products_sidebar_inner .slick-next, .mobile-menu-section-left .slick-next{right: 0}
.menu-section-mobile-inner button.slick-arrow:before, .products_sidebar_inner button.slick-arrow:before, .mobile-menu-section-left button.slick-arrow:before{ position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px; background: url(../images/left-arrow.png) no-repeat; content: "";
    margin-left: 6px;}
.menu-section-mobile-inner button.slick-arrow.slick-next:before, .products_sidebar_inner button.slick-arrow.slick-next:before, .mobile-menu-section-left button.slick-arrow.slick-next:before{
    transform: rotate(180deg);
    margin-top: -10px;
}
.detail-pg-slider button.slick-arrow, .products_sidebar_inner button.slick-arrow, .mobile-menu-section-left button.slick-arrow{
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    background:#000;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 53%;
    transform: translateY(-50%);
    transition: 0.3s linear all;
}
.detail-pg-slider .slick-prev, .products_sidebar_inner .slick-prev, .mobile-menu-section-left .slick-prev{left: 0}
.detail-pg-slider .slick-next, .products_sidebar_inner .slick-next, .mobile-menu-section-left .slick-next{right: 0}
.detail-pg-slider button.slick-arrow:before, .products_sidebar_inner button.slick-arrow:before, .mobile-menu-section-left button.slick-arrow:before{ position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px; background: url(../images/left-arrow.png) no-repeat; content: "";
    margin-left: 6px;}
.detail-pg-slider button.slick-arrow.slick-next:before, .products_sidebar_inner button.slick-arrow.slick-next:before, .mobile-menu-section-left button.slick-arrow.slick-next:before{
    transform: rotate(180deg);
    margin-top: -10px;
}
.detail-pg-slider .slick-next, .products_sidebar_inner .slick-next, .mobile-menu-section-left .slick-next{
    right: 0;
}
.hcart_dropdown {
    background: #fff;
    right: 0;
    width: 720px;
    position: fixed;
    height: 100vh;
    right: 0;
    /*overflow: scroll;*/
    top: 0px;
    transform: translateX(100%);
    transition: transform .3s ease-in-out;
    z-index: 11;
    text-align: left;
}

.hcart_dropdown.open {
    transform: translateX(0);
}
body.cart-items-open {
    overflow: hidden;
}

.cart_left { width: calc( 100% - 140px );    display: flex;}

.hcart_scrollarea .cart_body .cart_right {
    width: 120px;
}

.cart_right{ width: 98px;}
.cart_img {
    /*width: 120px;*/
    max-width: 120px;
    margin: 0 13px 5px 0;
}
.hcart_tt {
    padding: 20px 10px 20px 15px;
    z-index:21!important;
    background: #ff9f28;
    text-align: center;
}

.hcart_tt h3 {
    color: #fff;
    font-weight: 500;
    font-family: 'Poppins';
    margin: 0 auto;
    font-size: 18px;
}

span.close_product_orders {
    line-height: 1;
}
header .hcart_dropdown .cart_footer { 
        padding:10px 20px; 
    margin: 0px 25px 0px 25px;
    background: #f5f4f4; 
}
.product_card_desc {
    text-align: center;
    padding: 10px;
    font-style: italic;
}
.product_card_desc p{margin-bottom: 5px;}
.package_checkitem{text-align: left;font-size: 14px;}
.package_checkitem .custom_checkbox{margin: 0 0 10px 0;display: block}
.package_checkitem .custom_checkbox span{ padding: 0 50px 0 0;  line-height: 1.2; font-size: 14px; display: block }
.package_checkitem .custom_checkbox span:before{right: 0; left: inherit;    border-radius: 5px;}
.package_checkitem .custom_checkbox input[type=checkbox]:checked + span:before{ background: url(../images/blue-tick.png) no-repeat; }
.package_checkitem .custom_checkbox span p{margin: 5px 0 0 0;}
.package_checkitem .custom_checkbox span img { position: absolute; right: 30px;}
.input-file-sec{ position: relative;margin: 10px 0 0 0}
.input-file-sec #upload-file {position: absolute; opacity: 0; width: 100%; height: 100%; right: 0; cursor: pointer;}
.file-name {display: inline-block; vertical-align: middle; box-shadow: none; border-radius: 4px; margin: 0; margin-right: 10px; }
.input-file-sec .btn {pointer-events: none;padding: 9px 20px; font-size: 14px; }
.product_det_cards .det_inner_content i {font-family: 'avantgarde_bk_btdemi_oblique'; font-style: normal;}
.show_details > label.combo-input{ display: none;}
.det_card .pkgerror_txt {
    color: #f90000;
    font-size: 13px;
    margin: 0 0 5px 0;
}
.pkgerror_txt {
    color: #f90000;
    font-size: 13px;
    margin: 0 0 5px 0;
}

.package_details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 22px;
}

.package_details_left {
    width: 48%;
}

.package_details_right {
    width: 50%;
}
.package_details_left .slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex !important;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    justify-content: center;
}
.package_details_left .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 11px;
    height: 11px;
    color: transparent;
    border: 0;
    outline: none;
    padding: 0;
    margin: 0 3px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #302d2a;
}
.package_details_left .slick-dots li.slick-active button{ background: #fff }
.package_details_left .slick-slide img{ max-height: 240px; width: 100%; object-fit: cover; }
.pdr_right {
    text-align: center;
    width: 150px;
    
}
.pdr_right-inner{ display: flex; flex-flow: column wrap; align-items: center; justify-content: center; }

.package_details_right_inner {
    display: flex;
    justify-content: space-between;
}

.pdr_left {
    width: calc(100% - 160px);
    font-size: 14px;
}
.pdr_left h4{    margin-bottom: 15px;
    font-size: 17px;
    text-transform: capitalize;}

.pdr_right .qty_bx{width: 120px; height: 38px;    padding: 0 30px;}
.pdr_right .product-min-pax{font-size: 13px; margin: 8px 0 12px}
.pdr_right h5{
    font-size: 15px;
    margin-bottom: 10px;
}
.pdr_right .proqty_input{
    height: 100%;
    line-height: 36px;
    font-size: 17px;
    border-right: 2px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 2px solid #e0e0e0;
}
.pdr_right .qty_bx span{width: 30px}
.pdr_right h4{margin-bottom: 15px;}
.pdr_right h4 sup{top: -4px; margin-right: 2px;font-size: 90%;}
.fix-only-lhs{ display: none}
.fix-only-lhs h3{
    margin-bottom: 15px;
    font-size: 17px;
    text-transform: capitalize;
}
.pdr_right.price-fixed{ position: fixed;/* right: 0;*/background: #fff; padding: 10px; z-index: 9; top: 80px;    box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07); width: auto;display: flex; justify-content: space-between;     border: 3px solid #49947c; border-top: 0;margin: 0} 
.pdr_right.price-fixed .fix-only-lhs{display: block; padding-right: 30px}
.pdr_right.price-fixed .flavours, .pdr_right.price-fixed h5{ display: none}
.pdr_right.price-fixed .product-min-pax {font-size: 12px; margin: 2px 0 0px;}
.pdr_right.price-fixed h4 {margin-bottom: 5px;font-size: 20px}
.pdr_right.price-fixed .qty_bx { width: 110px; height: 30px;}
.pdr_right.price-fixed .proqty_input { line-height: 26px;font-size: 15px;}



.additionals {
    padding-top: 10px;
}
.instruction h5 {
    margin-bottom: 10px;
    text-transform: inherit;
}
.product_details_popup a.button.add_to_cart {
    font-size: 20px;
    margin: 10px 0 0 0;
    padding: 15px 24px 15px;
    letter-spacing: 0.5px;
}

.det_inner_content .show_details{ background: #282828; margin: 0 auto;min-width: 128px;    padding: 12px 6px 12px;}
.det_inner_content .prd_close{     color: #49947c;font-family: 'CenturyGothic-Bold'; text-transform: uppercase;}
.det_inner_content .prd_close  span{text-decoration: underline;}
.det_inner_content .product_desc{
    color: #353535;
    font-size: 14px;
    margin: 0 0 20px 0;
    line-height: 1.3;
    flex: 1 1 auto;
}
.det_inner{height: 100%;}
.modifier-btn-grp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.modifier-btn-grp .button {
    width: 49%;
    padding: 12px 5px;
    font-size: 14px;
    margin: 0 0 5px 0;
}
.modifier-btn-grp .mdf-done.button {background: #769e90}
.custom_logo_banner textarea.form-control {
    height: 80px;
    border-radius: 5px;
    margin-bottom: 0px;
    padding: 10px;
    font-size: 13px;
}
.custom_logo_banner .input_desc{font-size: 12px}
.event_timing .tooltip_link {
    width: 21px;
    height: 19px;
}
.order_card{ border: 1px solid #e4e4e4; border-radius: 5px; overflow: hidden; margin-bottom: 10px; }
.event_timing {
    border-bottom: 1px solid #e4e4e4;
    padding: 15px 15px 10px 15px;
}
.event_timing .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.event_timing .form-group .input_field {
    width: calc( 100% - 32px );
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.event_timing .form-control{ margin-bottom: 0; height: 40px; border-radius: 4px; padding: 6px 12px;}
.event_timing .form-control.required{ border-color:  #e4000d }
.event_timing .custom_select:after{    width: 30px; height: 38px;border-top-right-radius: 5px; border-bottom-right-radius: 5px}
.event_timing .form-group .input_field .custom_select {
    width: calc( 100% - 110px );
}
.event_timing label{ color: #353535; }
.det_inner_image img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.package_pdfico a {
    color: #49947c;
    font-family: 'CenturyGothic-Bold';
    text-transform: uppercase;
}

.package_pdfico a i {
    margin: 0 5px 0 0;
    font-size: 16px;
}
.file_details {
    font-size: 13px;
    margin: 5px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file_details a {
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-left: 5px;
}
.file_details a img{width: 10px}
.products_cart {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
}
.products_cart img{ margin-bottom: 10px }
.order_card.light{ background: #f9f9f9;  }
.order_card.light .order_card_head{ background: #dcdcdc;  }
.order_card.light .order_card_head h5 { color: #464646;}
.order_tooltip {
    padding: 20px 12px 20px;
    width: 230px;
    letter-spacing: 0.3px;
}
.order_tooltip p:first-of-type{ font-family: 'CenturyGothic-Bold'; text-transform: uppercase; }
.det_inner_content{ border: 2px solid #fff; border-top: 0; }
.selected .det_inner_content{ border-color: #fbaa1a }
.downalod-quote {
    text-align: center;
    padding: 15px 0 0 0;
}
.downalod-quote a{ font-family: 'CenturyGothic-Bold'; text-decoration: underline; color: #464646;  text-transform: uppercase; }
.products_info_item .cart_left h4 {
    font-size: 14px;
    margin: 0 0 2px;
    color: #161616;
    position: relative;
    padding-left: 20px;
}
.receipt_cateringpopup .pouup_in .products_info_item .cart_left h4{ padding-left: 0 }
.receipt_cateringpopup .pouup_in .cart_indvl_price{ display: block; clear: both }
.receipt_cateringpopup .pouup_in .pkg-list-lhs > div{ padding-right: 0 }

.products_info_item .cart_left h4 a{
    position: absolute;
    left: 0;
    top: 0px;
    color: #161616;
    font-size: 15px;
}
.products_info_options p{margin: 0 0 2px 0;
    color: #959595;
    font-size: 13px;
    padding-left: 10px; font-style: italic}
.products_info_options span b {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    display: block;
    padding: 10px 0 0 0;
    font-size: 13px;
}
.product-details.products_info_item{ padding-top: 15px; }
.cart_indvl_price{ margin: 0; color: #161616; font-size: 16px; font-family: 'CenturyGothic-Bold';}
.cart_indvl_price sup{top: 0;}
.cart_right .cart-counter .product_quantity {
    height: 100%;
    line-height: 27px;
    border-right: 2px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 2px solid #e0e0e0;
        font-size: 13px; font-family: 'CenturyGothic'; font-weight: normal;text-align: center;
    background: #fff;
}
.cartaction_checkout{ 
    margin: 0;
    width: 100%;
    text-align: center;
    display: block;
    padding: 14px 24px;
    font-weight: 500;
    background:#0271e5;
    color: #fff;
    border: 0px; 
    border-radius: 0;
    height: auto;     
}
.cartaction_checkout:hover{ background: #000; color: #fff; }

.hcart_dropdown .hcart_scrollarea_maindiv {
    position: relative;
    height: 100%;
}

.products_total sup{top: -1px;margin-right: 2px;}
.add-breaktime {
    font-family: 'CenturyGothic-Bold';
    text-decoration: underline;
    color: #464646;
    text-transform: capitalize;
    font-size: 14px;
    display: block;
    margin: 0 auto;
    text-align: center
}
.del_address h5{
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}
.product_container{ font-family: 'Open Sans', sans-serif;}
.additionals ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.cart-settup-list, .products_info_item{ padding: 5px 15px }
.cart-settup-list ul{list-style: none; padding: 0;display: block;}
.cart-settup-list ul li {
    padding: 5px 0px 10px 0;
}
.cart-settup-list ul li:last-child{padding-bottom: 10px}
.pkgslist_addition{ font-size: 13px; color: #959595;}
.pkgslist_addition p{ font-size: 13px; color: #959595; margin: 0 0 1px 0; }
.pkgslist_addition p:last-of-type{margin-bottom: 0}

.pkg-list-lhs {
    display: flex;
    justify-content: space-between;
}
.pkg-list-lhs label {
    color: #161616;
    font-family: 'CenturyGothic-Bold';
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1;
}
.pkg-list-lhs > div{ position: relative;padding-right: 20px; line-height: 1;}
.cart-package-info:nth-child(even){ background: #f8f8f8; border-top: 1px solid #e4e4e4; }
.pkg-list-lhs > div a.remove{ top: 1px; right: 0;}
.pkg-list-lhs > div .price sup{ top: -1px; }
.pkg-list-lhs > div .price{ color: #161616; font-family: 'CenturyGothic-Bold';}
.instruction .spl_instruction{ height: 100px; border-radius: 5px; }
.additionals h5{ text-transform: capitalize; }
.product_acc_grp {
    margin-bottom: 30px;
}
ul.additionals-deliverylist {
    padding: 15px 0 10px 30px;
}
.additionals_setuplist > li{ padding-bottom: 15px;    }
.additionals_setuplist > li .custom_radio span{     font-weight: 600; } 
ul.additionals-deliverylist li{ margin-bottom: 15px; }
.addon_checklist li{ margin-bottom: 10px; }
ul.additionals-deliverylist li:last-child, .addon_checklist li:last-child{margin-bottom: 0}
ul.additionals-deliverylist.equipment_listing_div{ padding: 0px 0 10px 0px;}
ul.additionals-deliverylist li .custom_checkbox span{ line-height: 1.4; }
ul.additionals-deliverylist li .custom_checkbox span:before, .addon_checklist li .custom_checkbox span:before{ border-radius: 3px; width: 16px; height: 16px; top: 4px; }
ul.additionals-deliverylist li .custom_checkbox input[type=checkbox]:checked + span:before, .addon_checklist li .custom_checkbox input[type=checkbox]:checked + span:before{ background: url(../images/blue-tick.png) no-repeat;}
.addon_popup-catering{ max-width: 780px; }
.addon_popup-catering .addon_linfo h4 {
    text-transform: capitalize;
    font-size: 17px;
    margin-bottom: 10px;
}
.addon_poptop_header h3{ font-size:18px; }
.additionals_equipmentlist .qty_val, .addon-lright-parent .addon_qty_lable, .combo_qtycount .qty_val {
    height: 100%;
    line-height: 28px;
    font-size: 14px;
    border-right: 1px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 1px solid #e0e0e0;text-align: center
}
.additionals_equipmentlist .counter{margin-bottom: 5px}
ul.additionals_equipmentlist li{ margin-bottom: 20px}
.addon_poptop_header h3 small {
    font-size: 80%;
    text-transform: capitalize;
    margin-left: 10px;
    font-family: 'CenturyGothic';
    color: #4e4e4e;
}
.addon_poptop_header h3 small {
    font-size: 80%;
    text-transform: capitalize;
    margin-left: 10px;
    font-family: 'CenturyGothic';
    color: #4e4e4e;
}

.addon_listrw {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px
}

.addon_linfo {
    width: calc( 100% - 160px );
}

.addon-lright-parent {
    width: 150px;
}
.addon_price{  font-family: 'CenturyGothic-Bold'; font-size: 16px;}
.addon-lright-parent .btn {    padding: 10px 12px; line-height: 1; font-size: 14px;margin: 5px 0 0 0; background: #282828}
.addon-lright-parent .btn:hover{ color: #fff; background: #f6aa32 }
.addon_lright { display: flex; flex-flow: column wrap; align-items: flex-end;}
.addon_popbot {
    text-align: right;
}

.addon_popbot .button {
    margin: 10px 0 0 10px;
}
.act-remove-topdiv .delete-act-icon{ color: #000; }
.address_linfo {
    margin-bottom: 10px;
}
.catering-checkout-total .order_det{ padding: 0 15px; }
.catering-checkout-total .cart_footer{  padding: 10px 15px 0 15px; }
.btn_big {
    display: block;
    padding: 12px 10px;
    font-size: 16px;
}
.cdd-details-rhs .event_timing .form-group .input_field .custom_select{ width: calc( 100% - 220px );}
.cdd-details-rhs .products_info_item .cart_left h4{padding-left: 0}
.cdd-details-rhs .event_timing{padding-bottom: 5px; border-bottom: 0}
.catering-checkout-total .cdd-details-rhs{font-family: 'Open Sans', sans-serif;}
.no-recrds-found.loader-main-cls{ min-height: 250px; }
.modal.fade{opacity: 1;}
.no-recrds-found {
    font-size:16px;
    font-family: 'BrandonGrotesque-Regular';
    
}
.hcart_dropdown .pkgslist_additionrow {
    margin-bottom: 15px;
}
.hcart_dropdown .pkgslist_additionrow:last-child{margin-bottom: 0}
.hcart_dropdown .cart-settup-list ul li:last-child{padding-bottom: 5px}
.hcart_dropdown .cart-settup-list ul li label{     color: #161616;
    font-family: 'CenturyGothic-Bold';
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1;}

.popup_product_details{ width: 100%; max-width: 1080px; }
.modal-backdrop.show {
    opacity: .5;
}
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}
select option:disabled{ color: rgba(0,0,0,0.4) }
.cdd-details-rhs .pkgslist_additionrow{padding-bottom: 10px} 
.cdd-details-rhs .pkgslist_additionrow:last-child{padding-bottom: 0}
.cart-settup-list ul li > label{ padding-bottom: 5px;    color: #161616;
    font-family: 'CenturyGothic-Bold';
    text-transform: uppercase; }
.slick-slider .products_sidebar_content a{     padding: 10px 15px; }
.products_sidebar_inner .slick-slider{ display: none}
.additionals .instruction {
    padding-top: 20px;
}
.product-edit-half{display: flex; flex-wrap: wrap; justify-content: space-between}


.pop-whole-lhs-inner {
    text-align: center;
    padding-bottom: 20px;
}

.pop-whole {
    position: relative
}

.login-popup .resend_link {
    padding: 10px 0px;
    text-align: right;
}

.login-popup .resend_link a {
    color: #0dad85;
    font-weight: 600;
}

.popup-footer .btn {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
}

.popup-footer .btn:hover {
    background: #333;
    color: #fff;
}

.popup-footer .btn i {
    padding-right: 15px;
}

.popup-footer span {
    color: #000;
    font-size: 16px;
    margin: 0 0 3px;
}

.popup-footer .guest_btn {
    background: #70afa7;
    color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
    background: #333333;
    color: #fff;
}

.pop-whole:after {
    display: table;
    clear: both;
    content: ""
}

.inside-popup-rhs {
    float: right;
    background: #fff;
    padding: 45px 35px 0px 35px;
    position: relative;
    z-index: 9;
    border: 1px solid #0271e5;
    /*margin: 0 35px 0 0;*/
    width: 100%;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
    border-radius: 20px;
}

.activities-popup-main .mfp-close {
    color: #fff;
    background: #000;
    opacity: 1;
    border-radius: 0;
}

.activities-popup-main .mfp-close:hover {
    color: #fff;
    background: #000;
}

.activities-popup-main{
    max-width: 900px!important;
}

.frgt-head h3{
    text-align: center;
    font-family: 'poppins';
    font-size: 45px;
    text-transform: capitalize;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #000;
    -webkit-text-stroke: 1px #fff;
    text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff;
}

.frgt-head p{    
    color: #000;
    margin-bottom: 10px;
    font-family: 'poppins';
}

.newsletter-popup-rhs {
    float: right;
    background: #fff;
    padding: 45px 35px 0px 35px;
    position: relative;
    z-index: 9;
    border: 1px solid rgb(232 90 0 / 90%);
    margin: 0 35px 0 0;
    width: 100%;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.inside-dine-popup-rhs {
    /*float: right;*/
    background: #000;
    padding: 5px 45px 0px 45px;
    position: relative;
    z-index: 9;
    border: 1px solid rgb(232 90 0 / 90%);
    margin: 0 auto;
    /*width: 80%;*/
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}
.dine-input input{
    width:55%;
    display:flex;
    margin: 0 auto;
}

.ta-box-inner{
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #fff;
}

/*.ta-box-inner:hover{
    background: #000;
}*/

.ta-header h5 {
    color:#000;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
}
.ta-header p {
    color:#000;
    font-family: 'Poppins';
    font-size: 14px;
    margin-bottom: 10px;
}
.tkaw-icons span{
    color:#000;
    padding: 0px 10px 0px 0px;
    font-size:14px;
}
.clr-blk {
    color: #000;
}

.f-right{
    float:right;
}
.f-bold{
    font-weight: bold;
}

.m-row{
    margin-left: -15px;
    margin-right: -15px;
}
.mb-5{
    margin-bottom:5px;
}
.mb-20{
    margin-bottom:20px;
}
.br-5{
    border-radius: 5px;
}
.signup-popup .inside-popup-rhs,
.signup-corparateone-popup .inside-popup-rhs,
.signup-corparatetwo-popup .inside-popup-rhs, .forgot-password-popup .inside-popup-rhs{
    padding: 45px 30px 30px 30px;
}

.signup-corparatetwo-popup .inside-popup-rhs,
.guest-popup .inside-popup-rhs {
    padding: 60px 35px 40px 35spx;
}

.pop-whole-lhs {
    position: absolute;
    background: #000000;
    padding: 30px 35px 30px 35px;
    left: 0;
    right: 0;
    z-index: 2;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.forgot-password-popup .pop-whole-lhs {
    padding: 33px 50px 33px 35px;
}

.forgot-password-popup .pop-whole-lhs-inner {
    text-align: center;
    
}
.signup-popup .pop-whole-lhs, .guest-popup .pop-whole-lhs{  padding: 60px 30px 60px 20px;}
.signup-popup .pop-whole-lhs-inner, .guest-popup .pop-whole-lhs-inner { }

.pop-whole-lhs h3 {
    font-size: 30px;
    margin: 0 0 5px 0;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}

#signup-popup .pop-whole-lhs h3{
    margin:5px 0;
}

.pop-whole-lhs p {
    font-size: 14px;
    color: #fff;
    margin: 0;
}

.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
    height: 20px;
    background-color: #000
}

.loginsep-popup .mfp-close {
    right: 20px;
    top: -6px
}
.login_submit, .signup_submit, .forgotpassword-cls{ background: #linear-gradient(135deg,  #f36b31 0%,#f2672e 9%,#f26730 14%,#f0642f 20%,#ed612c 22%,#ed5f2d 28%,#ed5f31 29%,#ea5c2e 31%,#e8532b 46%,#e44e29 48%,#e44e29 51%,#e64d2d 52%,#e64d2d 52%,#e44b2b 53%,#e44b2b 57%,#e2482c 57%,#e0462a 59%,#e0442b 66%,#dd4128 68%,#de422c 69%,#dc402a 71%,#de3f2c 75%,#d83926 85%,#da3728 86%,#d63226 100%)!important; display: block; margin-left: 0px !important; width: 100% }

.popup-header {
    padding: 0px 0 20px 0;
}

.popup-header h4 {
    font-size: 19px;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    text-transform: uppercase;
}

.popup-header h4 img {
    width: 200px;
    margin: 0 auto;
}

.popup-header p {
    font-size: 16px;
    margin: 0px;
}

.popup-body input {
    margin: 0 0 8px;
}

.two-links {
    padding: 15px 0 15px 0;
   text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
}

.two-links a {
    font-size: 14px;
    display: block;
    position: relative;
    color: #000;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    font-family: 'poppins';
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;padding-left: 3px;
    padding-right: 3px;
}

.two-links a:hover {
    color:#0271e5;     
}
.controls.single-link {
    /*padding-bottom: 20px;*/
}

.spilter {
    width: 2px;
    height: 20px;
    background: #565656;
    display: inline-block;
    content: "";
    vertical-align: top;
    margin: 1px 2px 0;
    position: relative;
    left: 0;
}

.or-seperator {
    position: relative;
    padding: 15px 0 7px 0;
    line-height: 1;
    margin: 0 auto;
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator span {
    padding: 10px 10px;
    background: #fff;
    font-family: 'Poppins';
    color: #000;
    font-size: 20px;
    display: inline-block;
    z-index: 1;
    position: relative
}

.or-seperator:after {
    height: 1px;
    width: 100%;
    content: "";
    display: block;
    background: #0e0e0e;
    position: absolute;
    top: 55%;
    left: 0
}

.login_pop_sub .btn {
    width: 100%;
    margin-left: 0px !important;
}

.guest_chk_out {
    margin-left: -55px;
    margin-right: -55px;
}

.guest_chk_out .btn {
    width: 100%;
    border: 0;
    margin-bottom: 0;
    color: #f1e0c6;
}

.guest_chk_out .btn b {
    color: #fff;
}

.guest_chk_out .btn:hover {
    color: #fff;
}

.popup-body h4 {
    text-transform: uppercase;
    margin: 0 0 11px 15px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    font-family: 'Open Sans';
    font-weight: bold;
}

.popup-body h4.your_lgn_spacing {
    margin-top: 19px;
}

.popup-body .custom_checkbox {
    width: 100%;
    margin: 7px 0 9px 0;
}

.popup-body .sign_reg {
    margin-left:28px;
    margin-top:3px;
}

.popup-body .sign_reg a {
    width: 100%;
    color: #595656;
}

.popup-body .sign_reg a:hover {
    background: #333;
    color: #fff;
}


.popup-body .custom_checkbox span:before {
    width: 21px;
    height: 21px;
    border: 2px solid #e4e4e4;
    top: 2px;
    border-radius: 0px;
}

.popup-body .custom_checkbox input[type=checkbox]:checked + span:before {
   background: url(../images/blue-tick.png) no-repeat;
}

.popup-body .sign_reg p {
    margin-bottom: 9px;
    color: #000;
    margin-top: 1px;
    font-family: 'poppins';
    line-height: 18px;
    font-weight: 400;
    font-size: 15px;
}



.popup-body .sign_reg a.sign_reg_uline {
    text-decoration: underline;
    color: #000;
}

.popup-body .sign_reg a.sign_reg_uline:hover {
    color: #0271e5;
    background: inherit;
}

.row.pop-two-form {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 20px;
}

.row.pop-two-form [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.back_click {
    position: absolute;
    left: 19px;
    top: 13px;
}

.back_click a {
    color: #c3c3c3;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    display: inline-block;
    padding-left: 24px;
}

.back_click a:hover {
    color: #333;
}

.back_click a::after {
    content: "";
    position: absolute;
    left: 0;
    width: 11px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -344px -67px;
    top: 2px;
}

.back_click a:hover::after {
    background-position: -375px -67px;
}

.changepw_popup {
    max-width: 768px;
}

.pouup_in h3{
    font-family: 'BrandonGrotesque-Regular';
    font-size: 25px;
    font-weight: 500;
}



.home-banner-main{ 
    padding: 0;
    position: relative;
    /* margin-top: -295px; */
    z-index: 2;
}
.home-banner button {
    /*display: none !important;*/
}
.white-home{  width: 100%;
      padding: 0;
    position: relative;
        margin-bottom: 30px;
    z-index: 2; 
    /* background: url(../images/bg-overlay.png) no-repeat; */
    background-size: contain;
}
.about-inner{
    /* background-color: #0357ae; */
    height: auto;
    align-items: center;
    /* padding-top: 50px;*/
    padding-bottom: 50px;
    /* margin-top: 50px;  */
    padding-bottom: 210px;
}
.about-left{
    margin-top: 120px;
    flex: 0 0 auto;
    width: 50%;
}

.home-banner .slick-dots{     
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 80px;
    right: 20%;
    text-align: center;}
 .home-banner .slick-dots li button{
    padding: 0;
    margin: 0 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -9999px;
     background: #ec6851;
}
.home-banner .slick-dots li{display: inline-block}
.home-banner .slick-dots li.slick-active button{  background: #000;  }
.bann-container{z-index: 3;position: relative;max-width: 1280px;
    padding: 0 20px;
    margin: 0 auto;}
.white-home .slick-slider, .white-home .slick-list, .white-home .slick-track, .white-home .slick-track .slick-slide div{}
.white-home .slick-track .slick-slide > div .products-single-li {padding-bottom: 100px;}
.white-home .slick-track .slick-slide > div .products-single-li .product-price {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
}
.white-home .slick-track .slick-slide > div .products-single-li .product-price .product-price-innr {
    display: flex;
    flex-direction: column;
}
.white-home .slick-track .slick-slide > div .events-single-li {padding-bottom: 40px;}
.white-home .slick-track .slick-slide > div .events-single-li .product-price {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
}
.white-home .slick-track .slick-slide > div .events-single-li .product-price .product-price-innr {
    display: flex;
    flex-direction: column;
}
.event-product-price h3{
    margin: 0 25px 5px;
    font-size: 20px;
    font-family: 'Poppins';
    color: #000;
    font-weight: 600;

}



.event-product-price h3 sup{ font-size: 70%}

.checkout-body .event-product-price {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    }
    .checkout-body .products-single-div{
    padding-bottom:100px;
}
.event-product-price-ckot {
    /*display: flex;*/
    flex-direction: column;
}

.productlist-main-div .products-list-ulmain > li .event-product-price {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
}
.productlist-main-div .products-list-ulmain > li .event-product-price .event-product-price-innr {display: flex;flex-direction: column;}

.white-home .slick-track .slick-slide > div .products-single-li .event-product-price {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
}
.white-home .slick-track .slick-slide > div .products-single-li .event-product-price .event-product-price-innr {
    display: flex;
    flex-direction: column;
}
.white-home .slick-track .slick-slide > div .events-single-li {padding-bottom: 40px;}
.white-home .slick-track .slick-slide > div .events-single-li .event-product-price {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
}
.white-home .slick-track .slick-slide > div .events-single-li .event-product-price .event-product-price-innr {
    display: flex;
    flex-direction: column;
}
.btn_black.login_submit.loginBtn--google {background: #cf4332;color:#fff; font-family: 'poppins';
border: none;    font-weight: 400;    text-transform: capitalize;}
.btn_black.login_submit.loginBtn--google:hover {background: #000;color: #fff;}
{/*.white-home .slick-arrow{display: none !important;}*/}
.wh-lhs {
    max-width: 570px;
}
.wh-lhs-bottom ul {
    list-style: none;
    padding: 100px 0 0 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}
.wh-lhs-bottom ul li{
   text-align: center;
    width: 48%;
    box-shadow:0px 0px 24px 0px rgba(0, 0, 0, 0.12);
    background: #fff;
    position: relative;
    border: 2px solid #fff;
    border-radius: 10px;
    transition: 0.3s linear all;
}
.wh-lhs-bottom ul li:hover{  border-color: #ff8b27;}
.wh-lhs-bottom ul li.active{  border-color: #ff8b27; box-shadow: none}
.hero-top-desc{
    padding: 25px 15px 15px;
}
.hero-top-desc h4{ 
    margin: 0 0 10px 0;
}
.hero-top-desc .button{
    margin: 0 auto;
}
.wh-lhs-top h2 {
    font-size: 66px;
    margin-bottom: 10px;
}
.wh-lhs-top p{
    font-size: 24px;
    font-family: 'CenturyGothic';
    margin: 0;
}
.wh-lhs-top{text-align: center;}
.hero-top-img {
    position: relative;
    height: 50px;
}
.hero-top-img img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.featuredpro-section {
    background: #f7f7f7;
    padding: 40px 0 60px;
    position: relative;
    z-index: 2;
}
.featuredpro-section:before{
    background: url(../images/skew-left.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: -106px;
    left: 0;
    width: 100%;
    content: "";
    height: 106px;
    z-index: 9;
}

.bg-none{
    background:none!important;
}

 .featured-products-section .products-list-ulmain{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.featured-products-section .products-list-ulmain li{
    text-align: center;
    width: 23.828125%;
    margin: 0 1.5625% 20px 0;
    background: #fff;
    box-shadow: 0 3px 28px 0 rgb(1 1 1 / 14%);
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
    display: flex;
    flex-direction: column;
}
.featured-products-section .products-list-ulmain li:nth-child(4n){ margin-right: 0 }
.product-title h3 {
    margin-bottom: 10px;
    line-height: 30px;
    letter-spacing: 1px;
    font-size: 20px;
        color: #060801;
    font-family: 'poppins';
    font-weight: bold;
    text-transform: inherit;
}
.products-image-div img { 
    width: 100%;
    height: 100%; 
    object-fit: cover;
}
.product-tag-list span{ background: #c2defb;
    color: #fff;
    font-family:'open sans';
    font-size: 13px;
    text-transform: uppercase;
    padding: 6px 15px;
    display: block;
    margin: 0 auto 5px;
    line-height: 1;
    border-radius: 50px;}



.myacc_filter .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    background-color: #fff;
    border: 0;
    border-bottom-color: transparent;
    font-family: 'BrandonGrotesque-Regular';
    font-weight:600;
    text-transform: uppercase;
    color: #000;
}

.myacc_filter .nav.nav-tabs.text-center{
    border-bottom: 5px solid #e3e3e3;
    font-size: 16px;
    font-family:'poppins'
}

.myacc_filter .nav.nav-tabs.text-center > li.active:before, .nav.nav-tabs.text-center > li:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.myacc_filter .nav.nav-tabs.text-center > li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #0271e5;
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s linear;
}

.myacc_filter .tab-content {
    /*padding-top: 5px;
    background: #fff;
    padding: 20px;*/
}
.inner-bdy{
    padding:25px;
}
.tab_mobtrigger{
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: 500;
    margin-top: 15px;
}
.myacc_filter .nav-tabs > li > a{
    font-family: 'poppins';
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    background-color: #fff;
    border: 0;
    border-bottom-color: transparent;
}

.myacc_filter .nav-tabs > li > a:hover {
    background-color: #fff;
    border: 0;
    border-bottom-color: transparent;
    font-family: 'poppins';
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
}

.myacc_order_details .order_btns .button:hover {
    background: #ff8b27;
}

.product-info-div .products-ordernow-action .qty_bx{
    margin-right: 3px;
}

.tab_mobrow .promo-earned-info .promation_btn .button{
    background: #0271e5;
    border: 1px solid #0271e5;
    color: #ffffff;
    margin-right: 10px;
    margin-top: 15px;
        /* width: 49%; */
    /* padding: 10px 5px; */
    margin: 0;
    font-size: 12px;
}

.tab_mobrow .promo-earned-info .promation_btn .promation_btn-one {
    background: #f7f7f7;
    color: #000;
    margin-right:4px;
    border: 1px solid #d4d4d1;
 }

.tab_mobrow .promo-earned-info .promation_btn .button:hover {
    background: #000;
    color: #fff;
    border-color: #000;
}

.myacc_order_details{
    list-style-type: none;
}

.modal .inn-product-popup .inn-product-img-bg img{
    width: 100%;
}

.progress_bar_div .indication .indication_progress .progress_bar {
    background: #74b9ff;
}

.progress_bar_div .indication .indication_progress{
    background: #ececec;
}

.progress_bar_div .help-block{
    color: #676767;
}

.thank-order-detaildiv .delivery-cart-div .cart-direction .cart-direction-left{
    height: 28px;
}

.tnk-delivery .delivery-cart-div .cart-direction::before {
    border: 1px dashed #dfdad7;
    top: 15px;
}

.thank-order-detaildiv .tnk-delivery .cart-direction{
    width: 100%;
}

.cart_footer .progress_bar_div .indication{
    width: 100%;
}

.page404-main-div img{
    height: 300px;
}

.home-main-div{ width: 100%; background: url(../images/body-bg.jpg) no-repeat;    
    background-size: cover; }

.productpage-main-div{
    width: 100%;
    background: url(../images/body-bg.jpg)repeat;
    background-size: contain;
    background-repeat: repeat;
    overflow-x: hidden;
}
.contactusmain-top-div{
    width: 100%;
    background: url(../images/body-bg.jpg) no-repeat;
    background-size: cover;
    overflow-x: hidden;
}
.checkout-main-div{
    width: 100%;
    background: url(../images/body-bg.jpg) no-repeat;
    background-size: cover;
    overflow-x: hidden;
}
.pagesList-main-div{
    width: 100%;
    background: url(../images/body-bg.jpg) no-repeat;
    background-size: cover;
    overflow-x: hidden;
}
.myacc-main-div{
    width: 100%;
    background: url(../images/body-bg.jpg) no-repeat;
    background-size: cover;
    overflow-x: hidden;
}
.thankyou-main-div{
    width: 100%;
    background: url(../images/body-bg.jpg) no-repeat;
    background-size: cover;
    overflow-x: hidden;
}
.top-bar{ background: #f4f4f4; width: 100%; position: relative; margin: 0; overflow: hidden; }
.top-bar p{ 
    margin: 0;
    padding: 15px;
    color: #282828;
    background: #f4f4f4;
    font-size: 15px;
    text-align: center;    
 }
.top-bar span{ position: absolute;
    left: 0;
    top: 0;
    background: #e2e2e2;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 17px;
    padding: 15px 30px; }
.top-bar span:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 90px solid #e2e2e2;
    border-right: 60px solid transparent;
    position: absolute;
    left: 140px;
    top: 0;
}

.home-top-four-section{ width: 100%; padding-bottom:0px; padding:30px 15px 0px 15px; display: flex; flex-wrap: wrap; justify-content: center; }
.four-columns{ 
    width: 18%;
    margin: 5px;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    border: 2px solid #0271e5;
    border-radius:10px;
    background:#fff;
}
.four-columns img{ 
    margin: 0 auto 18px; 
    display: block;
    max-height: 104px;
    /* filter: brightness(0.3); */

}
.four-columns h3{ 
    margin: 0 0 18px;
    line-height: 40px;
    font-family: 'Poppins';
    font-weight: bold;
    color: #101010;
    /* -webkit-text-stroke: 1px #ffffff; */
    font-size: 18px;
    /* text-shadow: 1px 1px 2px #ffffff, 0 0 25px #fdfdfd, 0 0 5px #ffffff; */
} 

.white-home .four-columns:hover .offer_txt h3,
.white-home .four-columns.active .offer_txt h3 {color: #fff;}

.white-home .four-columns:hover .offer_txt a,
.white-home .four-columns.active .offer_txt a {
    display: block;
    padding: 14px 20px;
    color: #0271e5;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px; 
    font-size: 15px;
    margin: 20px 0 0; 
    font-family: 'Poppins', sans-serif;
    font-weight: bold; 
    border-radius: 8px;
    background: #fff; 
    max-width: 162px;
    margin: 0 auto 0;
}
.white-home .four-columns .cat-img .mou_active1 {display: none;}
.white-home .four-columns:hover .cat-img .no_mou1,
.white-home .four-columns.active .cat-img .no_mou1 {display: none;}
.white-home .four-columns:hover .cat-img .mou_active1,
.white-home .four-columns.active .cat-img .mou_active1  {display: flex;}

.white-home .four-columns .cat-img .mou_active2 {display: none;}
.white-home .four-columns:hover .cat-img .no_mou2,
.white-home .four-columns.active .cat-img .no_mou2 {display: none;}
.white-home .four-columns:hover .cat-img .mou_active2,
.white-home .four-columns.active .cat-img .mou_active2  {display: flex;}

.white-home .four-columns .cat-img .mou_active3 {display: none;}
.white-home .four-columns:hover .cat-img .no_mou3,
.white-home .four-columns.active .cat-img .no_mou3 {display: none;}
.white-home .four-columns:hover .cat-img .mou_active3,
.white-home .four-columns.active .cat-img .mou_active3  {display: flex;}

.white-home .four-columns .cat-img .mou_active4 {display: none;}
.white-home .four-columns:hover .cat-img .no_mou4,
.white-home .four-columns.active .cat-img .no_mou4 {display: none;}
.white-home .four-columns:hover .cat-img .mou_active4,
.white-home .four-columns.active .cat-img .mou_active4  {display: flex;}

.white-home .four-columns .cat-img .mou_active5 {display: none;}
.white-home .four-columns:hover .cat-img .no_mou5,
.white-home .four-columns.active .cat-img .no_mou5 {display: none;}
.white-home .four-columns:hover .cat-img .mou_active5,
.white-home .four-columns.active .cat-img .mou_active5  {display: flex;}

.four-columns-btn {
    display: block;
    padding: 14px 20px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px; 
    font-size: 15px;
    margin: 20px 0 0; 
    font-family: 'Poppins', sans-serif;
    font-weight: bold; 
    border-radius: 8px;
    background: #0271e5; 
    max-width: 162px;
    margin: 0 auto 0;
}

.four-columns-btn:hover { color: #0271e5; background: #fff; }
.four-columns-btns:hover { color: #fff; background: #0271e5; }

.four-columns:hover{
    background: #0271e5;
    color:#fff
}

/* .white-home .home-top-four-section .four-columns .mou_active_store {
    display: none;
    }
    
    .white-home .home-top-four-section.active .four-columns .no_mou_store,
    .white-home .home-top-four-section:hover .four-columns .no_mou_store{
    display: none;
    }
    
    .white-home .home-top-four-section.active .four-columns .mou_active_store,
    .white-home .home-top-four-section:hover .four-columns .mou_active_store {
    display: block;
    }

    .white-home .home-top-four-section .four-columns .mou_active {
        display: none;
        }
        
        .white-home .home-top-four-section.active .four-columns .no_mou,
        .white-home .home-top-four-section:hover .four-columns .no_mou{
        display: none;
        }
        
        .white-home .home-top-four-section.active .four-columns .mou_active,
        .white-home .home-top-four-section:hover .four-columns .mou_active {
        display: block;
        } */

        .order-body .header-ordernow-single-img:hover .offer_txt h3,
        .order-body .header-ordernow-single-img.active .offer_txt h3 {color: #fff;}
        
        .order-body .header-ordernow-single-img .cat-img .mou_active1 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .no_mou1,
        .order-body .header-ordernow-single-img.active .cat-img .no_mou1 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .mou_active1,
        .order-body .header-ordernow-single-img.active .cat-img .mou_active1  {display: flex;}
        
        .order-body .header-ordernow-single-img .cat-img .mou_active2 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .no_mou2,
        .order-body .header-ordernow-single-img.active .cat-img .no_mou2 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .mou_active2,
        .order-body .header-ordernow-single-img.active .cat-img .mou_active2  {display: flex;}
        
        .order-body .header-ordernow-single-img .cat-img .mou_active3 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .no_mou3,
        .order-body .header-ordernow-single-img.active .cat-img .no_mou3 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .mou_active3,
        .order-body .header-ordernow-single-img.active .cat-img .mou_active3  {display: flex;}
        
        .order-body .header-ordernow-single-img .cat-img .mou_active4 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .no_mou4,
        .order-body .header-ordernow-single-img.active .cat-img .no_mou4 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .mou_active4,
        .order-body .header-ordernow-single-img.active .cat-img .mou_active4  {display: flex;}
        
        .order-body .header-ordernow-single-img .cat-img .mou_active5 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .no_mou5,
        .order-body .header-ordernow-single-img.active .cat-img .no_mou5 {display: none;}
        .order-body .header-ordernow-single-img:hover .cat-img .mou_active5,
        .order-body .header-ordernow-single-img.active .cat-img .mou_active5  {display: flex;}

.f-white{
    color: #fff !important;
}
.tkaw-white-btn {
    display: block;
    padding: 10px 15px;
    color: #0271e5;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px; font-size: 16px;
    margin: 20px 0 0; font-family: 'Poppins', sans-serif;
    font-weight: bold; border-radius: 8px;
    border: 2px solid #0271e5;
background: #fff; /* Old browsers */
/* max-width: 162px; */
    margin: 0 auto 0;
}

.tkaw-white-btn:hover { color: #fff; background: #0271e5; }

.our-promotions-section.our-special-section {
    padding-top: 50px;
}
.our-promotions-section { width: 100%; text-align: center; padding: 10px 0 20px; }
.our-promotions-section h2 { 
    margin: 0 0 0px; 
    line-height: 70px; 
    font-weight:bold;
    font-family: 'poppins';
    color: #101010;
    /* -webkit-text-stroke: 1px #fff;
    text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff; */
    font-size: 50px 
}
.our-special-section h2{ color: #101010; }
.our-promotions-section > p{ 
    margin: 0 0 0px; 
    text-align: center; 
    color: #181818;
    font-size: 12px;
    line-height:40px;
    font-family: 'poppins'; 
    font-weight: 600 }
.our-special-section > p{  color: #181818; }
.our-promotions-section .slick-dots {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none; padding: 20px 0 0;
}
.our-promotions-section .slick-dots li { margin: 0 5px; }
.our-promotions-section.happening-section {
    padding: 30px 30px 30px;
}
.our-promotions-section.our-special-section .prdt-list p {
    color: #000;
}
.our-promotions-section .slick-slide img { width: 100%; padding: 0px 0 20px; }
.our-special-section .slick-slide img{padding-bottom: 0}
.our-promotions-section.happening-section .slick-slide img {
    padding: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 40%);
}
.our-promotions-section button {
    background: #000;
    width: 11px;
    height: 11px;
    text-indent: -9999px;
    padding: 0;
    list-style: none;
    border-radius: 50%;
    cursor: pointer; margin: 0;
}
.addcart_row a{
    margin: 9px 0px 0px 0px;
}
.our-promotions-section .slick-track .slick-slide{ justify-content: center; padding: 10px;

}
.our-promotions-section .slick-dots li.slick-active button { background: #0271e5; }
.prdt-list{ width: 100%;  box-shadow:         0px 1px 20px 0px rgba(0, 0, 0, 0.11);
background: #fff; position: relative; border: 2px solid rgba(236,236,236,0.90); }
.prdt-list section{ padding: 0 20px 70px; position: relative;
    z-index: 1; }
.prdt-list h3{ margin: 0 0 10px;  font-size: 18px;font-family: 'Poppins', sans-serif; font-weight: 700; text-transform: inherit; }
.prdt-list p{ font-size: 15px; color: #757373; font-weight: 200; }
.prdt-list span.special {
    background: #a81774;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 7px 14px 7px;
    display: inline-flex;
    margin: 0 auto 3px;
    line-height:1;
    border-radius: 50px;
}
.prdt-list .price, .prdt-list .old-price{ display: block; }
.prdt-list .price {
    font-size: 28px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    padding: 10px 0 0 0;
}
.prdt-list .price sup {
    font-size: 16px;
    top: -12px;
    font-weight: 600;
}
.prdt-list .old-price {
    font-size: 21px;
    font-weight: 600; color: #b6b6b6; text-decoration: line-through;
}
.prdt-list .old-price sup { font-size: 14px; top: -8px; font-weight: 500; }
.blank-hgt{ height: 37px; display: block; }
.prdt-list-btn {
    display: block;
    padding: 15px 20px;
    color: #0271e5;
    text-transform: uppercase;
    border: 2px solid #0271e5;
    letter-spacing: 0.5px; font-size: 16px;
    margin: 20px 0 0; font-family: 'Poppins', sans-serif;
    font-weight: 500; border-radius: 8px;
     transition: 0.2s linear all;
background: #fff; /* Old browsers */
/* background: -moz-linear-gradient(-45deg,  #f36b31 0%, #f2672e 9%, #f26730 14%, #f0642f 20%, #ed612c 22%, #ed5f2d 28%, #ed5f31 29%, #ea5c2e 31%, #e8532b 46%, #e44e29 48%, #e44e29 51%, #e64d2d 52%, #e64d2d 52%, #e44b2b 53%, #e44b2b 57%, #e2482c 57%, #e0462a 59%, #e0442b 66%, #dd4128 68%, #de422c 69%, #dc402a 71%, #de3f2c 75%, #d83926 85%, #da3728 86%, #d63226 100%); /* FF3.6-15 */
/* background: -webkit-linear-gradient(-45deg,  #f36b31 0%,#f2672e 9%,#f26730 14%,#f0642f 20%,#ed612c 22%,#ed5f2d 28%,#ed5f31 29%,#ea5c2e 31%,#e8532b 46%,#e44e29 48%,#e44e29 51%,#e64d2d 52%,#e64d2d 52%,#e44b2b 53%,#e44b2b 57%,#e2482c 57%,#e0462a 59%,#e0442b 66%,#dd4128 68%,#de422c 69%,#dc402a 71%,#de3f2c 75%,#d83926 85%,#da3728 86%,#d63226 100%); /* Chrome10-25,Safari5.1-6 */
/* background: linear-gradient(135deg,  #f36b31 0%,#f2672e 9%,#f26730 14%,#f0642f 20%,#ed612c 22%,#ed5f2d 28%,#ed5f31 29%,#ea5c2e 31%,#e8532b 46%,#e44e29 48%,#e44e29 51%,#e64d2d 52%,#e64d2d 52%,#e44b2b 53%,#e44b2b 57%,#e2482c 57%,#e0462a 59%,#e0442b 66%,#dd4128 68%,#de422c 69%,#dc402a 71%,#de3f2c 75%,#d83926 85%,#da3728 86%,#d63226 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
/* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f36b31', endColorstr='#d63226',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.prdt-list-btn:hover { color: #fff; background: #0271e5; }

.product-spl-price {
    text-align: center;
    padding-bottom: 7px;
}
.product-spl-price h3 {
    font-size: 18px;
    font-family: 'BrandonGrotesque-Regular';
    margin-bottom: 0;
    display: inline-flex;
    color: #b6b6b6;
    text-decoration: line-through;
    font-weight: 500
}

.our-promotions-section .slick-track .slick-slide.slick-active .prdt-list:hover {
    border: 2px solid #a71773;
}

.promo-three{ width: 100%; background: #f9eadd; border-radius: 50px; overflow: hidden; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; }
.our-promotions-section.promo-section .slick-track .slick-slide {
    display: block !important;
}
.our-promotions-section.promo-section .slick-track .slick-slide img {
    width: 100%;
    padding: 0;
}

.upcoming-events-section.our-special-event-section {
    padding-top: 0;
}
.upcoming-events-section { width: 100%; text-align: center; padding: 10px 0 20px; }
.upcoming-events-section h2 { 
    margin: 0 0 0px; 
    line-height: 70px; 
    font-weight:bold;
    font-family: 'poppins';
    color: #101010;
    /* -webkit-text-stroke: 1px #fff;
    text-shadow: 1px 1px 2px #fff, 0 0 25px #fff, 0 0 5px #fff; */
    font-size: 50px;
    margin-bottom: 20px; 
}
.our-special-event-section h2{ color: #101010; }
.upcoming-events-section > p{ 
    margin: 0 0 0px; 
    text-align: center; 
    color: #181818;
    font-size: 12px;
    line-height:40px;
    font-family: 'open sans'; 
    font-weight: 600 }
.our-special-event-section > p{  color: #181818; }
.upcoming-events-section .slick-dots {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none; padding: 20px 0 0;
}
.upcoming-events-section .slick-dots li { margin: 0 5px; }
.upcoming-events-section.happening-section {
    padding: 20px 0 0px;
}
.upcoming-events-section.our-special-event-section .prdt-list p {
    color: #000;
}
.upcoming-events-section .slick-slide img { width: 100%; padding: 0px 0 20px; }
.our-special-event-section .slick-slide img{padding-bottom: 0}
.upcoming-events-section.happening-section .slick-slide img {
    padding: 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 40%);
}
.upcoming-events-section button {
    background: #101010;
    width: 11px;
    height: 11px;
    text-indent: -9999px;
    padding: 0;
    border:none;
    list-style: none;
    border-radius: 50%;
    cursor: pointer; margin: 0;
}

.upcoming-events-section .slick-track .slick-slide{ justify-content: center; padding: 10px; }
.upcoming-events-section .slick-dots li.slick-active button { background: #0271e5; }

.prd-cat{
    padding: 190px 15px 0px 15px;
}

.upcoming-events-section .slick-track .slick-slide.slick-active .prdt-list:hover {
    /* border: 2px solid #a71773; */
}


.upcoming-events-section.promo-section .slick-track .slick-slide {
    display: block !important;
}
.upcoming-events-section.promo-section .slick-track .slick-slide img {
    width: 100%;
    padding: 0;
}

.upcoming-events-bg .slick-track .slick-slide{
    box-shadow: 2px 2px 3px 2px rgb(0 0 0 / 10%);
    overflow: hidden;
    background: #fff;
}

.promo-three-clm1{ width: 30%; }
.promo-three-clm2{ width: 40%; padding: 20px; }
.daily-text{ font-style: italic; }
.promo-three-clm2 h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 35px;
    margin: 0;
}
.promo-three-clm2 h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: #ff9400;
    font-weight: 700;
    text-transform: uppercase;
    margin: 4px 0;
}
.promo-three-clm2 b {
    display: block;
}
.promo-three-clm2 span{ display: block; }
.promo-btn {
    display: block;
    padding: 10px 20px;
    color: #fff;
    background: #d63227;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px; font-size: 17px;
    margin: 20px 0 0; font-family: 'Poppins', sans-serif;
    font-weight: 500; border-radius: 5px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f36b31+0,f2672e+9,f26730+14,f0642f+20,ed612c+22,ed5f2d+28,ed5f31+29,ea5c2e+31,e8532b+46,e44e29+48,e44e29+51,e64d2d+52,e64d2d+52,e44b2b+53,e44b2b+57,e2482c+57,e0462a+59,e0442b+66,dd4128+68,de422c+69,dc402a+71,de3f2c+75,d83926+85,da3728+86,d63226+100 */
background: #f36b31; /* Old browsers */
background: -moz-linear-gradient(-45deg,  #f36b31 0%, #f2672e 9%, #f26730 14%, #f0642f 20%, #ed612c 22%, #ed5f2d 28%, #ed5f31 29%, #ea5c2e 31%, #e8532b 46%, #e44e29 48%, #e44e29 51%, #e64d2d 52%, #e64d2d 52%, #e44b2b 53%, #e44b2b 57%, #e2482c 57%, #e0462a 59%, #e0442b 66%, #dd4128 68%, #de422c 69%, #dc402a 71%, #de3f2c 75%, #d83926 85%, #da3728 86%, #d63226 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  #f36b31 0%,#f2672e 9%,#f26730 14%,#f0642f 20%,#ed612c 22%,#ed5f2d 28%,#ed5f31 29%,#ea5c2e 31%,#e8532b 46%,#e44e29 48%,#e44e29 51%,#e64d2d 52%,#e64d2d 52%,#e44b2b 53%,#e44b2b 57%,#e2482c 57%,#e0462a 59%,#e0442b 66%,#dd4128 68%,#de422c 69%,#dc402a 71%,#de3f2c 75%,#d83926 85%,#da3728 86%,#d63226 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  #f36b31 0%,#f2672e 9%,#f26730 14%,#f0642f 20%,#ed612c 22%,#ed5f2d 28%,#ed5f31 29%,#ea5c2e 31%,#e8532b 46%,#e44e29 48%,#e44e29 51%,#e64d2d 52%,#e64d2d 52%,#e44b2b 53%,#e44b2b 57%,#e2482c 57%,#e0462a 59%,#e0442b 66%,#dd4128 68%,#de422c 69%,#dc402a 71%,#de3f2c 75%,#d83926 85%,#da3728 86%,#d63226 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f36b31', endColorstr='#d63226',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
max-width: 220px;
    margin: auto;
}

.promo-btn:hover { color: #fff; background: #a81774; }
.promo-three-clm3{ width: 30%; }

.prd_chosen_sub_item_right .button:hover{
    background-color: #52FA00;
    color:#000
}
.foot-left{
    margin-top:30px;
}

.testimonial-section{ width: 100%;
    /* background-image: url("../images/testimonial.png"); */
    position: relative;
    /*padding: 0px 50px 0px 0px;*/
    text-align: center;
    margin: 0 0 0px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: auto;
    color: #fff; border-radius: 8px; }

.testi-content p{
        overflow: hidden;
        height: 150px;
        color: #0d0b06;
        font-size: 17px;
        text-align: left;
        font-family: 'poppins';
}

/*.prd_chosen_sub_item_right button{
    margin-top: 25px;
}*/

.prd_chosen_sub_item_right button:hover{
    background-color: #52FA00;
    color:#000
}
    
.check-p p{
    color: #fff;
    margin-left: 25px;
    font-family: 'Poppins';
    font-weight: bold;
}
.date-sec p{
    color: #fff;
    text-transform: uppercase;
    margin-left: 25px;
    font-family: 'Poppins';
    font-weight: bold;
}
.qrcode img{
    padding: 55px;
    border: 1px solid #f4f4f4;
}

.checkout-umbrella-link a{
    font-size: 14px;
    color: #393939;
    font-family: 'Open Sans';
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 10px 0 0 0px;
    display: inline-block;
    vertical-align: top;
    transition: 0.3s linear all;
}

.dinein-white-popup{
    position: relative;
    /*padding: 25px;*/
    width: auto;
    max-width: 450px !important;
    margin: 0 auto!important;
}
.mb-10{
    margin-bottom:10px
}
.text-center{
    text-align: center;
}
.slick-next{
    right: 0px;width: 24px;height: 11px;float: right; text-indent: -9999px; padding: 0;     background: url(../images/right-arrow.png) no-repeat;
 list-style: none; cursor: pointer; margin: 0;    top: -40px;
}
.slick-prev{
    width: 24px; height: 11px; text-indent: -9999px; background: url(../images/left-arrow.png) no-repeat;    top: 270px; left: 0px;
 list-style: none; cursor: pointer; margin: 0;
}
.testimonial-section h2 { 
    margin: 0 0 0px;
    line-height: 70px;
    font-weight: bold;
    font-family: 'poppins';
    color: #101010;
    font-size: 50px;
    line-height: 0;
}
.testimonial-section > div{ 
    /*text-align: left; */
    padding: 70px 80px 0px 80px; }
.testimonial-section .slick-dots { display: flex !important; flex-wrap: wrap; justify-content: center;
list-style: none; padding: 20px 0 0; }
.testimonial-section .slick-dots li { margin: 0 5px; }

/*.testimonial-section button { background-color: unset !important;
    text-decoration: none !important; }*/

.testimonial-section .slick-track .slick-slide{ justify-content: center; opacity: 0.3; }
.testimonial-section .slick-track .slick-slide.slick-current{ opacity: 1; }
.testimonial-section .slick-dots li.slick-active button { background: #0271e5; }

.testimonial-section button{
    background: #101010;
    width: 11px;
    height: 11px;
    text-indent: -9999px;
    padding: 0;
    list-style: none;
    border-radius: 50%;
    cursor: pointer;
    margin: 0;
    border:none;
}

.testimonial-section .slick-track .slick-slide.slick-cloned:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.testimonial-section .slick-track .slick-slide.slick-cloned:before, 
.testimonial-section .slick-track .slick-slide.slick-active:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

}
.testimonial-section .slick-slide.slick-active.slick-center.slick-current:before { 
    content: ''; 
    background: transparent;
}
.testimonial-section .slick-track .slick-slide > div{ padding: 0 20px; }

.footer-main{ 
    width: 100%;
    /* background-color:#0271e5; */
    /*background: url(../images/foot.png) no-repeat;*/
    background-position: center top;
    padding: 30px 0 0 0;
    margin: 0;
    background-repeat: repeat-x;
    background-size: cover; 
}
.foot-bottom{
    background-color:#0271e5;
}
.footer-bottom {
    padding-top: 30px;
    padding-bottom: 20px;
    background: #d45d38;
}

.footer-bottom .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.footer-bottom-left {
    width: 20%;
    display: inline-block;
}

.footer-bottom-right {
    width: 15%;
    display: inline-block;
}

.footer-bottom-middle {
    width: 58%;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

/* .footer-top {
    text-align: center;
    padding: 0 0 20px 0;
}
.footer-top img{
    margin-top: -75px;
} */

.footer-contact a,
.footer-bottom-right h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    text-decoration: none;
    color: #fff;
}

.footer-menu h3 {
    font-family: 'fjalla_oneregular';
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}

.footer-menu ul,
.footer-bottom-right ul {
    padding-left: 0px;
    list-style: none;
}

.footer-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.footer-bottom-right ul li {
    display: inline-block;
}

.footer-bottom-right ul li a {
    color: #fff;
    font-size: 22px;
}

.footer-menu ul li a:hover {
    color: #000
}
.ft-ct-top{ max-width: 900px; margin: 0 auto; display: flex; flex-wrap: wrap; justify-content: center; }
.ft-tp-col1, .ft-tp-col2, .ft-tp-col3{ width: 31.33%; margin: 15px 1%; text-align: center; }
.ft-tp-col3 h4 {font-weight: 700;color: #242a35;text-transform: uppercase;font-size: 17px;margin: 25px 0 15px;font-family: 'poppins';}
.ft-tp-col1 img, .ft-tp-col2 img, .ft-tp-col3 img{ margin: 0 auto 10px; display: block;max-height: 24px;}
.ft-tp-col1 b, .ft-tp-col2 b, .ft-tp-col3 b{ font-weight: 600; text-transform: uppercase; font-family: 'poppins'; font-size: 17px;    color: #fff; }
.ft-tp-col1 p, .ft-tp-col2 p, .ft-tp-col3 p{ margin: 0 0 5px;color: #fff; font-family:'poppins'; font-size:17px; font-weight: normal;}
.ft-ct-top a{ color: #fff; text-decoration: none;}
.ft-ct-top a:hover{ color: #000; text-decoration: none;}

.ft-ct-bottom {max-width: 100%;margin: 0 auto;display: flex;flex-wrap: wrap;justify-content: center;text-align: left;padding: 30px 0 30px;}
.ft-bt-col1{ width: 25%; padding: 0 0 0 0; }
.ft-bt-col1 p{ 
    width: 275px;
    margin: 0;
    font-size: 16px;
    word-break: break-all;
    font-family: 'poppins';
    line-height: 22px;
    color: #fff;
    font-weight: normal;
}
.ft-bt-col2{width: 12%;padding: 0px 15px 0%;margin-bottom: 25px;}
.ft-bt-col5 {width: 18%;padding: 0px 15px 0%;margin-bottom: 15px;}
.ft-bt-col3 {width: 19%;padding: 0px 15px 0%;margin-bottom: 15px;}
.ft-bt-col4 {width: 14%;padding: 0px 1%;margin-bottom: 15px;}

.ft-bt-col4 h4 ~ h4 {margin-top: 25px;}
.ft-ct-bottom h4 { font-weight: 600; color:#fff; text-transform: uppercase; font-size: 17px; margin: 0 0 8px;    font-family: 'poppins'; }
.ft-list { padding: 0; margin: 0; list-style: none; }
.ft-list li { margin: 0 0 10px; font-size: 16px; }
.ft-list li a{ color: #fff; font-family: 'poppins';font-size: 17px; font-weight:normal;text-decoration: none;}
.ft-list li a:hover{ color: #000; }
.foot-map {
    position: relative;
    overflow: hidden;
    text-align: center;
}
.social-list { padding: 0; margin: 0; list-style: none; display: flex; flex-wrap: wrap; align-items: center;justify-content: center;}
.social-list li { margin: 0; float: left; margin: 0 10px 0 0; font-size: 16px; }
.social-list li:last-child {margin: 0;}
.social-list li a{ color: #000; }
.social-list li a:hover{ color: #ff9f28;filter: brightness(0); }
.copyright-section p {
    font-size: 16px;
    color: #fff;
    font-family: 'poppins';
    text-align: center;
    margin-bottom: 0px;
    font-weight: normal;
}
.mt-40{
    margin-top: 40px;;
}
.white{
    color:#fff
}
.testi-name h5{
    font-size: 16px;
    font-family: 'poppins';
    text-align: center;

}
.testi-name p{
    font-size: 15px;
    font-family: 'poppins';
    text-align: center;

}
.mb-5{
    margin-bottom: 5px;
}
.mb-50{
    margin-bottom: 50px;
}
.pt-20{
    padding-top: 20px;
}
.testi-name img{
    position: absolute;
}
.detail-pg {
    position: relative;
    display: block;
    max-width: 1300px;
    padding: 0 20px;
    margin: 25px auto 0;
    text-align: center;
    overflow-x: hidden;
}
.detail-pg .detail-pg-box {
    width: auto;
    display: inline-block;
}
.detail-pg .detail-pg-slider {
    height: 100%;
    max-width: 1280px;
    width: auto;
    margin: 0 auto;
    padding-bottom: 10px;
    display: flex;
    /* overflow-x: scroll; */
}
.detail-pg .detail-pg-slider::-webkit-scrollbar {height: 5px;}
.detail-pg .detail-pg-slider:hover::-webkit-scrollbar-thumb {background: #fff;border-radius: 10px;}
.detail-pg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}
.detail-pg .owl-stage-outer .owl-stage .owl-item {max-width: 420px;margin-right: 10px;}
.detail-pg .slick-slider .slick-list .slick-slide > div {}
.detail-pg .innerproduct-item {
    position: relative;
    border-radius: 15px;
    background: #fff;
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 25px 10px;
    margin-right: 10px;
    border: 2px solid #fff;
    cursor: pointer;
}
.detail-pg .innerproduct-item .offer_img {
    background: #F7F7F7;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.detail-pg .innerproduct-item .offer_img img {display: flex;justify-content: center;align-items: center;width: auto;}
.detail-pg .innerproduct-item .offer_img .no_mou {display: flex;}
.detail-pg .innerproduct-item .offer_txt h3 {font-family: 'Open Sans', sans-serif;font-size: 16px;font-weight: bold;margin: 0;padding: 0;color: #000;}
.detail-pg .innerproduct-item .offer_txt p {margin: 0;font-family: 'Baloo 2', cursive;font-size: 17px;color: #37433b;}
.detail-pg .innerproduct-item::after {display: none;}
.detail-pg .innerproduct-item .offer_circle {
    width: 15px;
    height: 15px;
    background: #F7F7F7;
    border: 2px solid #E0E0E0;
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 10px;
}
.detail-pg .innerproduct-item .offer_img .mou_active {display: none;}
.detail-pg .innerproduct-item:hover,
.detail-pg .innerproduct-item.active {background: #000;border-color: #00CEE5;}
.detail-pg .innerproduct-item:hover .offer_txt h3,
.detail-pg .innerproduct-item.active .offer_txt h3 {color: #fff;}
.detail-pg .innerproduct-item:hover .offer_txt p,
.detail-pg .innerproduct-item.active .offer_txt p {color: #fff;}
.detail-pg .innerproduct-item:hover .offer_img,
.detail-pg .innerproduct-item.active .offer_img  {background: #232323;}
.detail-pg .innerproduct-item:hover .offer_circle,
.detail-pg .innerproduct-item.active .offer_circle {background: #00CEE5;border-color: #00CEE5;}
.detail-pg .innerproduct-item:hover .offer_img .no_mou,
.detail-pg .innerproduct-item.active .offer_img .no_mou {display: none;}
.detail-pg .innerproduct-item:hover .offer_img .mou_active,
.detail-pg .innerproduct-item.active .offer_img .mou_active  {display: flex;}
.products-single-li .like {position: absolute;top: 13px;right: 8px;}
/*.products-single-li .like:hover img,
.products-single-li .like.active img {filter: brightness(0);}*/
.events-single-li .like {position: absolute;top: 13px;right: 8px;}
/*.events-single-li .like:hover img,
.events-single-li .like.active img {filter: brightness(0);}*/

/*.products-single-li .like:hover img,
.products-single-li .like.active img {filter: brightness(0);}*/
.products-single-li .like:hover .no_mou,
.products-single-li .like.active .no_mou {display: none;}
.products-single-li .like:hover .mou_active,
.products-single-li .like.active .mou_active {display: flex;}
.products-single-li .like .mou_active {display: none;}


.progress-1 {
    margin:20px auto;
    padding:0;
    width:90%;
    height:20px;
    overflow:hidden;
    background:#1c1c1c;
    border-radius:15px;
  }
  
  .bar-1 {
      position:relative;
    float:left;
    min-width:1%;
    height:100%;
    background:#fbd7b7;
  }
  
  .percent-1 {
      position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0;
  }
.bar-width-1{
    width:70%
}  
.progress-2 {
    margin:15px auto;
    padding:0;
    width:90%;
    height:20px;
    overflow:hidden;
    background:#1c1c1c;
    border-radius:15px;
  }
  
  .bar-2 {
      position:relative;
    float:left;
    min-width:1%;
    height:100%;
    background:#00bbd0;
  }
  
  .percent-2 {
      position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0;
  }
.bar-width-2{
    width:55%
}  
 .prog-bar p{
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins';
    text-transform: uppercase;
    color: #1c1c1c;
 } 
.prog-bar{
    margin-bottom: 15px;
}
 .tooltip-top:before {
    content: '';
    position: absolute;
    width: 0px;        
    top: -30px;
    border: 15px solid transparent;
    border-bottom: 15px solid #a81774;
   
  }
  .tooltip-cart {  
    position: relative;
    padding: 0px 15px;
    width: 0vw;
    margin: 0px auto;
    right: 40px;
    float: right;
    text-align: center;
  }
  .acc-bg{
    /*background:#fff;
    padding:20px;*/ 
}
.ordr-hdr h4{
    font-family: 'BrandonGrotesque-Regular';
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #000;
    /*margin-top: 10px;*/
}
.mb-20{
    margin-bottom: 20px;
}
.pad-0 {
    padding: 0px !important;
}
.order_btns, .order_amt{
    padding: 10px;
}
.parallax:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*transform: translateZ(-1px) scale(1.5);*/
    background-size: 100%;
    z-index: -1;
  }
  .outlet-bg {
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height:450px;
  }
  .outlet-bg h1{
      text-align: center;
      padding-top:190px;
      font-family: 'Poppins';
      font-weight: bold;
      font-style: italic;
      text-shadow: #ffffff -1px -1px 0px, #ffffff -1px 1px 0px, #ffffff 1px 1px 0px, #ffffff 1px -1px 0px;
      color: #F77202;
      font-size: 80px;
  }
  .pickup-thankfull h4{
      font-size: 35px;
      margin-bottom: 0px;
  }
  .right-0{
      right:0px !important;
  }
  .f-addr-head span{
    font-size:17px;
    }
    .f-addr-body span{
    font-size:14px;
    }
    .promo-popup-image{
        text-align: center;
    }
    .promo-earned-content h4{
        font-family: 'BrandonGrotesque-Regular';
        font-weight: 600;
    }
   
    body.cart-items-open {
        overflow: hidden;
        }    
    .promo_cart_row{
        position: relative;
        padding-right: 25px;
    }    
    .promo-earned-content a{
        display: block;
        border-radius: 6px;
        margin: 0px auto;
        text-align: center;
    }
/*    .cartaction_bottom{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 8px;
        z-index: 999;
        font-weight: 700;
        background: #fff;
    }*/
    .myacc_gen{
        font-size: 16px;
        z-index: 11;
      }
      .faq_img img{
        max-width:600px
        }     
        .pt-200{
            padding-top:200px;
        }

        .product_tab_item{ display:none; }
.tab_item_active{ display:block; }

.ul.outletul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.outletul>li{
    width: 100%;
    margin: 0 0 30px;
    position: relative;
}
.ourrest_row, ul.outletul{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style-type: none;
    padding-left:0px;
}
.ourrest_row{
    position: relative;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    background: #eaeaea;
    overflow: hidden;
}
.ourrest_info{
    width: 50%;
    padding: 20px;
    color: #1b1b1b;

}
.ourrest_info h4{
    margin: 0 0 15px;
    color: #1b1b1b;
    font-size: 22px;
    font-family: 'Poppins';
    font-weight: 500;
}
.ourrest_info p{
    margin: 0 0 8px;
    position: relative;
    /*padding: 0 0 0 26px;*/
    font-family: 'Poppins';
}
.ourrest_map{
    width: 50%;
    max-height: 250px;
}
.ourrest_map>div img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.outlet-lst-page{
    margin-top: 75px;
}
.ourrest_infolinks li{
    marker: none;
}
.ourrest_info i{
    /*margin: 0 auto;*/
    margin-right: 10px;
    color:#0271e5
}

.testi-section .testimonial-section .slick-slider .slick-dots{
    display: -webkit-flex!important;
    display: flex!important;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 0;
}
.testi-section .testimonial-section .slick-slider .slick-dots li{
    list-style: none;
}

.testi-section .testimonial-section .slick-slider .slick-dots li button{
    font-size: 0;
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0 3px;
    border-radius: 50%;
    background: #000;
}

/* .testi-section .testimonial-section .slick-next{
    right: 0px;width: 11px;height: 11px;float: right; text-indent: -9999px; padding: 0;     
    background: #000;
 list-style: none; cursor: pointer; margin: 0;    top: 0px;
} */

.testi-section .testimonial-section .slick-slider .slick-dots .slick-active button{
    background: #0271e5;
}
.pt-0-home {
    padding-top:10px!important;
}
.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_right button{
    padding: 15px 20px;
    color: #0271e5;
    text-transform: uppercase;
    border: 2px solid #0271e5;
    letter-spacing: 0.5px;
    font-size: 16px;
    /* margin: 20px 0 0; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border-radius: 8px;
    transition: 0.2s linear all;
    background: #fff;
}

.product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_right button:hover { color: #fff; background: #0271e5; }

.inn-product-popup .inn_product_row .prd_chosen_sub_row .prd_chosen_sub_col {
    flex: none
}
.no_records{
    display: flex;
    margin: 0 auto;
    text-align: center;
    flex-wrap: wrap;
    justify-content: space-between!important;
}
.package-inner p{
    font-family: 'poppins';
    color: #101010;
    text-align:left;
    line-height: 2;
    padding: 0px!important;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: center;
}

.package-inner h4{
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: bold;
    color: #101010;
    margin-bottom:0px;
}

.virtual-card-inner h4{
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 400;
    color: #333f4f;
    text-align: left;
    margin-bottom:5px;
    margin-left: 0px;
}

.virtual-card-inner p{
    font-family: 'Poppins';
    font-size: 18px;
    /*font-weight: 400;*/
    color: #333f4f;
    text-align: right;
    margin-bottom:5px;
    text-transform: capitalize;
}


.dinein-white-popup{
    position: relative;
    /*padding: 25px;*/
    width: auto;
    max-width: 450px !important;
    margin: 0 auto!important;
}

.dinein-scantable p{
    font-family: 'poppins';
    font-size: 20px;
    top: 11%;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    margin: 27px auto;
}

.dinein-scantable-inner section{
    text-align:center;
    justify-content: center;
    margin: 0 auto;
}

.dinein-div{
    width: 100%;
    text-align: center;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
}

.dinein-scantable a{
    padding: 0px 10px 10px 10px;
    font-family: 'poppins';
    font-size: 50px;
    font-weight: bold;
    color: #000;
    float: left;
}
.hide_out{display: none;}

.promo-card-whole{
    background: #fff;
    padding: 15px 15px 0px 15px;
    border-radius: 10px;
    border: 1px solid #f5f5f5;
}
.promo-card-body{
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
}
.virtual-card-head img{
    max-width: 120px;
    margin-bottom: 0px;
}
.self_outlet p{
    text-align: center;
}

.col-xs-12.cart_left.text-center h4 {
    font-size: 40px;
}
/****************** 10-09-21 ****************************/

.hcart_scrollarea .cart_row.product-details .cart_left .cart_info { max-width: calc(100% - 135px);}
.hcart_scrollarea .cart_table .product-details .cart_remove{top: 19px;}

.cart_right{width: 175px;}
.cart_left {width: calc(100% - 180px); display: flex; align-items: flex-start;}

.chekout_cart_bar .cart_left .cart_info { width: calc(100% - 115px);}

.mt-75{
    margin-top: -75px;
}

/*Dbest- Activities Css*/

.activities-popup-head h3{
    text-align: center;
    font-size: 45px;
    font-weight: bolder;
    font-family: 'Poppins';
}
.activities-popup-body select{
    border: 1px solid #000;
}

.activities-popup-head h3 img{
    max-height: 50px;
}

.activities-popup-body{
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}

.choose-date, .choose-time{
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}
.choose-date h3, .choose-outlet h3, .choose-time h3, .reservation-counter h3, .special-instructions h3{
    font-family: poppins;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #949393;
    text-align: center;
}
.catering_days_ul{
        list-style: none;
    margin: 0 -2px;
    padding-left: 0;
    padding-bottom: 0;
}
.catering_days_ul li{
    padding: 0 2px;
    width: 33%;
    float: left;
    margin-bottom: 5px;
    height: 100px;
}

.viewmore_ul li{
    width: 25%!important;
    height: 70px!important;
}

.catering_days_ul li dl{
        padding: 12px 5px;
    background: #fff;
    border: 2px solid #f6f5f5;
    /*border-radius: 5px;*/
    display: block;
    text-align: center;
    cursor: pointer;
    height: 100px;
    transition: all .1s ease-in;
    position: relative;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

.catering_days_ul li dl.active {
    background: #809b66;
}

.catering_days_ul li dl.active dt {
    color: #fff;
}

.catering_days_ul li dl.active dd {
    color: #fff;
}

.catering_days_ul li dl:hover {
    background: #809b66;
}

.catering_days_ul li dl:hover dt {
    color: #fff;
}

.catering_days_ul li dl:hover dd {
    color: #acacac;
}

.catering_days_ul li dl.cl-next-days:hover span {
    color: #000;
}

.catering_days_ul li dl.cl-next-days {
    background: #a1a1a1;
}

.catering_days_ul li dl.cl-next-days span {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Poppins';
    text-transform: uppercase;
    display: block
}

.catering_days_ul li dl dt {
    font-size: 14px;
    /*text-transform: uppercase;*/
    color: #9d9d9d;
    line-height: 14px;
    font-family: 'Poppins';
    margin: 0 0 5px 0
}

.catering_days_ul li dl dd {
    font-weight: normal;
    font-size: 18px;
    /*text-transform: uppercase;*/
    color: #3c3a3a;
    line-height: 1;
    font-family: 'Poppins';
}

.catering_days_ul .tag {
    color: #fff;
    background: #060606;
    padding: 5px 5px;
    position: absolute;
    left: -10px;
    top: 20px;
    transform: rotate(-18deg);
    -webkit-transform: rotate(-18deg);
    width: 120%;
    line-height: normal;
    text-align: center;
    height: 27px;
    font-size: 12px;
    font-family: 'Poppins';
    text-transform: uppercase
}

.choose-date .catering_days_ul li dl dt, .choose-time .catering_days_ul li dl dt{
        font-size: 18px;
}
.catering_days_ul li dl dt{

        font-weight: 500;
    font-size: 18px;
    color: #000;
    line-height: 1;
}

.catering_days_ul li dl dt:hover{
    color: #fff;
}

.catering_days_ul li dl.cl-next-days span, .catering_days_ul li dl dt{
    font-size: 14px;
    font-family: Poppins;
    /*text-transform: uppercase;*/
}
.catering_days_ul .tag, .catering_days_ul li dl dd{
        /*text-transform: uppercase;*/
    font-family: poppins;
}
.catering_days_ul li dl dd{
    color: #949393;
    line-height: 14px;
    margin: 10px 0 10px;
    font-size: 13px;
}
.catering_days_ul:after{
    content: "";
    display: table;
    clear: both;
}
.choose-date .catering_days_ul li dl:hover, .choose-time .catering_days_ul li dl:hover{
    border: 2px solid #000000;
}
.catering_days_ul li dl:hover{
    background: #000000;
}

.catering_time_ul{
        list-style: none;
    margin: 0 -2px;
    padding-left: 0;
    padding-bottom: 0;
}
.catering_time_ul li{
    padding: 0 2px;
    width: 100%;
    float: left;
    margin-bottom: 5px;
    height: 100px;
}
.catering_time_ul li dl{
        padding: 12px 5px;
    background: #fff;
    border: 2px solid #f6f5f5;
    /*border-radius: 5px;*/
    display: block;
    text-align: center;
    cursor: pointer;
    height: 80px;
    transition: all .1s ease-in;
    position: relative;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

.time-slots h3{
    font-family: poppins;
    font-size: 16px;
    margin-bottom: 0px!important;
    margin-top: 0px!important;
    color: #949393;
}

.time-slots h2{
    font-family: poppins;
    font-size: 18px;
    font-weight: bolder;
    color: #040601;
}

.time-slots{
    display: flex;
    justify-content: space-between;
}

.activities-date h3{
    font-family: poppins;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #949393;
    text-align: center;
}

.activities-popup-main select{
    border-radius: 0px;
}

 .activities-time h3{
    font-family: poppins;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #949393;
    text-align: center;
}

.catering_time_ul li dl.active {
    background: #809b66;
}

.catering_time_ul li dl.active dt {
    color: #fff;
}

.catering_time_ul li dl.active dd {
    color: #fff;
}

.catering_time_ul li dl:hover {
    background: #809b66;
}

.catering_time_ul li dl:hover dt {
    color: #fff;
}

.catering_time_ul li dl:hover dd {
    color: #acacac;
}

.catering_time_ul li dl.cl-next-days:hover span {
    color: #000;
}

.catering_time_ul li dl.cl-next-days {
    background: #a1a1a1;
}

.catering_time_ul li dl.cl-next-days span {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Poppins';
    text-transform: uppercase;
    display: block
}

.catering_time_ul li dl dt {
    font-size: 14px;
    /*text-transform: uppercase;*/
    color: #9d9d9d;
    line-height: 14px;
    font-family: 'Poppins';
    margin: 0 0 5px 0
}

.catering_time_ul li dl dd {
    font-weight: normal;
    font-size: 18px;
    /*text-transform: uppercase;*/
    color: #3c3a3a;
    line-height: 1;
    font-family: 'Poppins';
}

.catering_time_ul .tag {
    color: #fff;
    background: #060606;
    padding: 5px 5px;
    position: absolute;
    left: -10px;
    top: 20px;
    transform: rotate(-18deg);
    -webkit-transform: rotate(-18deg);
    width: 120%;
    line-height: normal;
    text-align: center;
    height: 27px;
    font-size: 12px;
    font-family: 'Poppins';
    text-transform: uppercase
}

.choose-date .catering_time_ul li dl dt, .choose-time .catering_time_ul li dl dt{
        font-size: 18px;
}
.catering_time_ul li dl dt{

        font-weight: 500;
    font-size: 18px;
    color: #000;
    line-height: 1;
}

.catering_time_ul li dl dt:hover{
    color: #fff;
}

.catering_time_ul li dl.cl-next-days span, .catering_time_ul li dl dt{
    font-size: 18px;
    font-family: Poppins;
    /*text-transform: uppercase;*/
}
.catering_time_ul .tag, .catering_time_ul li dl dd{
        /*text-transform: uppercase;*/
    font-family: poppins;
}
.catering_time_ul li dl dd{
    color: #949393;
    line-height: 14px;
    margin: 10px 0 0px;
    font-size: 13px;
}
.catering_time_ul:after{
    content: "";
    display: table;
    clear: both;
}
.choose-date .catering_time_ul li dl:hover, .choose-time .catering_time_ul li dl:hover{
    border: 2px solid #000000;
}
.catering_time_ul li dl:hover{
    background: #000000;
}

.viewmore-bottom{
    text-align: center;
    text-decoration: underline;
    margin: 20px 0px 50px;
    font-size: 18px;
    text-transform: uppercase;
}
.activities-bottom-button a{
    min-width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;
    border: 2px solid #fff;
}

.activities-bottom-button a:hover{
    color: #0271e5;
    background: #fff;
    border: 2px solid #0271e5;
}
.activities-date .owl-nav{
    margin-top: 0;
}

.activities-date .owl-nav .owl-prev {
    border-right: 1px solid #3b3b3b;
}

.activities-date .owl-nav .owl-prev {
    left: 0px;
}

.activities-date .owl-nav .owl-prev:hover {
    background: #000;
    color: #fff;
}

.activities-date .owl-nav .owl-next:hover {
    background: #000;
    color: #fff;
}

.activities-date .owl-nav .owl-prev {
    display: inline-block;
    -webkit-appearance: none!important;
    appearance: none!important;
    cursor: pointer;
    background: #ededed;
    color: #fff;
    border-radius: 0px;
    border: 0;
    padding: 0;
    margin: 0;
    height: 80px;
    width: 27px;
    top: 0;
    font-size: 15px;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    font-size: 0;
    transition: all .3s linear;
}

.activities-date .owl-nav .owl-prev:before {
    position: absolute;
    left: 0;
    right: 0;
    width: 17px;
    height: 17px;
    top: 40%;
    margin-top: 0px;
    background: url(../images/banner-arrow.png) no-repeat center;
    content: "";
    margin: auto;
}

.activities-date .owl-nav .owl-next:before {
    position: absolute;
    left: 0;
    right: 0;
    width: 17px;
    height: 17px;
    top: 40%;
    margin-top: 0px;
    background: url(../images/banner-arrow.png) no-repeat center;
    content: "";
    margin: auto;
}

/*.activities-date .owl-item{
    width: 125px!important;
    margin-right: 17px!important;
}*/

.activities-date .owl-nav .owl-next {
    border-left: 1px solid #3b3b3b;
}

.activities-date .owl-nav .owl-next {
    right: 0px;
}

.activities-date .owl-nav .owl-next {
    display: inline-block;
    -webkit-appearance: none!important;
    appearance: none!important;
    cursor: pointer;
    background: #ededed;
    color: #fff;
    border-radius: 0px;
    border: 0;
    padding: 0;
    margin: 0;
    height: 80px;
    width: 27px;
    top: 0;
    font-size: 15px;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    font-size: 0;
    transition: all .3s linear;
}

.activities-date .owl-nav .owl-next:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}


.text-center{
    text-align: center;
}
.dbest-self-outlet p{
    margin: 0px;
}
a.four-columns-btn.four-columns-btns.f-left.issue_vc1 {
    margin-bottom: 0px;
    margin-top: 0px;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.table > thead > tr > th{
    vertical-align: middle;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th{
    vertical-align: middle;
    border-top: 0px;
    text-transform: capitalize;
}

.table > caption + thead > tr:first-child > td, .table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > td, .table > thead:first-child > tr:first-child > th{
    background: #0271e5;
    color: #fff;
    font-weight: 600;
    text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}


.checkout-total .cdd-details-lhs.fl .checkout-detail-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
   /* border-radius: 9px 9px 0 0;*/
}

.checkout-total .cdd-details-lhs.fl .checkout-detail-detail .tab-menu-container{
    margin-bottom: 0;
}

.text-center.checkout-heading.checkout-detail-detail .tab-menu-container ul li a.text-uppercase-leftone,
.text-center.checkout-heading.checkout-detail-detail .tab-menu-container ul li a.text-uppercase-rightone {
    background: #b7b6b6;
    color: #fff;
    padding: 12px 10px;
    position: relative;
    line-height: 1.2;
    border-radius: 12px 10px 0 0;
    text-transform: uppercase;
    cursor: pointer;
}

.text-center.checkout-heading.checkout-detail-detail .tab-menu-container ul li a.active {
    background: #0271e5;
    color: #fff;
}

.text-center.checkout-heading.checkout-detail-detail .tab-menu-container ul{
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout-main-div .common-inner-banner{
    background-size: cover;
}

.checkout-total .cdd-details-lhs.fl,
.checkout-total .cdd-details-rhs.fl
{
    border-radius: 9px 9px 0 0;
}

.voucher-popup-inner h2, .voucher-popup-inner h3 {
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    font-family: "Brandon-Grot-W01-Light";
}
.voucher-popup-inner h2 {
    font-size: 40px;
}
.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}
.react-tabs__tab-panel--selected {
    display: block;
}
.voucher-popup-inner h2, .voucher-popup-inner h3 {
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    font-family: "Brandon-Grot-W01-Light";
}
.voucher-popup-inner h3 {
    font-size: 28px;
}
.voucher-popup-inner {
    background: #fff;
    padding: 45px 55px 10px;
    position: relative;
    z-index: 9;
    margin: 0 49px 0 0;
    width: 100%;
    transition: all .4s linear;
}
.voucher-popup-inner .gift-group button, .voucher-popup-inner button {
    margin-left: 0!important;
}
a.four-columns-btn.four-columns-btns.f-left.issue_vc1 {
    margin-bottom: 0px;
}
#error_span{
    display: none;
    color: red;
}
.timeslot_info{
    color: red;
    text-align: center;
}
.filter_tabsec.ordertab1 .nav-tabs>li {
    width: 33%!important;
}
.activities-date  .react-datepicker-wrapper{
    display: block;
}
.booking .cart_price{
    padding-right: 0px;
}

.booking .cdd-details .cdd-details-rhs .cart_right{
    width: 144px;
}

.booking  h4{
    background: #ffffff !important;
    padding: 0px 0px 30px !important;
}
 .booking_catch h3{
    font-family: poppins;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #949393;
    text-align: left;
}
.activities-date input,.activities-time input{
    border: 1px solid #000;
    border-radius: 0px;
}
.col-lg-6.booking_catch {
    margin-top: -25px;
}
.col-sm-cls.cart_right.text-right{
    width: 200px;
}

/*25-10-2021*/

/******************************** OUTLET ************************************/

.outlet-list {
    max-height: 551px;
    overflow-y: auto;
}

.outlet-list > ul {
    padding: 20px 15px;
}

.outlet-list > ul > li {
    margin-bottom: 20px;
}

.outlet-list li {
    list-style: none;
    padding-left: 38px;
}

.outlet-list li span {
    display: block;
    font-weight: 700;
}

.outlet-list li .submenu {
    padding: 0;
}

.outlet-list li .submenu li {
    margin-top: 10px;
    list-style: none;
    padding: 0;
    line-height: normal;
    padding-left: 3px;
}

.outlet-list li .submenu li a {
    font-weight: 700;
    display: block;
    color: #272626;
    position: relative;
    padding-left: 14px;
    font-size: 11px;
}

.outlet-list li .submenu li a:hover {
    color: #ecdfcc
}

.outlet-list .custom_checkbox {
    position: absolute;
    left: 0;
    font-size: 0;
}

.outlet-list .custom_checkbox span:before {
    width: 16px;
    height: 17px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #e6e6e6;
}

.outlet-list .custom_checkbox input[type=checkbox]:checked + span:before {
    background-position: -216px -189px;
}

.outlet-list li .submenu li .mapcanvas_close {
    position: absolute;
    padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
    display: none;
}


/*.outletList-main-div .outlet-lst-page {
    padding: 45px 0 140px;
}*/

.ourrest_row{
    box-shadow: 0px 14px 32px 0px rgb(0 0 0 / 15%);
    height: 100%;
}

.ourrest_info strong{
    color: #000;
    text-transform: uppercase;
}

.ourrest_info p{
    color: #1c1c1c;
    margin-top: 12px;
}

.ourrest_info .outlet_address_content{
    margin-left: 20px;
}

.outlet-para-text p.phone-show-dskp {
    margin-left: 20px;
}

.outlet-para-text p.phone-show-dskp .phone-show-mbl a{
    color: #7e7f7e;
}

.outlet-para-text p.phone-show-dskp .phone-show-mbl a:hover, .outlet-para-text .phone-show-dskp span a:hover{
    color: #7fb178;
}

.outlet-para-text .phone-show-dskp span a{
    color: #7e7f7e;
}

.ourrest_info .outlet-para-text .outlets-timing p{
    margin-left: 25px;
}

.ourrest_info .outlet-para-text .outlets-timing p i{
    color: #000;
}


.ourrest_info li a{
    background: #0271e5;
    color: #fff
}

.ourrest_info .ourrest_infolinks .li-full-width a:hover{
    background-image: #0271e5;
    background-size: 300% 100%;
    background-position: 100% 0;
    transition: all .4s ease-in-out;
}

.ourrest_info .ourrest_infolinks .li-full-width a:hover i{
    color: #fff
}

.outlet-lst-page ul.outletul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.outlet-lst-page .outletul>li {
    width: 49%;
    margin: 0 2% 30px 0;
    position: relative;
}

.ourrest_img {
    width: 100%;
    height: 320px;
    display: flex;
}

.ourrest_img img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.ourrest_info {
    width: 100%;
    padding: 30px 30px 70px;
    color: #fff;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 320px);
    background: #ffffff9e;
}

.ourrest_info h4 {
    margin: 0 0 15px;
    color: #000;
    font-size: 22px;
}

.ourrest_infolinks {
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    display: -webkit-flex;
    display: flex;
}

.ourrest_infolinks li {
    display: block;
    width: 100%;
}

.media-links-b a {
    background: #000;
}

.ourrest_infolinks a i {
    display: inline-block;
    margin: 0 10px 0 0;
}

.ourrest_infolinks a {
    padding: 14px 5px;
    display: block;
    color: #fff;
    transition: all .4s;
    font-family: 'poppins';
    font-weight: 600;
    height: 100%;
    text-transform: uppercase;
}

.outlet-para-text i, .ourrest_info i, .outlets-timing i, .outlet_address i{
    color: #0271e5;
    font-size: 20px;
    margin-right: 5px;
    text-align: left;
}

.outlets-timing strong{
    margin-left: -3px;
}

.ourrest_info .outlet_address_content, .outlet-para-text p.phone-show-dskp {
    margin-left: 29px;
}

.outletul>li:nth-child(2n) {
    margin-right: 0;
}

.outlet-lst-page .ourrest_row .ourrest_info p.outlet_address {
    margin-bottom: 5px;
    margin-top: 5px;
}

.ourrest_info p span a{
    color: #1c1c1c;
}

.contact_col {
    float: left;
    width: 50%;
    padding: 0 15px;
}

.contact_form textarea.form-control {
    margin: 0;
    height: 105px;
}

.contact_form .btn_sec button {
    width: 100%;
    margin: 3px 0 0;
}

.hcart_dropdown .cart_action {
    padding: 0;
    z-index: 99;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.hcart_dropdown .hcart_scrollarea {
    overflow-y: auto;
    height: calc(100% - 104px);
}



/*.home-top-four-section:hover .choose-option-hover{  opacity: 1;
    visibility: visible;
    pointer-events: auto; z-index: 9 }*/
.home-top-four-section:nth-child(5n) .choose-option-hover {
    left: inherit;
    right: 100%;
}

/*.home-top-four-section {
    position: relative;
}*/


/********************** SURESH CSS ************************************/

.four-columns{
    position: relative;
}

.four-columns:hover {
    z-index: 9;
}

.four-columns:hover .choose-option-hover {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    z-index: 99;
}

.choose-option-hover {
    position: absolute;
    width: 210px;
    top: 100%;
    left: 0%;
    box-shadow:0px 3px 15px 0px rgba(0, 0, 0, 0.19);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: all .4s;
    transition: all .4s;
    z-index: 9;
    margin-left: 4px;
    
}
.choose-option-hover ul{ 
    background: #fff;
    border: 3px solid #0271e5;
    padding: 0;
    list-style: none;
    padding: 10px;
    /*border-radius:0 0 5px 5px*/
    border-radius: 5px;
}

.mobile-choose-option-hover{display: none}

.mobile-choose-option-hover>ul>li>a {
    padding: 5px;
    position: relative;
    text-align: center;
    font-family: 'Poppins';
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #0271e5;
}

.mobile-choose-option-hover > ul{ padding: 0; list-style: none}

.mobile-choose-option-hover ul li ul{    background: #ececec;
    list-style: none;
    padding: 10px 0;
    border-radius: 8px;
 }




.choose-option-hover ul li{ margin: 7px 0;text-align: left;  }
.choose-option-hover ul li a{font-family: 'Poppins'; color: #000; font-size: 16px;}
.choose-option-hover ul li a:hover{  color: #0271e5 }


.mobile-choose-option-hover ul li:hover ul{display: block;}
.mobile-choose-option-hover > ul > li > a{
    padding: 5px 5px;
    position: relative;
    text-align: center;
    font-family: 'Poppins';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #fbaa1a;
}
.mobile-choose-option-hover > ul > li:last-child > a{
    /*color: #fff;*/
    /*background: #0271e5;*/
    /*border-radius: 4px;*/
    background: none;
}

.mobile-choose-option-hover > ul > li > a img {
    margin-right: 5px;
    margin: 0 auto;
}

.mobile-choose-option-hover ul li ul li a{    font-family: 'Poppins'; color: #000; font-size: 16px; display: block;padding: 4px 10px}
.mobile-choose-option-hover ul li ul li a:hover{ color: #0271e5 }
.mobile-choose-option-hover ul li:hover ul{
    display: block;
}

.home-banner .slick-prev, .home-banner .slick-next{ width: 30px; height: 45px; font-size: 0; background: #000;    top: 50%; left: 0px;  margin: 0; padding: 0; margin-top: -22px; position: absolute; border-radius: 0; border: 0px;}
.home-banner .slick-next{ right: 0px; left: inherit;text-indent:10000px;color: #fff; }
.home-banner .slick-prev{ left: 0px; left: inherit;text-indent:10000px;color: #fff; }
.home-banner button.slick-arrow.slick-prev:after, .home-banner button.slick-arrow.slick-next:after {
    content: "\f104";
    font-family: 'FontAwesome';
    font-size: 26px;
    font-weight: normal;
}
.home-banner button.slick-arrow.slick-next:after {
    content: "\f105";
}

.myacc-main-div .myacc_order_details .promo-earned-col{
    width: 33.33%;
    padding: 0 10px;
    background: #fff;
    margin: 0 0 40px;

}



/*.promo-earned-col-item{
        -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    border: 1px
     solid #fff;
    box-shadow: 0 1px 9px 0 rgb(0 0 0 / 14%);
    background: #fff;
}
*/
.promo-earned-col-image {
    position: relative;
}

.promo-earned-col-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 206px;
    object-position: center;
}

.promo-earned-info{
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
}

.promo-earned-content .voucher_btn {
    margin-top: 20px;
}

.Viewreceipt .cart_left{
        width: calc(100% - 114px);
}

.media-page {
    margin-top: 15px;
}

.media-page ul.mediaul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.media-page .mediaul>li {
    width: 31%;
    margin: 0 2% 5px 0;
    position: relative;
}

.media_row {
    /*box-shadow: 0px 14px 32px 0px rgb(0 0 0 / 15%);*/
    height: 100%;
    position: relative;
    justify-content: center;
    width: 100%;
    background: #fff;
    overflow: hidden;
}

.media_img {
    width: 100%;
    height: 320px;
    display: flex;
}

.media_info {
    width: 100%;
    padding: 10px 0px 0px;
    color: #fff;
    border-top: 2px solid #0271e5;
    display: flex;
    text-align: left;
    flex: 1 1 auto;
    flex-direction: column;
    /*height: calc(100% - 320px);*/
    background: #fff;
}

.media_info h4 {
    margin: 0 0 5px;
    color: #000;
    font-size: 21px;
    font-family: 'Poppins';
    font-weight: 500;
    text-transform: uppercase;
}

.media_img img {
    height: 100%;
    width: 100%;
}

.media-section h2 {
    margin: 0 0 0px;
    line-height: 50px;
    font-weight: bold;
    font-family: 'poppins';
    color: #101010;
    font-size: 30px;
    margin-bottom: 0px;
}

#accordion .panel {
  border-radius: 0;
  border: 0;
  margin-top: 0px;
  margin-bottom: 20px;
}
#accordion a {
  display: block;
      font-size: 15px;
    font-weight: 500;
    font-family: 'poppins';
    text-align: left;
  padding: 20px;
  box-shadow: 0px 4px 9px 0px rgb(2 2 2 / 8%);
  text-decoration: none;
      border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 10px 0px 0px 10px;
    background-color: #f6f6f6;
}
#accordion .panel-heading a.collapsed:hover,
#accordion .panel-heading a.collapsed:focus {
  background-color: #f6f6f6;
  color: #444;
  transition: all 0.2s ease-in;
}
#accordion .panel-heading a.collapsed:hover::before,
#accordion .panel-heading a.collapsed:focus::before {
  color: #0271e5;
}
#accordion .panel-heading {
  padding: 0;
  border-radius: 0px;
  text-align: center;
/*background-color: #f6f6f6;*/
/*color: #444;*/
}
#accordion .panel-heading a:not(.collapsed) {
  color: white;
  background-color: #f6f6f6;
  color: #444;
box-shadow: 0px 4px 9px 0px rgb(2 2 2 / 8%);
  transition: all 0.2s ease-in;
}

/* Add Indicator fontawesome icon to the left */
#accordion .panel-heading .accordion-toggle::before {
  font-family: 'FontAwesome';
  content: '\f00d';
  float: right;
  color: #0271e5;
  font-weight: lighter;
  transform: rotate(0deg);
  transition: all 0.2s ease-in;
}
#accordion .panel-heading .accordion-toggle.collapsed::before {
  color: #444;
  transform: rotate(-135deg);
  transition: all 0.2s ease-in;
}


.accordion_sec{
        margin-top: 50px;
}

.panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body{
    border-top: 0px;
    border-bottom: 0px;
}

.tbl-bg-none{
    background:none!important;
    box-shadow: none !important;
}
.table_number{
    text-align: center;
    color:  green;
}

.scan-tbl p{
    font-size: 16px;
}

.scan-tbl .four-columns-btn {
    display: block;
    padding: 14px 20px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px; 
    font-size: 15px;
    margin: 20px 0 0; 
    font-family: 'Poppins', sans-serif;
    font-weight: bold; 
    border-radius: 8px;
    background: #0271e5; 
    max-width: 225px;
    margin: 0 auto 0;
}

.scan-tbl .four-columns-btn:hover { color: #fff; background: #0271e5; }

.scan-tbl h3{
    margin-top: 15px;
    font-size: 24px;
    margin-bottom: 15px;
}

.booking-tbl-input input{
    width: 95%;
    font-size: 14px;
    font-weight: 600;
}

.pricing-section h2{
    color: #101010;
    font-family: 'Poppins';
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
}

.pricing-section h3{
    color: #101010;
    margin-top: 20px;
    font-family: 'Poppins';
    text-align: left;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.pricing-section table{
    -webkit-box-shadow: 0px 0px 10px 0px #6b6b6b;
    box-shadow: 0px 0px 10px 0px #6b6b6b;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    font-size: 16px;
}

.pricing-section table tr{
    border: none;
}

.pricing-section table tr th:nth-of-type(1){
        border-left: none;
}

.pricing-section table tr th, .pricing-section table tr td {
    padding: 17px 10px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
}

.pricing-section table tr th {
    background-color: #0271e5;
    color: #fff;
    border-left: 1px solid #9fbedd;
    border-right: 1px solid #9fbedd;
    font-size: 16px;
    vertical-align: inherit;
    font-weight: bold;
}

.pricing-section table tr th, .pricing-section table tr td {
    padding: 17px 10px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
}

.pricing-section table tr td {
    background: #fcfcfc;
}

.pricing-section table tr td:nth-of-type(1){
    border-left: none;
    /*vertical-align: inherit;*/
    /*display: table-cell;*/
    /*text-indent: initial;*/
    /*text-align: center;*/
    /*border-spacing: 2px;*/
    font-weight: 500;
    /*word-wrap: break-word !important;*/
}

/*.pricing-section table tr td:nth-of-type(11){
    text-align: center;
}*/

.pricing-section table tr:nth-of-type(odd) td{
        background: #f3f3f3;
}

.pricing-section .row{
    margin-bottom: 30px;
}

.pricing-section .notif{
    background-color: #0271e5;
    color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
    padding: 10px 15px;
}

#pricing .row:last-of-type .col-lg-6:last-of-type table tbody tr:last-of-type td{
        width: 50%;
}

.pricing-section .row:last-of-type .smaller-table, .pricing-section .row:nth-child(3) .col-lg-6:last-of-type .smaller-table{
        border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.pricing-section table tr td span {
    font-weight: bold;
    font-size: 18px;
}

.pricing-section .smaller-table{
    position: relative;
}

.pricing-section table tr{
    margin-bottom: 0px;
}

.pricing-section .smaller-table .operating-tr{
    display: table-row;
}

.pricing-section .smaller-table tr td{
    display: revert;
}

.pricing-section .smaller-table tr{
    display: revert;
}

a[type="link"]{ display: none }

.mypromo-main-div .myacc_order_details .promo-earned-col {
    width: 49%;
    background: #fff;
    margin: 0 0 40px;
}

.promo-earned-info{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;padding: 30px 10px;width: 50%;-webkit-box-align: center;-ms-flex-align: center;align-items: center;    -webkit-box-pack: center;    -ms-flex-pack: center;    justify-content: center; text-align: center; position: relative;z-index: 1
}

.promo-earned-info:before{  content: "";
    content: "";
    z-index: -1;
    position: absolute;
    top: -1px;
    left: -60px;
    bottom: -1px;
    background: #ffffff;
    width: 80px;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg); 
}

.promation_btn {
    width: 100%;
}

.forgot-password-popup, .login-popup, .signup-popup {
    max-width: 980px;
    margin: 20px auto;
    width: 100%;
    background: #fff;
    position: relative;
    color: #222;
}

.packages-promos-body a{
    display: block;
    padding: 15px 20px;
    color: #0271e5;
    text-transform: uppercase;
    border: 2px solid #0271e5;
    letter-spacing: 0.5px; font-size: 16px;
    margin: 20px 0 0; font-family: 'Poppins', sans-serif;
    font-weight: 500; border-radius: 8px;
     transition: 0.2s linear all;
    background: #fff;
    margin: 0 auto 0; 
    margin-top: 0px;
    width: 100%;
    max-width: 162px
}

.packages-promos-body a:hover { color: #fff; background: #0271e5; }


.cart-items-open #scrollbutton, .cart-items-open .jxPOhn{
    opacity: 0
}

.media-partner .media_img img{
    width: 371px;
    /*height: 136px;*/
    object-fit: contain;
}

.media-partner .media_img{
    height: 180px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.media-partner .mediaul>li{
    margin: 0 2% 20px 0;
}

.promo-package-main-div{
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;    
}

.promo-package-main-div .packages-promos-body{
        padding: 0px 0 0px;
        display: flex;
}

.promo-package-main-head .package-promo-para strong{
    color: #101010;
    margin-top: 20px;
    font-family: 'Poppins';
    text-align: left;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.login-popup .two-links .spilter, .guest-popup .two-links .spilter {
    height: 14px;
    margin: 0 8px 0;
    vertical-align: middle;
}
.hmenu-login-section span{
    font-size: 12px;
}

.product-title.whats h3 {
    color: #0271e5;
}
.product-title.whats h3:hover {
    text-decoration: underline;
}

/*new class for booking*/
.booking-cart-category-txt h4 { padding: 0px 0px 0px !important; }
.delivery_total_maindiv .product-details { width: 100% }
.delivery_total_maindiv .product-details span{ display: block;
    color: #000;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px; }

.booking .cart_remove{
top: 2px;
}
.booking .cart_price {
    padding-right: 25px;
    margin: 10px 0 10px 0;
}

.booking .cart_right.text-right{
    width: 180px;
}

.cdd-details-rhs .booking .qty_bx{
    right: 0;
}

.custom_select_12hrtimebooking .custom_select {
	float: left;
	width: 36%;
}

#ordstdhrstype_div {
	width: 23%;
}
.custom_select_12hrtimebooking
{
    display: flex;
    justify-content: space-between;
}

.timeslot_info_div .col-lg-7 {
    padding-right: 0px;
    padding-left: 0px;
} 

.timeslot_info_div .col-lg-5 {
    padding-right: 15px;
    padding-left: 0px;
} 

.col-lg-12.booking_hourchanges{
    padding-right: 0px;
    padding-left: 0px;
    display: flex;
    justify-content: space-between;
}

/*.row .activities-advanced-timeslot-main{
    margin-left: 0px;
    margin-right: 0px
}*/

.form-group.timeslot_info_div .row{
    margin-right: 0px;
    margin-left: 0px;
}

.activities-advanced-timeslot-main .booking_catch .additional_hours_text p{
    text-align: center;
}

.activities-advanced-timeslot-main .booking_catch h3{    
    text-align: center;
}

.activities-advanced-timeslot-main .col-lg-12.booking_catch{
    text-align: center;
}

.timeslot_info-right, .timeslot_info-left{
    width: 48%;
    position: relative;
}

.timeslot_info_left_right{
    display: flex;
    justify-content: space-between;
    width: 100%
}

.activities-advanced-timeslot-left-right{
    display: flex;
    justify-content: space-between;
    width: 100%
}

.activities-advanced-timeslot-right, .activities-advanced-timeslot-left{    
    width: 48%;
    position: relative;
}

.activities-advanced-timeslot-left-5{
     width: 39.66666667%;
}

.activities-advanced-timeslot-left-7{
     width: 58.33333333%;
}

.activities-advanced-timeslot-left-6{
    width: 48%;
}